$chip-y-spacing: 15px;
$chip-x-spacing: 5px;
$chip-button-width: $chip-y-spacing + $chip-x-spacing * 2;
$chip-border-radius: 5px;
$chip-background: #666f86;
$chip-delete-background: #5b6478;
$chip-color: #fff;
$chip-min-height: 36px;

body {
  font-family: Arial, sans-serif;
}
#title {
  letter-spacing: 1px;
}

.chips {
  min-height: $chip-min-height;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  line-height: 1;
  font-size: 1em;
}

.chips-input {
  display: inline-block;
  min-height: $chip-min-height;
  margin-top: $chip-x-spacing;
  margin-bottom: $chip-x-spacing;
  margin-left: $chip-x-spacing * 2;
  margin-right: $chip-x-spacing * 2;
  border: 0px;
  outline: none;
  font-size: 0.9rem;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0)
}

.chip{
    display: inline-block;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 5px;
    margin-right: 5px;
    position: relative;
    height: 26px;
    background: #666f86;
    border-radius: 5px;
    padding-right: 5px;
    width: auto;

  
  .chip-value {
    display: inline-block;
    padding: 5px;
    padding-left: 15px;
    padding-right: 7.5px;
    color: #fff;
    font-weight: bold;
  }
  
  .chip-delete-button {
    background: #525868;
    color: #c5c5c5;
    border: 0;
    font-weight: bold;
    height: 25px;
    border-radius: 12px;
    display: inline-block;
    position: relative;
    top: -1px;
    padding: 0 7px 3px 7.5px;
  }
}