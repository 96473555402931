@font-face {
  font-family: 'icomoon';
  src: url('/font/icomoon.ttf?2nxk1t') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="iconm-"], [class*=" iconm-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.iconm-Logout:before {
  content: "\e94a";
}
.iconm-Add-2:before {
  content: "\e949";
}
.iconm-user-b:before {
  content: "\e92e";
}
.iconm-lock-b:before {
  content: "\e930";
}
.iconm-store:before {
  content: "\e907";
}
.iconm-user:before {
  content: "\e95a";
}
.iconm-window:before {
  content: "\e95f";
}
.iconm-drm:before {
  content: url("../../assets/img/icon-drm.svg");
}
.active .iconm-drm:before,
.nav-link:hover .iconm-drm:before {
  content: url("../../assets/img/icon-drm-active.svg");
}
.iconm-clipboard:before {
  content: url("../../assets/img/icon-clipboard.svg");
}
.active .iconm-clipboard:before,
.nav-link:hover .iconm-clipboard:before {
  content: url("../../assets/img/icon-clipboard-active.svg");
}
.iconm-download:before{
  content: url("../../assets/img/icon-download.svg");
}
.iconm-users:before {
  content: "\e960";
}
.iconm-mail:before {
  content: "\e961";
}
.iconm-shopping:before {
  content: "\e962";
}
.iconm-file-missing:before {
  content: "\e964";
}
.iconm-ticket:before {
  content: "\e965";
}
.iconm-packages:before {
  content: "\e966";
}
.iconm-meter:before {
  content: "\e967";
}
.iconm-Explore---Active:before {
  content: "\e901";
}
.iconm-Map---Active:before {
  content: "\e902";
}
.iconm-Menu:before {
  content: "\e903";
}
.iconm-Notification1:before {
  content: "\e904";
}
.iconm-Profile:before {
  content: "\e905";
}
.iconm-Rearrange:before {
  content: "\e906";
}
.iconm-Cart:before {
  content: "\e900";
}
.iconm-Add:before {
  content: "\e908";
}
.iconm-Back:before {
  content: "\e909";
}
.iconm-Bookmark:before {
  content: "\e90a";
}
.iconm-Bookmarked:before {
  content: "\e90b";
}
.iconm-Breakfast:before {
  content: "\e90c";
}
.iconm-Bridge:before {
  content: "\e90d";
}
.iconm-Calendar:before {
  content: "\e90e";
}
.iconm-Car:before {
  content: "\e90f";
}
.iconm-Church:before {
  content: "\e910";
}
.iconm-Close:before {
  content: "\e911";
}
.iconm-Coffee:before {
  content: "\e912";
}
.iconm-Details:before {
  content: "\e913";
}
.iconm-Eye-Hide:before {
  content: "\e914";
}
.iconm-Eye:before {
  content: "\e915";
}
.iconm-Facebook:before {
  content: "\e916";
}
.iconm-Filter1:before {
  content: "\e917";
}
.iconm-Google:before {
  content: "\e918";
}
.iconm-Info1:before {
  content: "\e919";
}
.iconm-Instagram:before {
  content: "\e91a";
}
.iconm-Left:before {
  content: "\e91b";
}
.iconm-Loading1:before {
  content: "\e91c";
}
.iconm-Location1:before {
  content: "\e91d";
}
.iconm-Map:before {
  content: "\e91e";
}
.iconm-Mountain:before {
  content: "\e921";
}
.iconm-Navigation-2:before {
  content: "\e922";
}
.iconm-Navigation:before {
  content: "\e923";
}
.iconm-Out:before {
  content: "\e924";
}
.iconm-Play:before {
  content: "\e925";
}
.iconm-Position:before {
  content: "\e926";
}
.iconm-Right:before {
  content: "\e927";
}
.iconm-Search1:before {
  content: "\e928";
}
.iconm-Setting:before {
  content: "\e929";
}
.iconm-Share1:before {
  content: "\e92a";
}
.iconm-Sights:before {
  content: "\e92b";
}
.iconm-Stadium:before {
  content: "\e92c";
}
.iconm-Toward-2:before {
  content: "\e92d";
}
.iconm-Toward-3:before {
  content: "\e92f";
}
.iconm-Toward:before {
  content: "\e931";
}
.iconm-Train:before {
  content: "\e932";
}
.iconm-Tripadvisor:before {
  content: "\e933";
}
.iconm-Twitter:before {
  content: "\e934";
}
.iconm-Walk:before {
  content: "\e935";
}
.iconm-Arrow-Outline-Left:before {
  content: "\e91f";
}
.iconm-Arrow-Outline-Right:before {
  content: "\e920";
}
.iconm-Active:before {
  content: "\e936";
}
.iconm-Inactive:before {
  content: "\e937";
}
.iconm-Contacts:before {
  content: "\e938";
}
.iconm-Direction:before {
  content: "\e939";
}
.iconm-Distance:before {
  content: "\e93a";
}
.iconm-Download:before {
  content: "\e93b";
}
.iconm-Exchange:before {
  content: "\e93c";
}
.iconm-Export:before {
  content: "\e93d";
}
.iconm-Filter:before {
  content: "\e93e";
}
.iconm-Group:before {
  content: "\e93f";
}
.iconm-Info:before {
  content: "\e940";
}
.iconm-Loading:before {
  content: "\e941";
}
.iconm-Location-Line:before {
  content: "\e942";
}
.iconm-Location:before {
  content: "\e943";
}
.iconm-Message1:before {
  content: "\e944";
}
.iconm-Money:before {
  content: "\e945";
}
.iconm-More:before {
  content: "\e946";
}
.iconm-Next:before {
  content: "\e947";
}
.iconm-NoCoupon:before {
  content: "\e948";
}
.iconm-Pen:before {
  content: "\e94e";
}
.iconm-Photo:before {
  content: "\e94f";
}
.iconm-Product1:before {
  content: "\e950";
}
.iconm-Question:before {
  content: "\e951";
}
.iconm-Receipt:before {
  content: "\e952";
}
.iconm-Remove-Round:before {
  content: "\e953";
}
.iconm-Remove:before {
  content: "\e954";
}
.iconm-Repeat:before {
  content: "\e955";
}
.iconm-Return:before {
  content: "\e956";
}
.iconm-Save:before {
  content: "\e957";
}
.iconm-Search:before {
  content: "\e958";
}
.iconm-Send:before {
  content: "\e959";
}
.iconm-Sort:before {
  content: "\e95b";
}
.iconm-Subtract:before {
  content: "\e95c";
}
.iconm-Time:before {
  content: "\e95d";
}
.iconm-Trash:before {
  content: "\e95e";
}
.iconm-bullseye:before {
  content: '';
  background: url("../../assets/img/challenge-icon.png");
  display: block;
  width: 16px;
  height: 16px;
  background-size: 16px;
  
  .nav-link.active &,.nav-link:hover & {
    filter: invert(0.5);
  }
}
.iconm-gmn-message:before {
  content: "";
  background:url("../../assets/img/gmn-message.png");
  display: block;
  width: 16px;
  height: 16px;
  background-size: 16px;

  .nav-link.active &,.nav-link:hover & {
    filter: invert(0.5);
  }
}

/* Font Awesome uses the Unicode Private Use Area (PUA) to ensure screen
readers do not read off random characters that represent icons */

@font-face {
  font-family: 'font-awesome';
  src: url('/font/font-awesome.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="fa-"], [class*=" fa-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'font-awesome' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// Convenience function used to set content property
@function fa-content($fa-var) {
  @return unquote("\"#{ $fa-var }\"");
}

$fa-var-500px: \f26e;
$fa-var-accessible-icon: \f368;
$fa-var-accusoft: \f369;
$fa-var-acquisitions-incorporated: \f6af;
$fa-var-ad: \f641;
$fa-var-address-book: \f2b9;
$fa-var-address-card: \f2bb;
$fa-var-adjust: \f042;
$fa-var-adn: \f170;
$fa-var-adversal: \f36a;
$fa-var-affiliatetheme: \f36b;
$fa-var-air-freshener: \f5d0;
$fa-var-airbnb: \f834;
$fa-var-algolia: \f36c;
$fa-var-align-center: \f037;
$fa-var-align-justify: \f039;
$fa-var-align-left: \f036;
$fa-var-align-right: \f038;
$fa-var-alipay: \f642;
$fa-var-allergies: \f461;
$fa-var-amazon: \f270;
$fa-var-amazon-pay: \f42c;
$fa-var-ambulance: \f0f9;
$fa-var-american-sign-language-interpreting: \f2a3;
$fa-var-amilia: \f36d;
$fa-var-anchor: \f13d;
$fa-var-android: \f17b;
$fa-var-angellist: \f209;
$fa-var-angle-double-down: \f103;
$fa-var-angle-double-left: \f100;
$fa-var-angle-double-right: \f101;
$fa-var-angle-double-up: \f102;
$fa-var-angle-down: \f107;
$fa-var-angle-left: \f104;
$fa-var-angle-right: \f105;
$fa-var-angle-up: \f106;
$fa-var-angry: \f556;
$fa-var-angrycreative: \f36e;
$fa-var-angular: \f420;
$fa-var-ankh: \f644;
$fa-var-app-store: \f36f;
$fa-var-app-store-ios: \f370;
$fa-var-apper: \f371;
$fa-var-apple: \f179;
$fa-var-apple-alt: \f5d1;
$fa-var-apple-pay: \f415;
$fa-var-archive: \f187;
$fa-var-archway: \f557;
$fa-var-arrow-alt-circle-down: \f358;
$fa-var-arrow-alt-circle-left: \f359;
$fa-var-arrow-alt-circle-right: \f35a;
$fa-var-arrow-alt-circle-up: \f35b;
$fa-var-arrow-circle-down: \f0ab;
$fa-var-arrow-circle-left: \f0a8;
$fa-var-arrow-circle-right: \f0a9;
$fa-var-arrow-circle-up: \f0aa;
$fa-var-arrow-down: \f063;
$fa-var-arrow-left: \f060;
$fa-var-arrow-right: \f061;
$fa-var-arrow-up: \f062;
$fa-var-arrows-alt: \f0b2;
$fa-var-arrows-alt-h: \f337;
$fa-var-arrows-alt-v: \f338;
$fa-var-artstation: \f77a;
$fa-var-assistive-listening-systems: \f2a2;
$fa-var-asterisk: \f069;
$fa-var-asymmetrik: \f372;
$fa-var-at: \f1fa;
$fa-var-atlas: \f558;
$fa-var-atlassian: \f77b;
$fa-var-atom: \f5d2;
$fa-var-audible: \f373;
$fa-var-audio-description: \f29e;
$fa-var-autoprefixer: \f41c;
$fa-var-avianex: \f374;
$fa-var-aviato: \f421;
$fa-var-award: \f559;
$fa-var-aws: \f375;
$fa-var-baby: \f77c;
$fa-var-baby-carriage: \f77d;
$fa-var-backspace: \f55a;
$fa-var-backward: \f04a;
$fa-var-bacon: \f7e5;
$fa-var-bacteria: \e059;
$fa-var-bacterium: \e05a;
$fa-var-bahai: \f666;
$fa-var-balance-scale: \f24e;
$fa-var-balance-scale-left: \f515;
$fa-var-balance-scale-right: \f516;
$fa-var-ban: \f05e;
$fa-var-band-aid: \f462;
$fa-var-bandcamp: \f2d5;
$fa-var-barcode: \f02a;
$fa-var-bars: \f0c9;
$fa-var-baseball-ball: \f433;
$fa-var-basketball-ball: \f434;
$fa-var-bath: \f2cd;
$fa-var-battery-empty: \f244;
$fa-var-battery-full: \f240;
$fa-var-battery-half: \f242;
$fa-var-battery-quarter: \f243;
$fa-var-battery-three-quarters: \f241;
$fa-var-battle-net: \f835;
$fa-var-bed: \f236;
$fa-var-beer: \f0fc;
$fa-var-behance: \f1b4;
$fa-var-behance-square: \f1b5;
$fa-var-bell: \f0f3;
$fa-var-bell-slash: \f1f6;
$fa-var-bezier-curve: \f55b;
$fa-var-bible: \f647;
$fa-var-bicycle: \f206;
$fa-var-biking: \f84a;
$fa-var-bimobject: \f378;
$fa-var-binoculars: \f1e5;
$fa-var-biohazard: \f780;
$fa-var-birthday-cake: \f1fd;
$fa-var-bitbucket: \f171;
$fa-var-bitcoin: \f379;
$fa-var-bity: \f37a;
$fa-var-black-tie: \f27e;
$fa-var-blackberry: \f37b;
$fa-var-blender: \f517;
$fa-var-blender-phone: \f6b6;
$fa-var-blind: \f29d;
$fa-var-blog: \f781;
$fa-var-blogger: \f37c;
$fa-var-blogger-b: \f37d;
$fa-var-bluetooth: \f293;
$fa-var-bluetooth-b: \f294;
$fa-var-bold: \f032;
$fa-var-bolt: \f0e7;
$fa-var-bomb: \f1e2;
$fa-var-bone: \f5d7;
$fa-var-bong: \f55c;
$fa-var-book: \f02d;
$fa-var-book-dead: \f6b7;
$fa-var-book-medical: \f7e6;
$fa-var-book-open: \f518;
$fa-var-book-reader: \f5da;
$fa-var-bookmark: \f02e;
$fa-var-bootstrap: \f836;
$fa-var-border-all: \f84c;
$fa-var-border-none: \f850;
$fa-var-border-style: \f853;
$fa-var-bowling-ball: \f436;
$fa-var-box: \f466;
$fa-var-box-open: \f49e;
$fa-var-box-tissue: \e05b;
$fa-var-boxes: \f468;
$fa-var-braille: \f2a1;
$fa-var-brain: \f5dc;
$fa-var-bread-slice: \f7ec;
$fa-var-briefcase: \f0b1;
$fa-var-briefcase-medical: \f469;
$fa-var-broadcast-tower: \f519;
$fa-var-broom: \f51a;
$fa-var-brush: \f55d;
$fa-var-btc: \f15a;
$fa-var-buffer: \f837;
$fa-var-bug: \f188;
$fa-var-building: \f1ad;
$fa-var-bullhorn: \f0a1;
$fa-var-bullseye: \f140;
$fa-var-burn: \f46a;
$fa-var-buromobelexperte: \f37f;
$fa-var-bus: \f207;
$fa-var-bus-alt: \f55e;
$fa-var-business-time: \f64a;
$fa-var-buy-n-large: \f8a6;
$fa-var-buysellads: \f20d;
$fa-var-calculator: \f1ec;
$fa-var-calendar: \f133;
$fa-var-calendar-alt: \f073;
$fa-var-calendar-check: \f274;
$fa-var-calendar-day: \f783;
$fa-var-calendar-minus: \f272;
$fa-var-calendar-plus: \f271;
$fa-var-calendar-times: \f273;
$fa-var-calendar-week: \f784;
$fa-var-camera: \f030;
$fa-var-camera-retro: \f083;
$fa-var-campground: \f6bb;
$fa-var-canadian-maple-leaf: \f785;
$fa-var-candy-cane: \f786;
$fa-var-cannabis: \f55f;
$fa-var-capsules: \f46b;
$fa-var-car: \f1b9;
$fa-var-car-alt: \f5de;
$fa-var-car-battery: \f5df;
$fa-var-car-crash: \f5e1;
$fa-var-car-side: \f5e4;
$fa-var-caravan: \f8ff;
$fa-var-caret-down: \f0d7;
$fa-var-caret-left: \f0d9;
$fa-var-caret-right: \f0da;
$fa-var-caret-square-down: \f150;
$fa-var-caret-square-left: \f191;
$fa-var-caret-square-right: \f152;
$fa-var-caret-square-up: \f151;
$fa-var-caret-up: \f0d8;
$fa-var-carrot: \f787;
$fa-var-cart-arrow-down: \f218;
$fa-var-cart-plus: \f217;
$fa-var-cash-register: \f788;
$fa-var-cat: \f6be;
$fa-var-cc-amazon-pay: \f42d;
$fa-var-cc-amex: \f1f3;
$fa-var-cc-apple-pay: \f416;
$fa-var-cc-diners-club: \f24c;
$fa-var-cc-discover: \f1f2;
$fa-var-cc-jcb: \f24b;
$fa-var-cc-mastercard: \f1f1;
$fa-var-cc-paypal: \f1f4;
$fa-var-cc-stripe: \f1f5;
$fa-var-cc-visa: \f1f0;
$fa-var-centercode: \f380;
$fa-var-centos: \f789;
$fa-var-certificate: \f0a3;
$fa-var-chair: \f6c0;
$fa-var-chalkboard: \f51b;
$fa-var-chalkboard-teacher: \f51c;
$fa-var-charging-station: \f5e7;
$fa-var-chart-area: \f1fe;
$fa-var-chart-bar: \f080;
$fa-var-chart-line: \f201;
$fa-var-chart-pie: \f200;
$fa-var-check: \f00c;
$fa-var-check-circle: \f058;
$fa-var-check-double: \f560;
$fa-var-check-square: \f14a;
$fa-var-cheese: \f7ef;
$fa-var-chess: \f439;
$fa-var-chess-bishop: \f43a;
$fa-var-chess-board: \f43c;
$fa-var-chess-king: \f43f;
$fa-var-chess-knight: \f441;
$fa-var-chess-pawn: \f443;
$fa-var-chess-queen: \f445;
$fa-var-chess-rook: \f447;
$fa-var-chevron-circle-down: \f13a;
$fa-var-chevron-circle-left: \f137;
/* Font Awesome uses the Unicode Private Use Area (PUA) to ensure screen
readers do not read off random characters that represent icons */

// Convenience function used to set content property
@function fa-content($fa-var) {
  @return unquote("\"#{ $fa-var }\"");
}

$fa-var-500px: \f26e;
$fa-var-accessible-icon: \f368;
$fa-var-accusoft: \f369;
$fa-var-acquisitions-incorporated: \f6af;
$fa-var-ad: \f641;
$fa-var-address-book: \f2b9;
$fa-var-address-card: \f2bb;
$fa-var-adjust: \f042;
$fa-var-adn: \f170;
$fa-var-adversal: \f36a;
$fa-var-affiliatetheme: \f36b;
$fa-var-air-freshener: \f5d0;
$fa-var-airbnb: \f834;
$fa-var-algolia: \f36c;
$fa-var-align-center: \f037;
$fa-var-align-justify: \f039;
$fa-var-align-left: \f036;
$fa-var-align-right: \f038;
$fa-var-alipay: \f642;
$fa-var-allergies: \f461;
$fa-var-amazon: \f270;
$fa-var-amazon-pay: \f42c;
$fa-var-ambulance: \f0f9;
$fa-var-american-sign-language-interpreting: \f2a3;
$fa-var-amilia: \f36d;
$fa-var-anchor: \f13d;
$fa-var-android: \f17b;
$fa-var-angellist: \f209;
$fa-var-angle-double-down: \f103;
$fa-var-angle-double-left: \f100;
$fa-var-angle-double-right: \f101;
$fa-var-angle-double-up: \f102;
$fa-var-angle-down: \f107;
$fa-var-angle-left: \f104;
$fa-var-angle-right: \f105;
$fa-var-angle-up: \f106;
$fa-var-angry: \f556;
$fa-var-angrycreative: \f36e;
$fa-var-angular: \f420;
$fa-var-ankh: \f644;
$fa-var-app-store: \f36f;
$fa-var-app-store-ios: \f370;
$fa-var-apper: \f371;
$fa-var-apple: \f179;
$fa-var-apple-alt: \f5d1;
$fa-var-apple-pay: \f415;
$fa-var-archive: \f187;
$fa-var-archway: \f557;
$fa-var-arrow-alt-circle-down: \f358;
$fa-var-arrow-alt-circle-left: \f359;
$fa-var-arrow-alt-circle-right: \f35a;
$fa-var-arrow-alt-circle-up: \f35b;
$fa-var-arrow-circle-down: \f0ab;
$fa-var-arrow-circle-left: \f0a8;
$fa-var-arrow-circle-right: \f0a9;
$fa-var-arrow-circle-up: \f0aa;
$fa-var-arrow-down: \f063;
$fa-var-arrow-left: \f060;
$fa-var-arrow-right: \f061;
$fa-var-arrow-up: \f062;
$fa-var-arrows-alt: \f0b2;
$fa-var-arrows-alt-h: \f337;
$fa-var-arrows-alt-v: \f338;
$fa-var-artstation: \f77a;
$fa-var-assistive-listening-systems: \f2a2;
$fa-var-asterisk: \f069;
$fa-var-asymmetrik: \f372;
$fa-var-at: \f1fa;
$fa-var-atlas: \f558;
$fa-var-atlassian: \f77b;
$fa-var-atom: \f5d2;
$fa-var-audible: \f373;
$fa-var-audio-description: \f29e;
$fa-var-autoprefixer: \f41c;
$fa-var-avianex: \f374;
$fa-var-aviato: \f421;
$fa-var-award: \f559;
$fa-var-aws: \f375;
$fa-var-baby: \f77c;
$fa-var-baby-carriage: \f77d;
$fa-var-backspace: \f55a;
$fa-var-backward: \f04a;
$fa-var-bacon: \f7e5;
$fa-var-bacteria: \e059;
$fa-var-bacterium: \e05a;
$fa-var-bahai: \f666;
$fa-var-balance-scale: \f24e;
$fa-var-balance-scale-left: \f515;
$fa-var-balance-scale-right: \f516;
$fa-var-ban: \f05e;
$fa-var-band-aid: \f462;
$fa-var-bandcamp: \f2d5;
$fa-var-barcode: \f02a;
$fa-var-bars: \f0c9;
$fa-var-baseball-ball: \f433;
$fa-var-basketball-ball: \f434;
$fa-var-bath: \f2cd;
$fa-var-battery-empty: \f244;
$fa-var-battery-full: \f240;
$fa-var-battery-half: \f242;
$fa-var-battery-quarter: \f243;
$fa-var-battery-three-quarters: \f241;
$fa-var-battle-net: \f835;
$fa-var-bed: \f236;
$fa-var-beer: \f0fc;
$fa-var-behance: \f1b4;
$fa-var-behance-square: \f1b5;
$fa-var-bell: \f0f3;
$fa-var-bell-slash: \f1f6;
$fa-var-bezier-curve: \f55b;
$fa-var-bible: \f647;
$fa-var-bicycle: \f206;
$fa-var-biking: \f84a;
$fa-var-bimobject: \f378;
$fa-var-binoculars: \f1e5;
$fa-var-biohazard: \f780;
$fa-var-birthday-cake: \f1fd;
$fa-var-bitbucket: \f171;
$fa-var-bitcoin: \f379;
$fa-var-bity: \f37a;
$fa-var-black-tie: \f27e;
$fa-var-blackberry: \f37b;
$fa-var-blender: \f517;
$fa-var-blender-phone: \f6b6;
$fa-var-blind: \f29d;
$fa-var-blog: \f781;
$fa-var-blogger: \f37c;
$fa-var-blogger-b: \f37d;
$fa-var-bluetooth: \f293;
$fa-var-bluetooth-b: \f294;
$fa-var-bold: \f032;
$fa-var-bolt: \f0e7;
$fa-var-bomb: \f1e2;
$fa-var-bone: \f5d7;
$fa-var-bong: \f55c;
$fa-var-book: \f02d;
$fa-var-book-dead: \f6b7;
$fa-var-book-medical: \f7e6;
$fa-var-book-open: \f518;
$fa-var-book-reader: \f5da;
$fa-var-bookmark: \f02e;
$fa-var-bootstrap: \f836;
$fa-var-border-all: \f84c;
$fa-var-border-none: \f850;
$fa-var-border-style: \f853;
$fa-var-bowling-ball: \f436;
$fa-var-box: \f466;
$fa-var-box-open: \f49e;
$fa-var-box-tissue: \e05b;
$fa-var-boxes: \f468;
$fa-var-braille: \f2a1;
$fa-var-brain: \f5dc;
$fa-var-bread-slice: \f7ec;
$fa-var-briefcase: \f0b1;
$fa-var-briefcase-medical: \f469;
$fa-var-broadcast-tower: \f519;
$fa-var-broom: \f51a;
$fa-var-brush: \f55d;
$fa-var-btc: \f15a;
$fa-var-buffer: \f837;
$fa-var-bug: \f188;
$fa-var-building: \f1ad;
$fa-var-bullhorn: \f0a1;
$fa-var-bullseye: \f140;
$fa-var-burn: \f46a;
$fa-var-buromobelexperte: \f37f;
$fa-var-bus: \f207;
$fa-var-bus-alt: \f55e;
$fa-var-business-time: \f64a;
$fa-var-buy-n-large: \f8a6;
$fa-var-buysellads: \f20d;
$fa-var-calculator: \f1ec;
$fa-var-calendar: \f133;
$fa-var-calendar-alt: \f073;
$fa-var-calendar-check: \f274;
$fa-var-calendar-day: \f783;
$fa-var-calendar-minus: \f272;
$fa-var-calendar-plus: \f271;
$fa-var-calendar-times: \f273;
$fa-var-calendar-week: \f784;
$fa-var-camera: \f030;
$fa-var-camera-retro: \f083;
$fa-var-campground: \f6bb;
$fa-var-canadian-maple-leaf: \f785;
$fa-var-candy-cane: \f786;
$fa-var-cannabis: \f55f;
$fa-var-capsules: \f46b;
$fa-var-car: \f1b9;
$fa-var-car-alt: \f5de;
$fa-var-car-battery: \f5df;
$fa-var-car-crash: \f5e1;
$fa-var-car-side: \f5e4;
$fa-var-caravan: \f8ff;
$fa-var-caret-down: \f0d7;
$fa-var-caret-left: \f0d9;
$fa-var-caret-right: \f0da;
$fa-var-caret-square-down: \f150;
$fa-var-caret-square-left: \f191;
$fa-var-caret-square-right: \f152;
$fa-var-caret-square-up: \f151;
$fa-var-caret-up: \f0d8;
$fa-var-carrot: \f787;
$fa-var-cart-arrow-down: \f218;
$fa-var-cart-plus: \f217;
$fa-var-cash-register: \f788;
$fa-var-cat: \f6be;
$fa-var-cc-amazon-pay: \f42d;
$fa-var-cc-amex: \f1f3;
$fa-var-cc-apple-pay: \f416;
$fa-var-cc-diners-club: \f24c;
$fa-var-cc-discover: \f1f2;
$fa-var-cc-jcb: \f24b;
$fa-var-cc-mastercard: \f1f1;
$fa-var-cc-paypal: \f1f4;
$fa-var-cc-stripe: \f1f5;
$fa-var-cc-visa: \f1f0;
$fa-var-centercode: \f380;
$fa-var-centos: \f789;
$fa-var-certificate: \f0a3;
$fa-var-chair: \f6c0;
$fa-var-chalkboard: \f51b;
$fa-var-chalkboard-teacher: \f51c;
$fa-var-charging-station: \f5e7;
$fa-var-chart-area: \f1fe;
$fa-var-chart-bar: \f080;
$fa-var-chart-line: \f201;
$fa-var-chart-pie: \f200;
$fa-var-check: \f00c;
$fa-var-check-circle: \f058;
$fa-var-check-double: \f560;
$fa-var-check-square: \f14a;
$fa-var-cheese: \f7ef;
$fa-var-chess: \f439;
$fa-var-chess-bishop: \f43a;
$fa-var-chess-board: \f43c;
$fa-var-chess-king: \f43f;
$fa-var-chess-knight: \f441;
$fa-var-chess-pawn: \f443;
$fa-var-chess-queen: \f445;
$fa-var-chess-rook: \f447;
$fa-var-chevron-circle-down: \f13a;
$fa-var-chevron-circle-left: \f137;
/* Font Awesome uses the Unicode Private Use Area (PUA) to ensure screen
readers do not read off random characters that represent icons */

// Convenience function used to set content property
@function fa-content($fa-var) {
  @return unquote("\"#{ $fa-var }\"");
}

$fa-var-500px: \f26e;
$fa-var-accessible-icon: \f368;
$fa-var-accusoft: \f369;
$fa-var-acquisitions-incorporated: \f6af;
$fa-var-ad: \f641;
$fa-var-address-book: \f2b9;
$fa-var-address-card: \f2bb;
$fa-var-adjust: \f042;
$fa-var-adn: \f170;
$fa-var-adversal: \f36a;
$fa-var-affiliatetheme: \f36b;
$fa-var-air-freshener: \f5d0;
$fa-var-airbnb: \f834;
$fa-var-algolia: \f36c;
$fa-var-align-center: \f037;
$fa-var-align-justify: \f039;
$fa-var-align-left: \f036;
$fa-var-align-right: \f038;
$fa-var-alipay: \f642;
$fa-var-allergies: \f461;
$fa-var-amazon: \f270;
$fa-var-amazon-pay: \f42c;
$fa-var-ambulance: \f0f9;
$fa-var-american-sign-language-interpreting: \f2a3;
$fa-var-amilia: \f36d;
$fa-var-anchor: \f13d;
$fa-var-android: \f17b;
$fa-var-angellist: \f209;
$fa-var-angle-double-down: \f103;
$fa-var-angle-double-left: \f100;
$fa-var-angle-double-right: \f101;
$fa-var-angle-double-up: \f102;
$fa-var-angle-down: \f107;
$fa-var-angle-left: \f104;
$fa-var-angle-right: \f105;
$fa-var-angle-up: \f106;
$fa-var-angry: \f556;
$fa-var-angrycreative: \f36e;
$fa-var-angular: \f420;
$fa-var-ankh: \f644;
$fa-var-app-store: \f36f;
$fa-var-app-store-ios: \f370;
$fa-var-apper: \f371;
$fa-var-apple: \f179;
$fa-var-apple-alt: \f5d1;
$fa-var-apple-pay: \f415;
$fa-var-archive: \f187;
$fa-var-archway: \f557;
$fa-var-arrow-alt-circle-down: \f358;
$fa-var-arrow-alt-circle-left: \f359;
$fa-var-arrow-alt-circle-right: \f35a;
$fa-var-arrow-alt-circle-up: \f35b;
$fa-var-arrow-circle-down: \f0ab;
$fa-var-arrow-circle-left: \f0a8;
$fa-var-arrow-circle-right: \f0a9;
$fa-var-arrow-circle-up: \f0aa;
$fa-var-arrow-down: \f063;
$fa-var-arrow-left: \f060;
$fa-var-arrow-right: \f061;
$fa-var-arrow-up: \f062;
$fa-var-arrows-alt: \f0b2;
$fa-var-arrows-alt-h: \f337;
$fa-var-arrows-alt-v: \f338;
$fa-var-artstation: \f77a;
$fa-var-assistive-listening-systems: \f2a2;
$fa-var-asterisk: \f069;
$fa-var-asymmetrik: \f372;
$fa-var-at: \f1fa;
$fa-var-atlas: \f558;
$fa-var-atlassian: \f77b;
$fa-var-atom: \f5d2;
$fa-var-audible: \f373;
$fa-var-audio-description: \f29e;
$fa-var-autoprefixer: \f41c;
$fa-var-avianex: \f374;
$fa-var-aviato: \f421;
$fa-var-award: \f559;
$fa-var-aws: \f375;
$fa-var-baby: \f77c;
$fa-var-baby-carriage: \f77d;
$fa-var-backspace: \f55a;
$fa-var-backward: \f04a;
$fa-var-bacon: \f7e5;
$fa-var-bacteria: \e059;
$fa-var-bacterium: \e05a;
$fa-var-bahai: \f666;
$fa-var-balance-scale: \f24e;
$fa-var-balance-scale-left: \f515;
$fa-var-balance-scale-right: \f516;
$fa-var-ban: \f05e;
$fa-var-band-aid: \f462;
$fa-var-bandcamp: \f2d5;
$fa-var-barcode: \f02a;
$fa-var-bars: \f0c9;
$fa-var-baseball-ball: \f433;
$fa-var-basketball-ball: \f434;
$fa-var-bath: \f2cd;
$fa-var-battery-empty: \f244;
$fa-var-battery-full: \f240;
$fa-var-battery-half: \f242;
$fa-var-battery-quarter: \f243;
$fa-var-battery-three-quarters: \f241;
$fa-var-battle-net: \f835;
$fa-var-bed: \f236;
$fa-var-beer: \f0fc;
$fa-var-behance: \f1b4;
$fa-var-behance-square: \f1b5;
$fa-var-bell: \f0f3;
$fa-var-bell-slash: \f1f6;
$fa-var-bezier-curve: \f55b;
$fa-var-bible: \f647;
$fa-var-bicycle: \f206;
$fa-var-biking: \f84a;
$fa-var-bimobject: \f378;
$fa-var-binoculars: \f1e5;
$fa-var-biohazard: \f780;
$fa-var-birthday-cake: \f1fd;
$fa-var-bitbucket: \f171;
$fa-var-bitcoin: \f379;
$fa-var-bity: \f37a;
$fa-var-black-tie: \f27e;
$fa-var-blackberry: \f37b;
$fa-var-blender: \f517;
$fa-var-blender-phone: \f6b6;
$fa-var-blind: \f29d;
$fa-var-blog: \f781;
$fa-var-blogger: \f37c;
$fa-var-blogger-b: \f37d;
$fa-var-bluetooth: \f293;
$fa-var-bluetooth-b: \f294;
$fa-var-bold: \f032;
$fa-var-bolt: \f0e7;
$fa-var-bomb: \f1e2;
$fa-var-bone: \f5d7;
$fa-var-bong: \f55c;
$fa-var-book: \f02d;
$fa-var-book-dead: \f6b7;
$fa-var-book-medical: \f7e6;
$fa-var-book-open: \f518;
$fa-var-book-reader: \f5da;
$fa-var-bookmark: \f02e;
$fa-var-bootstrap: \f836;
$fa-var-border-all: \f84c;
$fa-var-border-none: \f850;
$fa-var-border-style: \f853;
$fa-var-bowling-ball: \f436;
$fa-var-box: \f466;
$fa-var-box-open: \f49e;
$fa-var-box-tissue: \e05b;
$fa-var-boxes: \f468;
$fa-var-braille: \f2a1;
$fa-var-brain: \f5dc;
$fa-var-bread-slice: \f7ec;
$fa-var-briefcase: \f0b1;
$fa-var-briefcase-medical: \f469;
$fa-var-broadcast-tower: \f519;
$fa-var-broom: \f51a;
$fa-var-brush: \f55d;
$fa-var-btc: \f15a;
$fa-var-buffer: \f837;
$fa-var-bug: \f188;
$fa-var-building: \f1ad;
$fa-var-bullhorn: \f0a1;
$fa-var-bullseye: \f140;
$fa-var-burn: \f46a;
$fa-var-buromobelexperte: \f37f;
$fa-var-bus: \f207;
$fa-var-bus-alt: \f55e;
$fa-var-business-time: \f64a;
$fa-var-buy-n-large: \f8a6;
$fa-var-buysellads: \f20d;
$fa-var-calculator: \f1ec;
$fa-var-calendar: \f133;
$fa-var-calendar-alt: \f073;
$fa-var-calendar-check: \f274;
$fa-var-calendar-day: \f783;
$fa-var-calendar-minus: \f272;
$fa-var-calendar-plus: \f271;
$fa-var-calendar-times: \f273;
$fa-var-calendar-week: \f784;
$fa-var-camera: \f030;
$fa-var-camera-retro: \f083;
$fa-var-campground: \f6bb;
$fa-var-canadian-maple-leaf: \f785;
$fa-var-candy-cane: \f786;
$fa-var-cannabis: \f55f;
$fa-var-capsules: \f46b;
$fa-var-car: \f1b9;
$fa-var-car-alt: \f5de;
$fa-var-car-battery: \f5df;
$fa-var-car-crash: \f5e1;
$fa-var-car-side: \f5e4;
$fa-var-caravan: \f8ff;
$fa-var-caret-down: \f0d7;
$fa-var-caret-left: \f0d9;
$fa-var-caret-right: \f0da;
$fa-var-caret-square-down: \f150;
$fa-var-caret-square-left: \f191;
$fa-var-caret-square-right: \f152;
$fa-var-caret-square-up: \f151;
$fa-var-caret-up: \f0d8;
$fa-var-carrot: \f787;
$fa-var-cart-arrow-down: \f218;
$fa-var-cart-plus: \f217;
$fa-var-cash-register: \f788;
$fa-var-cat: \f6be;
$fa-var-cc-amazon-pay: \f42d;
$fa-var-cc-amex: \f1f3;
$fa-var-cc-apple-pay: \f416;
$fa-var-cc-diners-club: \f24c;
$fa-var-cc-discover: \f1f2;
$fa-var-cc-jcb: \f24b;
$fa-var-cc-mastercard: \f1f1;
$fa-var-cc-paypal: \f1f4;
$fa-var-cc-stripe: \f1f5;
$fa-var-cc-visa: \f1f0;
$fa-var-centercode: \f380;
$fa-var-centos: \f789;
$fa-var-certificate: \f0a3;
$fa-var-chair: \f6c0;
$fa-var-chalkboard: \f51b;
$fa-var-chalkboard-teacher: \f51c;
$fa-var-charging-station: \f5e7;
$fa-var-chart-area: \f1fe;
$fa-var-chart-bar: \f080;
$fa-var-chart-line: \f201;
$fa-var-chart-pie: \f200;
$fa-var-check: \f00c;
$fa-var-check-circle: \f058;
$fa-var-check-double: \f560;
$fa-var-check-square: \f14a;
$fa-var-cheese: \f7ef;
$fa-var-chess: \f439;
$fa-var-chess-bishop: \f43a;
$fa-var-chess-board: \f43c;
$fa-var-chess-king: \f43f;
$fa-var-chess-knight: \f441;
$fa-var-chess-pawn: \f443;
$fa-var-chess-queen: \f445;
$fa-var-chess-rook: \f447;
$fa-var-chevron-circle-down: \f13a;
$fa-var-chevron-circle-left: \f137;
/* Font Awesome uses the Unicode Private Use Area (PUA) to ensure screen
readers do not read off random characters that represent icons */

// Convenience function used to set content property
@function fa-content($fa-var) {
  @return unquote("\"#{ $fa-var }\"");
}

$fa-var-500px: \f26e;
$fa-var-accessible-icon: \f368;
$fa-var-accusoft: \f369;
$fa-var-acquisitions-incorporated: \f6af;
$fa-var-ad: \f641;
$fa-var-address-book: \f2b9;
$fa-var-address-card: \f2bb;
$fa-var-adjust: \f042;
$fa-var-adn: \f170;
$fa-var-adversal: \f36a;
$fa-var-affiliatetheme: \f36b;
$fa-var-air-freshener: \f5d0;
$fa-var-airbnb: \f834;
$fa-var-algolia: \f36c;
$fa-var-align-center: \f037;
$fa-var-align-justify: \f039;
$fa-var-align-left: \f036;
$fa-var-align-right: \f038;
$fa-var-alipay: \f642;
$fa-var-allergies: \f461;
$fa-var-amazon: \f270;
$fa-var-amazon-pay: \f42c;
$fa-var-ambulance: \f0f9;
$fa-var-american-sign-language-interpreting: \f2a3;
$fa-var-amilia: \f36d;
$fa-var-anchor: \f13d;
$fa-var-android: \f17b;
$fa-var-angellist: \f209;
$fa-var-angle-double-down: \f103;
$fa-var-angle-double-left: \f100;
$fa-var-angle-double-right: \f101;
$fa-var-angle-double-up: \f102;
$fa-var-angle-down: \f107;
$fa-var-angle-left: \f104;
$fa-var-angle-right: \f105;
$fa-var-angle-up: \f106;
$fa-var-angry: \f556;
$fa-var-angrycreative: \f36e;
$fa-var-angular: \f420;
$fa-var-ankh: \f644;
$fa-var-app-store: \f36f;
$fa-var-app-store-ios: \f370;
$fa-var-apper: \f371;
$fa-var-apple: \f179;
$fa-var-apple-alt: \f5d1;
$fa-var-apple-pay: \f415;
$fa-var-archive: \f187;
$fa-var-archway: \f557;
$fa-var-arrow-alt-circle-down: \f358;
$fa-var-arrow-alt-circle-left: \f359;
$fa-var-arrow-alt-circle-right: \f35a;
$fa-var-arrow-alt-circle-up: \f35b;
$fa-var-arrow-circle-down: \f0ab;
$fa-var-arrow-circle-left: \f0a8;
$fa-var-arrow-circle-right: \f0a9;
$fa-var-arrow-circle-up: \f0aa;
$fa-var-arrow-down: \f063;
$fa-var-arrow-left: \f060;
$fa-var-arrow-right: \f061;
$fa-var-arrow-up: \f062;
$fa-var-arrows-alt: \f0b2;
$fa-var-arrows-alt-h: \f337;
$fa-var-arrows-alt-v: \f338;
$fa-var-artstation: \f77a;
$fa-var-assistive-listening-systems: \f2a2;
$fa-var-asterisk: \f069;
$fa-var-asymmetrik: \f372;
$fa-var-at: \f1fa;
$fa-var-atlas: \f558;
$fa-var-atlassian: \f77b;
$fa-var-atom: \f5d2;
$fa-var-audible: \f373;
$fa-var-audio-description: \f29e;
$fa-var-autoprefixer: \f41c;
$fa-var-avianex: \f374;
$fa-var-aviato: \f421;
$fa-var-award: \f559;
$fa-var-aws: \f375;
$fa-var-baby: \f77c;
$fa-var-baby-carriage: \f77d;
$fa-var-backspace: \f55a;
$fa-var-backward: \f04a;
$fa-var-bacon: \f7e5;
$fa-var-bacteria: \e059;
$fa-var-bacterium: \e05a;
$fa-var-bahai: \f666;
$fa-var-balance-scale: \f24e;
$fa-var-balance-scale-left: \f515;
$fa-var-balance-scale-right: \f516;
$fa-var-ban: \f05e;
$fa-var-band-aid: \f462;
$fa-var-bandcamp: \f2d5;
$fa-var-barcode: \f02a;
$fa-var-bars: \f0c9;
$fa-var-baseball-ball: \f433;
$fa-var-basketball-ball: \f434;
$fa-var-bath: \f2cd;
$fa-var-battery-empty: \f244;
$fa-var-battery-full: \f240;
$fa-var-battery-half: \f242;
$fa-var-battery-quarter: \f243;
$fa-var-battery-three-quarters: \f241;
$fa-var-battle-net: \f835;
$fa-var-bed: \f236;
$fa-var-beer: \f0fc;
$fa-var-behance: \f1b4;
$fa-var-behance-square: \f1b5;
$fa-var-bell: \f0f3;
$fa-var-bell-slash: \f1f6;
$fa-var-bezier-curve: \f55b;
$fa-var-bible: \f647;
$fa-var-bicycle: \f206;
$fa-var-biking: \f84a;
$fa-var-bimobject: \f378;
$fa-var-binoculars: \f1e5;
$fa-var-biohazard: \f780;
$fa-var-birthday-cake: \f1fd;
$fa-var-bitbucket: \f171;
$fa-var-bitcoin: \f379;
$fa-var-bity: \f37a;
$fa-var-black-tie: \f27e;
$fa-var-blackberry: \f37b;
$fa-var-blender: \f517;
$fa-var-blender-phone: \f6b6;
$fa-var-blind: \f29d;
$fa-var-blog: \f781;
$fa-var-blogger: \f37c;
$fa-var-blogger-b: \f37d;
$fa-var-bluetooth: \f293;
$fa-var-bluetooth-b: \f294;
$fa-var-bold: \f032;
$fa-var-bolt: \f0e7;
$fa-var-bomb: \f1e2;
$fa-var-bone: \f5d7;
$fa-var-bong: \f55c;
$fa-var-book: \f02d;
$fa-var-book-dead: \f6b7;
$fa-var-book-medical: \f7e6;
$fa-var-book-open: \f518;
$fa-var-book-reader: \f5da;
$fa-var-bookmark: \f02e;
$fa-var-bootstrap: \f836;
$fa-var-border-all: \f84c;
$fa-var-border-none: \f850;
$fa-var-border-style: \f853;
$fa-var-bowling-ball: \f436;
$fa-var-box: \f466;
$fa-var-box-open: \f49e;
$fa-var-box-tissue: \e05b;
$fa-var-boxes: \f468;
$fa-var-braille: \f2a1;
$fa-var-brain: \f5dc;
$fa-var-bread-slice: \f7ec;
$fa-var-briefcase: \f0b1;
$fa-var-briefcase-medical: \f469;
$fa-var-broadcast-tower: \f519;
$fa-var-broom: \f51a;
$fa-var-brush: \f55d;
$fa-var-btc: \f15a;
$fa-var-buffer: \f837;
$fa-var-bug: \f188;
$fa-var-building: \f1ad;
$fa-var-bullhorn: \f0a1;
$fa-var-bullseye: \f140;
$fa-var-burn: \f46a;
$fa-var-buromobelexperte: \f37f;
$fa-var-bus: \f207;
$fa-var-bus-alt: \f55e;
$fa-var-business-time: \f64a;
$fa-var-buy-n-large: \f8a6;
$fa-var-buysellads: \f20d;
$fa-var-calculator: \f1ec;
$fa-var-calendar: \f133;
$fa-var-calendar-alt: \f073;
$fa-var-calendar-check: \f274;
$fa-var-calendar-day: \f783;
$fa-var-calendar-minus: \f272;
$fa-var-calendar-plus: \f271;
$fa-var-calendar-times: \f273;
$fa-var-calendar-week: \f784;
$fa-var-camera: \f030;
$fa-var-camera-retro: \f083;
$fa-var-campground: \f6bb;
$fa-var-canadian-maple-leaf: \f785;
$fa-var-candy-cane: \f786;
$fa-var-cannabis: \f55f;
$fa-var-capsules: \f46b;
$fa-var-car: \f1b9;
$fa-var-car-alt: \f5de;
$fa-var-car-battery: \f5df;
$fa-var-car-crash: \f5e1;
$fa-var-car-side: \f5e4;
$fa-var-caravan: \f8ff;
$fa-var-caret-down: \f0d7;
$fa-var-caret-left: \f0d9;
$fa-var-caret-right: \f0da;
$fa-var-caret-square-down: \f150;
$fa-var-caret-square-left: \f191;
$fa-var-caret-square-right: \f152;
$fa-var-caret-square-up: \f151;
$fa-var-caret-up: \f0d8;
$fa-var-carrot: \f787;
$fa-var-cart-arrow-down: \f218;
$fa-var-cart-plus: \f217;
$fa-var-cash-register: \f788;
$fa-var-cat: \f6be;
$fa-var-cc-amazon-pay: \f42d;
$fa-var-cc-amex: \f1f3;
$fa-var-cc-apple-pay: \f416;
$fa-var-cc-diners-club: \f24c;
$fa-var-cc-discover: \f1f2;
$fa-var-cc-jcb: \f24b;
$fa-var-cc-mastercard: \f1f1;
$fa-var-cc-paypal: \f1f4;
$fa-var-cc-stripe: \f1f5;
$fa-var-cc-visa: \f1f0;
$fa-var-centercode: \f380;
$fa-var-centos: \f789;
$fa-var-certificate: \f0a3;
$fa-var-chair: \f6c0;
$fa-var-chalkboard: \f51b;
$fa-var-chalkboard-teacher: \f51c;
$fa-var-charging-station: \f5e7;
$fa-var-chart-area: \f1fe;
$fa-var-chart-bar: \f080;
$fa-var-chart-line: \f201;
$fa-var-chart-pie: \f200;
$fa-var-check: \f00c;
$fa-var-check-circle: \f058;
$fa-var-check-double: \f560;
$fa-var-check-square: \f14a;
$fa-var-cheese: \f7ef;
$fa-var-chess: \f439;
$fa-var-chess-bishop: \f43a;
$fa-var-chess-board: \f43c;
$fa-var-chess-king: \f43f;
$fa-var-chess-knight: \f441;
$fa-var-chess-pawn: \f443;
$fa-var-chess-queen: \f445;
$fa-var-chess-rook: \f447;
$fa-var-chevron-circle-down: \f13a;
$fa-var-chevron-circle-left: \f137;
/* Font Awesome uses the Unicode Private Use Area (PUA) to ensure screen
readers do not read off random characters that represent icons */

// Convenience function used to set content property
@function fa-content($fa-var) {
  @return unquote("\"#{ $fa-var }\"");
}

$fa-var-500px: \f26e;
$fa-var-accessible-icon: \f368;
$fa-var-accusoft: \f369;
$fa-var-acquisitions-incorporated: \f6af;
$fa-var-ad: \f641;
$fa-var-address-book: \f2b9;
$fa-var-address-card: \f2bb;
$fa-var-adjust: \f042;
$fa-var-adn: \f170;
$fa-var-adversal: \f36a;
$fa-var-affiliatetheme: \f36b;
$fa-var-air-freshener: \f5d0;
$fa-var-airbnb: \f834;
$fa-var-algolia: \f36c;
$fa-var-align-center: \f037;
$fa-var-align-justify: \f039;
$fa-var-align-left: \f036;
$fa-var-align-right: \f038;
$fa-var-alipay: \f642;
$fa-var-allergies: \f461;
$fa-var-amazon: \f270;
$fa-var-amazon-pay: \f42c;
$fa-var-ambulance: \f0f9;
$fa-var-american-sign-language-interpreting: \f2a3;
$fa-var-amilia: \f36d;
$fa-var-anchor: \f13d;
$fa-var-android: \f17b;
$fa-var-angellist: \f209;
$fa-var-angle-double-down: \f103;
$fa-var-angle-double-left: \f100;
$fa-var-angle-double-right: \f101;
$fa-var-angle-double-up: \f102;
$fa-var-angle-down: \f107;
$fa-var-angle-left: \f104;
$fa-var-angle-right: \f105;
$fa-var-angle-up: \f106;
$fa-var-angry: \f556;
$fa-var-angrycreative: \f36e;
$fa-var-angular: \f420;
$fa-var-ankh: \f644;
$fa-var-app-store: \f36f;
$fa-var-app-store-ios: \f370;
$fa-var-apper: \f371;
$fa-var-apple: \f179;
$fa-var-apple-alt: \f5d1;
$fa-var-apple-pay: \f415;
$fa-var-archive: \f187;
$fa-var-archway: \f557;
$fa-var-arrow-alt-circle-down: \f358;
$fa-var-arrow-alt-circle-left: \f359;
$fa-var-arrow-alt-circle-right: \f35a;
$fa-var-arrow-alt-circle-up: \f35b;
$fa-var-arrow-circle-down: \f0ab;
$fa-var-arrow-circle-left: \f0a8;
$fa-var-arrow-circle-right: \f0a9;
$fa-var-arrow-circle-up: \f0aa;
$fa-var-arrow-down: \f063;
$fa-var-arrow-left: \f060;
$fa-var-arrow-right: \f061;
$fa-var-arrow-up: \f062;
$fa-var-arrows-alt: \f0b2;
$fa-var-arrows-alt-h: \f337;
$fa-var-arrows-alt-v: \f338;
$fa-var-artstation: \f77a;
$fa-var-assistive-listening-systems: \f2a2;
$fa-var-asterisk: \f069;
$fa-var-asymmetrik: \f372;
$fa-var-at: \f1fa;
$fa-var-atlas: \f558;
$fa-var-atlassian: \f77b;
$fa-var-atom: \f5d2;
$fa-var-audible: \f373;
$fa-var-audio-description: \f29e;
$fa-var-autoprefixer: \f41c;
$fa-var-avianex: \f374;
$fa-var-aviato: \f421;
$fa-var-award: \f559;
$fa-var-aws: \f375;
$fa-var-baby: \f77c;
$fa-var-baby-carriage: \f77d;
$fa-var-backspace: \f55a;
$fa-var-backward: \f04a;
$fa-var-bacon: \f7e5;
$fa-var-bacteria: \e059;
$fa-var-bacterium: \e05a;
$fa-var-bahai: \f666;
$fa-var-balance-scale: \f24e;
$fa-var-balance-scale-left: \f515;
$fa-var-balance-scale-right: \f516;
$fa-var-ban: \f05e;
$fa-var-band-aid: \f462;
$fa-var-bandcamp: \f2d5;
$fa-var-barcode: \f02a;
$fa-var-bars: \f0c9;
$fa-var-baseball-ball: \f433;
$fa-var-basketball-ball: \f434;
$fa-var-bath: \f2cd;
$fa-var-battery-empty: \f244;
$fa-var-battery-full: \f240;
$fa-var-battery-half: \f242;
$fa-var-battery-quarter: \f243;
$fa-var-battery-three-quarters: \f241;
$fa-var-battle-net: \f835;
$fa-var-bed: \f236;
$fa-var-beer: \f0fc;
$fa-var-behance: \f1b4;
$fa-var-behance-square: \f1b5;
$fa-var-bell: \f0f3;
$fa-var-bell-slash: \f1f6;
$fa-var-bezier-curve: \f55b;
$fa-var-bible: \f647;
$fa-var-bicycle: \f206;
$fa-var-biking: \f84a;
$fa-var-bimobject: \f378;
$fa-var-binoculars: \f1e5;
$fa-var-biohazard: \f780;
$fa-var-birthday-cake: \f1fd;
$fa-var-bitbucket: \f171;
$fa-var-bitcoin: \f379;
$fa-var-bity: \f37a;
$fa-var-black-tie: \f27e;
$fa-var-blackberry: \f37b;
$fa-var-blender: \f517;
$fa-var-blender-phone: \f6b6;
$fa-var-blind: \f29d;
$fa-var-blog: \f781;
$fa-var-blogger: \f37c;
$fa-var-blogger-b: \f37d;
$fa-var-bluetooth: \f293;
$fa-var-bluetooth-b: \f294;
$fa-var-bold: \f032;
$fa-var-bolt: \f0e7;
$fa-var-bomb: \f1e2;
$fa-var-bone: \f5d7;
$fa-var-bong: \f55c;
$fa-var-book: \f02d;
$fa-var-book-dead: \f6b7;
$fa-var-book-medical: \f7e6;
$fa-var-book-open: \f518;
$fa-var-book-reader: \f5da;
$fa-var-bookmark: \f02e;
$fa-var-bootstrap: \f836;
$fa-var-border-all: \f84c;
$fa-var-border-none: \f850;
$fa-var-border-style: \f853;
$fa-var-bowling-ball: \f436;
$fa-var-box: \f466;
$fa-var-box-open: \f49e;
$fa-var-box-tissue: \e05b;
$fa-var-boxes: \f468;
$fa-var-braille: \f2a1;
$fa-var-brain: \f5dc;
$fa-var-bread-slice: \f7ec;
$fa-var-briefcase: \f0b1;
$fa-var-briefcase-medical: \f469;
$fa-var-broadcast-tower: \f519;
$fa-var-broom: \f51a;
$fa-var-brush: \f55d;
$fa-var-btc: \f15a;
$fa-var-buffer: \f837;
$fa-var-bug: \f188;
$fa-var-building: \f1ad;
$fa-var-bullhorn: \f0a1;
$fa-var-bullseye: \f140;
$fa-var-burn: \f46a;
$fa-var-buromobelexperte: \f37f;
$fa-var-bus: \f207;
$fa-var-bus-alt: \f55e;
$fa-var-business-time: \f64a;
$fa-var-buy-n-large: \f8a6;
$fa-var-buysellads: \f20d;
$fa-var-calculator: \f1ec;
$fa-var-calendar: \f133;
$fa-var-calendar-alt: \f073;
$fa-var-calendar-check: \f274;
$fa-var-calendar-day: \f783;
$fa-var-calendar-minus: \f272;
$fa-var-calendar-plus: \f271;
$fa-var-calendar-times: \f273;
$fa-var-calendar-week: \f784;
$fa-var-camera: \f030;
$fa-var-camera-retro: \f083;
$fa-var-campground: \f6bb;
$fa-var-canadian-maple-leaf: \f785;
$fa-var-candy-cane: \f786;
$fa-var-cannabis: \f55f;
$fa-var-capsules: \f46b;
$fa-var-car: \f1b9;
$fa-var-car-alt: \f5de;
$fa-var-car-battery: \f5df;
$fa-var-car-crash: \f5e1;
$fa-var-car-side: \f5e4;
$fa-var-caravan: \f8ff;
$fa-var-caret-down: \f0d7;
$fa-var-caret-left: \f0d9;
$fa-var-caret-right: \f0da;
$fa-var-caret-square-down: \f150;
$fa-var-caret-square-left: \f191;
$fa-var-caret-square-right: \f152;
$fa-var-caret-square-up: \f151;
$fa-var-caret-up: \f0d8;
$fa-var-carrot: \f787;
$fa-var-cart-arrow-down: \f218;
$fa-var-cart-plus: \f217;
$fa-var-cash-register: \f788;
$fa-var-cat: \f6be;
$fa-var-cc-amazon-pay: \f42d;
$fa-var-cc-amex: \f1f3;
$fa-var-cc-apple-pay: \f416;
$fa-var-cc-diners-club: \f24c;
$fa-var-cc-discover: \f1f2;
$fa-var-cc-jcb: \f24b;
$fa-var-cc-mastercard: \f1f1;
$fa-var-cc-paypal: \f1f4;
$fa-var-cc-stripe: \f1f5;
$fa-var-cc-visa: \f1f0;
$fa-var-centercode: \f380;
$fa-var-centos: \f789;
$fa-var-certificate: \f0a3;
$fa-var-chair: \f6c0;
$fa-var-chalkboard: \f51b;
$fa-var-chalkboard-teacher: \f51c;
$fa-var-charging-station: \f5e7;
$fa-var-chart-area: \f1fe;
$fa-var-chart-bar: \f080;
$fa-var-chart-line: \f201;
$fa-var-chart-pie: \f200;
$fa-var-check: \f00c;
$fa-var-check-circle: \f058;
$fa-var-check-double: \f560;
$fa-var-check-square: \f14a;
$fa-var-cheese: \f7ef;
$fa-var-chess: \f439;
$fa-var-chess-bishop: \f43a;
$fa-var-chess-board: \f43c;
$fa-var-chess-king: \f43f;
$fa-var-chess-knight: \f441;
$fa-var-chess-pawn: \f443;
$fa-var-chess-queen: \f445;
$fa-var-chess-rook: \f447;
$fa-var-chevron-circle-down: \f13a;
$fa-var-chevron-circle-left: \f137;
$fa-var-chevron-circle-right: \f138;
$fa-var-chevron-circle-up: \f139;
$fa-var-chevron-down: \f078;
$fa-var-chevron-left: \f053;
$fa-var-chevron-right: \f054;
$fa-var-chevron-up: \f077;
$fa-var-child: \f1ae;
$fa-var-chrome: \f268;
$fa-var-chromecast: \f838;
$fa-var-church: \f51d;
$fa-var-circle: \f111;
$fa-var-circle-notch: \f1ce;
$fa-var-city: \f64f;
$fa-var-clinic-medical: \f7f2;
$fa-var-clipboard: \f328;
$fa-var-clipboard-check: \f46c;
$fa-var-clipboard-list: \f46d;
$fa-var-clock: \f017;
$fa-var-clone: \f24d;
$fa-var-closed-captioning: \f20a;
$fa-var-cloud: \f0c2;
$fa-var-cloud-download-alt: \f381;
$fa-var-cloud-meatball: \f73b;
$fa-var-cloud-moon: \f6c3;
$fa-var-cloud-moon-rain: \f73c;
$fa-var-cloud-rain: \f73d;
$fa-var-cloud-showers-heavy: \f740;
$fa-var-cloud-sun: \f6c4;
$fa-var-cloud-sun-rain: \f743;
$fa-var-cloud-upload-alt: \f382;
$fa-var-cloudflare: \e07d;
$fa-var-cloudscale: \f383;
$fa-var-cloudsmith: \f384;
$fa-var-cloudversify: \f385;
$fa-var-cocktail: \f561;
$fa-var-code: \f121;
$fa-var-code-branch: \f126;
$fa-var-codepen: \f1cb;
$fa-var-codiepie: \f284;
$fa-var-coffee: \f0f4;
$fa-var-cog: \f013;
$fa-var-cogs: \f085;
$fa-var-coins: \f51e;
$fa-var-columns: \f0db;
$fa-var-comment: \f075;
$fa-var-comment-alt: \f27a;
$fa-var-comment-dollar: \f651;
$fa-var-comment-dots: \f4ad;
$fa-var-comment-medical: \f7f5;
$fa-var-comment-slash: \f4b3;
$fa-var-comments: \f086;
$fa-var-comments-dollar: \f653;
$fa-var-compact-disc: \f51f;
$fa-var-compass: \f14e;
$fa-var-compress: \f066;
$fa-var-compress-alt: \f422;
$fa-var-compress-arrows-alt: \f78c;
$fa-var-concierge-bell: \f562;
$fa-var-confluence: \f78d;
$fa-var-connectdevelop: \f20e;
$fa-var-contao: \f26d;
$fa-var-cookie: \f563;
$fa-var-cookie-bite: \f564;
$fa-var-copy: \f0c5;
$fa-var-copyright: \f1f9;
$fa-var-cotton-bureau: \f89e;
$fa-var-couch: \f4b8;
$fa-var-cpanel: \f388;
$fa-var-creative-commons: \f25e;
$fa-var-creative-commons-by: \f4e7;
$fa-var-creative-commons-nc: \f4e8;
$fa-var-creative-commons-nc-eu: \f4e9;
$fa-var-creative-commons-nc-jp: \f4ea;
$fa-var-creative-commons-nd: \f4eb;
$fa-var-creative-commons-pd: \f4ec;
$fa-var-creative-commons-pd-alt: \f4ed;
$fa-var-creative-commons-remix: \f4ee;
$fa-var-creative-commons-sa: \f4ef;
$fa-var-creative-commons-sampling: \f4f0;
$fa-var-creative-commons-sampling-plus: \f4f1;
$fa-var-creative-commons-share: \f4f2;
$fa-var-creative-commons-zero: \f4f3;
$fa-var-credit-card: \f09d;
$fa-var-critical-role: \f6c9;
$fa-var-crop: \f125;
$fa-var-crop-alt: \f565;
$fa-var-cross: \f654;
$fa-var-crosshairs: \f05b;
$fa-var-crow: \f520;
$fa-var-crown: \f521;
$fa-var-crutch: \f7f7;
$fa-var-css3: \f13c;
$fa-var-css3-alt: \f38b;
$fa-var-cube: \f1b2;
$fa-var-cubes: \f1b3;
$fa-var-cut: \f0c4;
$fa-var-cuttlefish: \f38c;
$fa-var-d-and-d: \f38d;
$fa-var-d-and-d-beyond: \f6ca;
$fa-var-dailymotion: \e052;
$fa-var-dashcube: \f210;
$fa-var-database: \f1c0;
$fa-var-deaf: \f2a4;
$fa-var-deezer: \e077;
$fa-var-delicious: \f1a5;
$fa-var-democrat: \f747;
$fa-var-deploydog: \f38e;
$fa-var-deskpro: \f38f;
$fa-var-desktop: \f108;
$fa-var-dev: \f6cc;
$fa-var-deviantart: \f1bd;
$fa-var-dharmachakra: \f655;
$fa-var-dhl: \f790;
$fa-var-diagnoses: \f470;
$fa-var-diaspora: \f791;
$fa-var-dice: \f522;
$fa-var-dice-d20: \f6cf;
$fa-var-dice-d6: \f6d1;
$fa-var-dice-five: \f523;
$fa-var-dice-four: \f524;
$fa-var-dice-one: \f525;
$fa-var-dice-six: \f526;
$fa-var-dice-three: \f527;
$fa-var-dice-two: \f528;
$fa-var-digg: \f1a6;
$fa-var-digital-ocean: \f391;
$fa-var-digital-tachograph: \f566;
$fa-var-directions: \f5eb;
$fa-var-discord: \f392;
$fa-var-discourse: \f393;
$fa-var-disease: \f7fa;
$fa-var-divide: \f529;
$fa-var-dizzy: \f567;
$fa-var-dna: \f471;
$fa-var-dochub: \f394;
$fa-var-docker: \f395;
$fa-var-dog: \f6d3;
$fa-var-dollar-sign: \f155;
$fa-var-dolly: \f472;
$fa-var-dolly-flatbed: \f474;
$fa-var-donate: \f4b9;
$fa-var-door-closed: \f52a;
$fa-var-door-open: \f52b;
$fa-var-dot-circle: \f192;
$fa-var-dove: \f4ba;
$fa-var-download: \f019;
$fa-var-draft2digital: \f396;
$fa-var-drafting-compass: \f568;
$fa-var-dragon: \f6d5;
$fa-var-draw-polygon: \f5ee;
$fa-var-dribbble: \f17d;
$fa-var-dribbble-square: \f397;
$fa-var-dropbox: \f16b;
$fa-var-drum: \f569;
$fa-var-drum-steelpan: \f56a;
$fa-var-drumstick-bite: \f6d7;
$fa-var-drupal: \f1a9;
$fa-var-dumbbell: \f44b;
$fa-var-dumpster: \f793;
$fa-var-dumpster-fire: \f794;
$fa-var-dungeon: \f6d9;
$fa-var-dyalog: \f399;
$fa-var-earlybirds: \f39a;
$fa-var-ebay: \f4f4;
$fa-var-edge: \f282;
$fa-var-edge-legacy: \e078;
$fa-var-edit: \f044;
$fa-var-egg: \f7fb;
$fa-var-eject: \f052;
$fa-var-elementor: \f430;
$fa-var-ellipsis-h: \f141;
$fa-var-ellipsis-v: \f142;
$fa-var-ello: \f5f1;
$fa-var-ember: \f423;
$fa-var-empire: \f1d1;
$fa-var-envelope: \f0e0;
$fa-var-envelope-open: \f2b6;
$fa-var-envelope-open-text: \f658;
$fa-var-envelope-square: \f199;
$fa-var-envira: \f299;
$fa-var-equals: \f52c;
$fa-var-eraser: \f12d;
$fa-var-erlang: \f39d;
$fa-var-ethereum: \f42e;
$fa-var-ethernet: \f796;
$fa-var-etsy: \f2d7;
$fa-var-euro-sign: \f153;
$fa-var-evernote: \f839;
$fa-var-exchange-alt: \f362;
$fa-var-exclamation: \f12a;
$fa-var-exclamation-circle: \f06a;
$fa-var-exclamation-triangle: \f071;
$fa-var-expand: \f065;
$fa-var-expand-alt: \f424;
$fa-var-expand-arrows-alt: \f31e;
$fa-var-expeditedssl: \f23e;
$fa-var-external-link-alt: \f35d;
$fa-var-external-link-square-alt: \f360;
$fa-var-eye: \f06e;
$fa-var-eye-dropper: \f1fb;
$fa-var-eye-slash: \f070;
$fa-var-facebook: \f09a;
$fa-var-facebook-f: \f39e;
$fa-var-facebook-messenger: \f39f;
$fa-var-facebook-square: \f082;
$fa-var-fan: \f863;
$fa-var-fantasy-flight-games: \f6dc;
$fa-var-fast-backward: \f049;
$fa-var-fast-forward: \f050;
$fa-var-faucet: \e005;
$fa-var-fax: \f1ac;
$fa-var-feather: \f52d;
$fa-var-feather-alt: \f56b;
$fa-var-fedex: \f797;
$fa-var-fedora: \f798;
$fa-var-female: \f182;
$fa-var-fighter-jet: \f0fb;
$fa-var-figma: \f799;
$fa-var-file: \f15b;
$fa-var-file-alt: \f15c;
$fa-var-file-archive: \f1c6;
$fa-var-file-audio: \f1c7;
$fa-var-file-code: \f1c9;
$fa-var-file-contract: \f56c;
$fa-var-file-csv: \f6dd;
$fa-var-file-download: \f56d;
$fa-var-file-excel: \f1c3;
$fa-var-file-export: \f56e;
$fa-var-file-image: \f1c5;
$fa-var-file-import: \f56f;
$fa-var-file-invoice: \f570;
$fa-var-file-invoice-dollar: \f571;
$fa-var-file-medical: \f477;
$fa-var-file-medical-alt: \f478;
$fa-var-file-pdf: \f1c1;
$fa-var-file-powerpoint: \f1c4;
$fa-var-file-prescription: \f572;
$fa-var-file-signature: \f573;
$fa-var-file-upload: \f574;
$fa-var-file-video: \f1c8;
$fa-var-file-word: \f1c2;
$fa-var-fill: \f575;
$fa-var-fill-drip: \f576;
$fa-var-film: \f008;
$fa-var-filter: \f0b0;
$fa-var-fingerprint: \f577;
$fa-var-fire: \f06d;
$fa-var-fire-alt: \f7e4;
$fa-var-fire-extinguisher: \f134;
$fa-var-firefox: \f269;
$fa-var-firefox-browser: \e007;
$fa-var-first-aid: \f479;
$fa-var-first-order: \f2b0;
$fa-var-first-order-alt: \f50a;
$fa-var-firstdraft: \f3a1;
$fa-var-fish: \f578;
$fa-var-fist-raised: \f6de;
$fa-var-flag: \f024;
$fa-var-flag-checkered: \f11e;
$fa-var-flag-usa: \f74d;
$fa-var-flask: \f0c3;
$fa-var-flickr: \f16e;
$fa-var-flipboard: \f44d;
$fa-var-flushed: \f579;
$fa-var-fly: \f417;
$fa-var-folder: \f07b;
$fa-var-folder-minus: \f65d;
$fa-var-folder-open: \f07c;
$fa-var-folder-plus: \f65e;
$fa-var-font: \f031;
$fa-var-font-awesome: \f2b4;
$fa-var-font-awesome-alt: \f35c;
$fa-var-font-awesome-flag: \f425;
$fa-var-font-awesome-logo-full: \f4e6;
$fa-var-fonticons: \f280;
$fa-var-fonticons-fi: \f3a2;
$fa-var-football-ball: \f44e;
$fa-var-fort-awesome: \f286;
$fa-var-fort-awesome-alt: \f3a3;
$fa-var-forumbee: \f211;
$fa-var-forward: \f04e;
$fa-var-foursquare: \f180;
$fa-var-free-code-camp: \f2c5;
$fa-var-freebsd: \f3a4;
$fa-var-frog: \f52e;
$fa-var-frown: \f119;
$fa-var-frown-open: \f57a;
$fa-var-fulcrum: \f50b;
$fa-var-funnel-dollar: \f662;
$fa-var-futbol: \f1e3;
$fa-var-galactic-republic: \f50c;
$fa-var-galactic-senate: \f50d;
$fa-var-gamepad: \f11b;
$fa-var-gas-pump: \f52f;
$fa-var-gavel: \f0e3;
$fa-var-gem: \f3a5;
$fa-var-genderless: \f22d;
$fa-var-get-pocket: \f265;
$fa-var-gg: \f260;
$fa-var-gg-circle: \f261;
$fa-var-ghost: \f6e2;
$fa-var-gift: \f06b;
$fa-var-gifts: \f79c;
$fa-var-git: \f1d3;
$fa-var-git-alt: \f841;
$fa-var-git-square: \f1d2;
$fa-var-github: \f09b;
$fa-var-github-alt: \f113;
$fa-var-github-square: \f092;
$fa-var-gitkraken: \f3a6;
$fa-var-gitlab: \f296;
$fa-var-gitter: \f426;
$fa-var-glass-cheers: \f79f;
$fa-var-glass-martini: \f000;
$fa-var-glass-martini-alt: \f57b;
$fa-var-glass-whiskey: \f7a0;
$fa-var-glasses: \f530;
$fa-var-glide: \f2a5;
$fa-var-glide-g: \f2a6;
$fa-var-globe: \f0ac;
$fa-var-globe-africa: \f57c;
$fa-var-globe-americas: \f57d;
$fa-var-globe-asia: \f57e;
$fa-var-globe-europe: \f7a2;
$fa-var-gofore: \f3a7;
$fa-var-golf-ball: \f450;
$fa-var-goodreads: \f3a8;
$fa-var-goodreads-g: \f3a9;
$fa-var-google: \f1a0;
$fa-var-google-drive: \f3aa;
$fa-var-google-pay: \e079;
$fa-var-google-play: \f3ab;
$fa-var-google-plus: \f2b3;
$fa-var-google-plus-g: \f0d5;
$fa-var-google-plus-square: \f0d4;
$fa-var-google-wallet: \f1ee;
$fa-var-gopuram: \f664;
$fa-var-graduation-cap: \f19d;
$fa-var-gratipay: \f184;
$fa-var-grav: \f2d6;
$fa-var-greater-than: \f531;
$fa-var-greater-than-equal: \f532;
$fa-var-grimace: \f57f;
$fa-var-grin: \f580;
$fa-var-grin-alt: \f581;
$fa-var-grin-beam: \f582;
$fa-var-grin-beam-sweat: \f583;
$fa-var-grin-hearts: \f584;
$fa-var-grin-squint: \f585;
$fa-var-grin-squint-tears: \f586;
$fa-var-grin-stars: \f587;
$fa-var-grin-tears: \f588;
$fa-var-grin-tongue: \f589;
$fa-var-grin-tongue-squint: \f58a;
$fa-var-grin-tongue-wink: \f58b;
$fa-var-grin-wink: \f58c;
$fa-var-grip-horizontal: \f58d;
$fa-var-grip-lines: \f7a4;
$fa-var-grip-lines-vertical: \f7a5;
$fa-var-grip-vertical: \f58e;
$fa-var-gripfire: \f3ac;
$fa-var-grunt: \f3ad;
$fa-var-guilded: \e07e;
$fa-var-guitar: \f7a6;
$fa-var-gulp: \f3ae;
$fa-var-h-square: \f0fd;
$fa-var-hacker-news: \f1d4;
$fa-var-hacker-news-square: \f3af;
$fa-var-hackerrank: \f5f7;
$fa-var-hamburger: \f805;
$fa-var-hammer: \f6e3;
$fa-var-hamsa: \f665;
$fa-var-hand-holding: \f4bd;
$fa-var-hand-holding-heart: \f4be;
$fa-var-hand-holding-medical: \e05c;
$fa-var-hand-holding-usd: \f4c0;
$fa-var-hand-holding-water: \f4c1;
$fa-var-hand-lizard: \f258;
$fa-var-hand-middle-finger: \f806;
$fa-var-hand-paper: \f256;
$fa-var-hand-peace: \f25b;
$fa-var-hand-point-down: \f0a7;
$fa-var-hand-point-left: \f0a5;
$fa-var-hand-point-right: \f0a4;
$fa-var-hand-point-up: \f0a6;
$fa-var-hand-pointer: \f25a;
$fa-var-hand-rock: \f255;
$fa-var-hand-scissors: \f257;
$fa-var-hand-sparkles: \e05d;
$fa-var-hand-spock: \f259;
$fa-var-hands: \f4c2;
$fa-var-hands-helping: \f4c4;
$fa-var-hands-wash: \e05e;
$fa-var-handshake: \f2b5;
$fa-var-handshake-alt-slash: \e05f;
$fa-var-handshake-slash: \e060;
$fa-var-hanukiah: \f6e6;
$fa-var-hard-hat: \f807;
$fa-var-hashtag: \f292;
$fa-var-hat-cowboy: \f8c0;
$fa-var-hat-cowboy-side: \f8c1;
$fa-var-hat-wizard: \f6e8;
$fa-var-hdd: \f0a0;
$fa-var-head-side-cough: \e061;
$fa-var-head-side-cough-slash: \e062;
$fa-var-head-side-mask: \e063;
$fa-var-head-side-virus: \e064;
$fa-var-heading: \f1dc;
$fa-var-headphones: \f025;
$fa-var-headphones-alt: \f58f;
$fa-var-headset: \f590;
$fa-var-heart: \f004;
$fa-var-heart-broken: \f7a9;
$fa-var-heartbeat: \f21e;
$fa-var-helicopter: \f533;
$fa-var-highlighter: \f591;
$fa-var-hiking: \f6ec;
$fa-var-hippo: \f6ed;
$fa-var-hips: \f452;
$fa-var-hire-a-helper: \f3b0;
$fa-var-history: \f1da;
$fa-var-hive: \e07f;
$fa-var-hockey-puck: \f453;
$fa-var-holly-berry: \f7aa;
$fa-var-home: \f015;
$fa-var-hooli: \f427;
$fa-var-hornbill: \f592;
$fa-var-horse: \f6f0;
$fa-var-horse-head: \f7ab;
$fa-var-hospital: \f0f8;
$fa-var-hospital-alt: \f47d;
$fa-var-hospital-symbol: \f47e;
$fa-var-hospital-user: \f80d;
$fa-var-hot-tub: \f593;
$fa-var-hotdog: \f80f;
$fa-var-hotel: \f594;
$fa-var-hotjar: \f3b1;
$fa-var-hourglass: \f254;
$fa-var-hourglass-end: \f253;
$fa-var-hourglass-half: \f252;
$fa-var-hourglass-start: \f251;
$fa-var-house-damage: \f6f1;
$fa-var-house-user: \e065;
$fa-var-houzz: \f27c;
$fa-var-hryvnia: \f6f2;
$fa-var-html5: \f13b;
$fa-var-hubspot: \f3b2;
$fa-var-i-cursor: \f246;
$fa-var-ice-cream: \f810;
$fa-var-icicles: \f7ad;
$fa-var-icons: \f86d;
$fa-var-id-badge: \f2c1;
$fa-var-id-card: \f2c2;
$fa-var-id-card-alt: \f47f;
$fa-var-ideal: \e013;
$fa-var-igloo: \f7ae;
$fa-var-image: \f03e;
$fa-var-images: \f302;
$fa-var-imdb: \f2d8;
$fa-var-inbox: \f01c;
$fa-var-indent: \f03c;
$fa-var-industry: \f275;
$fa-var-infinity: \f534;
$fa-var-info: \f129;
$fa-var-info-circle: \f05a;
$fa-var-innosoft: \e080;
$fa-var-instagram: \f16d;
$fa-var-instagram-square: \e055;
$fa-var-instalod: \e081;
$fa-var-intercom: \f7af;
$fa-var-internet-explorer: \f26b;
$fa-var-invision: \f7b0;
$fa-var-ioxhost: \f208;
$fa-var-italic: \f033;
$fa-var-itch-io: \f83a;
$fa-var-itunes: \f3b4;
$fa-var-itunes-note: \f3b5;
$fa-var-java: \f4e4;
$fa-var-jedi: \f669;
$fa-var-jedi-order: \f50e;
$fa-var-jenkins: \f3b6;
$fa-var-jira: \f7b1;
$fa-var-joget: \f3b7;
$fa-var-joint: \f595;
$fa-var-joomla: \f1aa;
$fa-var-journal-whills: \f66a;
$fa-var-js: \f3b8;
$fa-var-js-square: \f3b9;
$fa-var-jsfiddle: \f1cc;
$fa-var-kaaba: \f66b;
$fa-var-kaggle: \f5fa;
$fa-var-key: \f084;
$fa-var-keybase: \f4f5;
$fa-var-keyboard: \f11c;
$fa-var-keycdn: \f3ba;
$fa-var-khanda: \f66d;
$fa-var-kickstarter: \f3bb;
$fa-var-kickstarter-k: \f3bc;
$fa-var-kiss: \f596;
$fa-var-kiss-beam: \f597;
$fa-var-kiss-wink-heart: \f598;
$fa-var-kiwi-bird: \f535;
$fa-var-korvue: \f42f;
$fa-var-landmark: \f66f;
$fa-var-language: \f1ab;
$fa-var-laptop: \f109;
$fa-var-laptop-code: \f5fc;
$fa-var-laptop-house: \e066;
$fa-var-laptop-medical: \f812;
$fa-var-laravel: \f3bd;
$fa-var-lastfm: \f202;
$fa-var-lastfm-square: \f203;
$fa-var-laugh: \f599;
$fa-var-laugh-beam: \f59a;
$fa-var-laugh-squint: \f59b;
$fa-var-laugh-wink: \f59c;
$fa-var-layer-group: \f5fd;
$fa-var-leaf: \f06c;
$fa-var-leanpub: \f212;
$fa-var-lemon: \f094;
$fa-var-less: \f41d;
$fa-var-less-than: \f536;
$fa-var-less-than-equal: \f537;
$fa-var-level-down-alt: \f3be;
$fa-var-level-up-alt: \f3bf;
$fa-var-life-ring: \f1cd;
$fa-var-lightbulb: \f0eb;
$fa-var-line: \f3c0;
$fa-var-link: \f0c1;
$fa-var-linkedin: \f08c;
$fa-var-linkedin-in: \f0e1;
$fa-var-linode: \f2b8;
$fa-var-linux: \f17c;
$fa-var-lira-sign: \f195;
$fa-var-list: \f03a;
$fa-var-list-alt: \f022;
$fa-var-list-ol: \f0cb;
$fa-var-list-ul: \f0ca;
$fa-var-location-arrow: \f124;
$fa-var-lock: \f023;
$fa-var-lock-open: \f3c1;
$fa-var-long-arrow-alt-down: \f309;
$fa-var-long-arrow-alt-left: \f30a;
$fa-var-long-arrow-alt-right: \f30b;
$fa-var-long-arrow-alt-up: \f30c;
$fa-var-low-vision: \f2a8;
$fa-var-luggage-cart: \f59d;
$fa-var-lungs: \f604;
$fa-var-lungs-virus: \e067;
$fa-var-lyft: \f3c3;
$fa-var-magento: \f3c4;
$fa-var-magic: \f0d0;
$fa-var-magnet: \f076;
$fa-var-mail-bulk: \f674;
$fa-var-mailchimp: \f59e;
$fa-var-male: \f183;
$fa-var-mandalorian: \f50f;
$fa-var-map: \f279;
$fa-var-map-marked: \f59f;
$fa-var-map-marked-alt: \f5a0;
$fa-var-map-marker: \f041;
$fa-var-map-marker-alt: \f3c5;
$fa-var-map-pin: \f276;
$fa-var-map-signs: \f277;
$fa-var-markdown: \f60f;
$fa-var-marker: \f5a1;
$fa-var-mars: \f222;
$fa-var-mars-double: \f227;
$fa-var-mars-stroke: \f229;
$fa-var-mars-stroke-h: \f22b;
$fa-var-mars-stroke-v: \f22a;
$fa-var-mask: \f6fa;
$fa-var-mastodon: \f4f6;
$fa-var-maxcdn: \f136;
$fa-var-mdb: \f8ca;
$fa-var-medal: \f5a2;
$fa-var-medapps: \f3c6;
$fa-var-medium: \f23a;
$fa-var-medium-m: \f3c7;
$fa-var-medkit: \f0fa;
$fa-var-medrt: \f3c8;
$fa-var-meetup: \f2e0;
$fa-var-megaport: \f5a3;
$fa-var-meh: \f11a;
$fa-var-meh-blank: \f5a4;
$fa-var-meh-rolling-eyes: \f5a5;
$fa-var-memory: \f538;
$fa-var-mendeley: \f7b3;
$fa-var-menorah: \f676;
$fa-var-mercury: \f223;
$fa-var-meteor: \f753;
$fa-var-microblog: \e01a;
$fa-var-microchip: \f2db;
$fa-var-microphone: \f130;
$fa-var-microphone-alt: \f3c9;
$fa-var-microphone-alt-slash: \f539;
$fa-var-microphone-slash: \f131;
$fa-var-microscope: \f610;
$fa-var-microsoft: \f3ca;
$fa-var-minus: \f068;
$fa-var-minus-circle: \f056;
$fa-var-minus-square: \f146;
$fa-var-mitten: \f7b5;
$fa-var-mix: \f3cb;
$fa-var-mixcloud: \f289;
$fa-var-mixer: \e056;
$fa-var-mizuni: \f3cc;
$fa-var-mobile: \f10b;
$fa-var-mobile-alt: \f3cd;
$fa-var-modx: \f285;
$fa-var-monero: \f3d0;
$fa-var-money-bill: \f0d6;
$fa-var-money-bill-alt: \f3d1;
$fa-var-money-bill-wave: \f53a;
$fa-var-money-bill-wave-alt: \f53b;
$fa-var-money-check: \f53c;
$fa-var-money-check-alt: \f53d;
$fa-var-monument: \f5a6;
$fa-var-moon: \f186;
$fa-var-mortar-pestle: \f5a7;
$fa-var-mosque: \f678;
$fa-var-motorcycle: \f21c;
$fa-var-mountain: \f6fc;
$fa-var-mouse: \f8cc;
$fa-var-mouse-pointer: \f245;
$fa-var-mug-hot: \f7b6;
$fa-var-music: \f001;
$fa-var-napster: \f3d2;
$fa-var-neos: \f612;
$fa-var-network-wired: \f6ff;
$fa-var-neuter: \f22c;
$fa-var-newspaper: \f1ea;
$fa-var-nimblr: \f5a8;
$fa-var-node: \f419;
$fa-var-node-js: \f3d3;
$fa-var-not-equal: \f53e;
$fa-var-notes-medical: \f481;
$fa-var-npm: \f3d4;
$fa-var-ns8: \f3d5;
$fa-var-nutritionix: \f3d6;
$fa-var-object-group: \f247;
$fa-var-object-ungroup: \f248;
$fa-var-octopus-deploy: \e082;
$fa-var-odnoklassniki: \f263;
$fa-var-odnoklassniki-square: \f264;
$fa-var-oil-can: \f613;
$fa-var-old-republic: \f510;
$fa-var-om: \f679;
$fa-var-opencart: \f23d;
$fa-var-openid: \f19b;
$fa-var-opera: \f26a;
$fa-var-optin-monster: \f23c;
$fa-var-orcid: \f8d2;
$fa-var-osi: \f41a;
$fa-var-otter: \f700;
$fa-var-outdent: \f03b;
$fa-var-page4: \f3d7;
$fa-var-pagelines: \f18c;
$fa-var-pager: \f815;
$fa-var-paint-brush: \f1fc;
$fa-var-paint-roller: \f5aa;
$fa-var-palette: \f53f;
$fa-var-palfed: \f3d8;
$fa-var-pallet: \f482;
$fa-var-paper-plane: \f1d8;
$fa-var-paperclip: \f0c6;
$fa-var-parachute-box: \f4cd;
$fa-var-paragraph: \f1dd;
$fa-var-parking: \f540;
$fa-var-passport: \f5ab;
$fa-var-pastafarianism: \f67b;
$fa-var-paste: \f0ea;
$fa-var-patreon: \f3d9;
$fa-var-pause: \f04c;
$fa-var-pause-circle: \f28b;
$fa-var-paw: \f1b0;
$fa-var-paypal: \f1ed;
$fa-var-peace: \f67c;
$fa-var-pen: \f304;
$fa-var-pen-alt: \f305;
$fa-var-pen-fancy: \f5ac;
$fa-var-pen-nib: \f5ad;
$fa-var-pen-square: \f14b;
$fa-var-pencil-alt: \f303;
$fa-var-pencil-ruler: \f5ae;
$fa-var-penny-arcade: \f704;
$fa-var-people-arrows: \e068;
$fa-var-people-carry: \f4ce;
$fa-var-pepper-hot: \f816;
$fa-var-perbyte: \e083;
$fa-var-percent: \f295;
$fa-var-percentage: \f541;
$fa-var-periscope: \f3da;
$fa-var-person-booth: \f756;
$fa-var-phabricator: \f3db;
$fa-var-phoenix-framework: \f3dc;
$fa-var-phoenix-squadron: \f511;
$fa-var-phone: \f095;
$fa-var-phone-alt: \f879;
$fa-var-phone-slash: \f3dd;
$fa-var-phone-square: \f098;
$fa-var-phone-square-alt: \f87b;
$fa-var-phone-volume: \f2a0;
$fa-var-photo-video: \f87c;
$fa-var-php: \f457;
$fa-var-pied-piper: \f2ae;
$fa-var-pied-piper-alt: \f1a8;
$fa-var-pied-piper-hat: \f4e5;
$fa-var-pied-piper-pp: \f1a7;
$fa-var-pied-piper-square: \e01e;
$fa-var-piggy-bank: \f4d3;
$fa-var-pills: \f484;
$fa-var-pinterest: \f0d2;
$fa-var-pinterest-p: \f231;
$fa-var-pinterest-square: \f0d3;
$fa-var-pizza-slice: \f818;
$fa-var-place-of-worship: \f67f;
$fa-var-plane: \f072;
$fa-var-plane-arrival: \f5af;
$fa-var-plane-departure: \f5b0;
$fa-var-plane-slash: \e069;
$fa-var-play: \f04b;
$fa-var-play-circle: \f144;
$fa-var-playstation: \f3df;
$fa-var-plug: \f1e6;
$fa-var-plus: \f067;
$fa-var-plus-circle: \f055;
$fa-var-plus-square: \f0fe;
$fa-var-podcast: \f2ce;
$fa-var-poll: \f681;
$fa-var-poll-h: \f682;
$fa-var-poo: \f2fe;
$fa-var-poo-storm: \f75a;
$fa-var-poop: \f619;
$fa-var-portrait: \f3e0;
$fa-var-pound-sign: \f154;
$fa-var-power-off: \f011;
$fa-var-pray: \f683;
$fa-var-praying-hands: \f684;
$fa-var-prescription: \f5b1;
$fa-var-prescription-bottle: \f485;
$fa-var-prescription-bottle-alt: \f486;
$fa-var-print: \f02f;
$fa-var-procedures: \f487;
$fa-var-product-hunt: \f288;
$fa-var-project-diagram: \f542;
$fa-var-pump-medical: \e06a;
$fa-var-pump-soap: \e06b;
$fa-var-pushed: \f3e1;
$fa-var-puzzle-piece: \f12e;
$fa-var-python: \f3e2;
$fa-var-qq: \f1d6;
$fa-var-qrcode: \f029;
$fa-var-question: \f128;
$fa-var-question-circle: \f059;
$fa-var-quidditch: \f458;
$fa-var-quinscape: \f459;
$fa-var-quora: \f2c4;
$fa-var-quote-left: \f10d;
$fa-var-quote-right: \f10e;
$fa-var-quran: \f687;
$fa-var-r-project: \f4f7;
$fa-var-radiation: \f7b9;
$fa-var-radiation-alt: \f7ba;
$fa-var-rainbow: \f75b;
$fa-var-random: \f074;
$fa-var-raspberry-pi: \f7bb;
$fa-var-ravelry: \f2d9;
$fa-var-react: \f41b;
$fa-var-reacteurope: \f75d;
$fa-var-readme: \f4d5;
$fa-var-rebel: \f1d0;
$fa-var-receipt: \f543;
$fa-var-record-vinyl: \f8d9;
$fa-var-recycle: \f1b8;
$fa-var-red-river: \f3e3;
$fa-var-reddit: \f1a1;
$fa-var-reddit-alien: \f281;
$fa-var-reddit-square: \f1a2;
$fa-var-redhat: \f7bc;
$fa-var-redo: \f01e;
$fa-var-redo-alt: \f2f9;
$fa-var-registered: \f25d;
$fa-var-remove-format: \f87d;
$fa-var-renren: \f18b;
$fa-var-reply: \f3e5;
$fa-var-reply-all: \f122;
$fa-var-replyd: \f3e6;
$fa-var-republican: \f75e;
$fa-var-researchgate: \f4f8;
$fa-var-resolving: \f3e7;
$fa-var-restroom: \f7bd;
$fa-var-retweet: \f079;
$fa-var-rev: \f5b2;
$fa-var-ribbon: \f4d6;
$fa-var-ring: \f70b;
$fa-var-road: \f018;
$fa-var-robot: \f544;
$fa-var-rocket: \f135;
$fa-var-rocketchat: \f3e8;
$fa-var-rockrms: \f3e9;
$fa-var-route: \f4d7;
$fa-var-rss: \f09e;
$fa-var-rss-square: \f143;
$fa-var-ruble-sign: \f158;
$fa-var-ruler: \f545;
$fa-var-ruler-combined: \f546;
$fa-var-ruler-horizontal: \f547;
$fa-var-ruler-vertical: \f548;
$fa-var-running: \f70c;
$fa-var-rupee-sign: \f156;
$fa-var-rust: \e07a;
$fa-var-sad-cry: \f5b3;
$fa-var-sad-tear: \f5b4;
$fa-var-safari: \f267;
$fa-var-salesforce: \f83b;
$fa-var-sass: \f41e;
$fa-var-satellite: \f7bf;
$fa-var-satellite-dish: \f7c0;
$fa-var-save: \f0c7;
$fa-var-schlix: \f3ea;
$fa-var-school: \f549;
$fa-var-screwdriver: \f54a;
$fa-var-scribd: \f28a;
$fa-var-scroll: \f70e;
$fa-var-sd-card: \f7c2;
$fa-var-search: \f002;
$fa-var-search-dollar: \f688;
$fa-var-search-location: \f689;
$fa-var-search-minus: \f010;
$fa-var-search-plus: \f00e;
$fa-var-searchengin: \f3eb;
$fa-var-seedling: \f4d8;
$fa-var-sellcast: \f2da;
$fa-var-sellsy: \f213;
$fa-var-server: \f233;
$fa-var-servicestack: \f3ec;
$fa-var-shapes: \f61f;
$fa-var-share: \f064;
$fa-var-share-alt: \f1e0;
$fa-var-share-alt-square: \f1e1;
$fa-var-share-square: \f14d;
$fa-var-shekel-sign: \f20b;
$fa-var-shield-alt: \f3ed;
$fa-var-shield-virus: \e06c;
$fa-var-ship: \f21a;
$fa-var-shipping-fast: \f48b;
$fa-var-shirtsinbulk: \f214;
$fa-var-shoe-prints: \f54b;
$fa-var-shopify: \e057;
$fa-var-shopping-bag: \f290;
$fa-var-shopping-basket: \f291;
$fa-var-shopping-cart: \f07a;
$fa-var-shopware: \f5b5;
$fa-var-shower: \f2cc;
$fa-var-shuttle-van: \f5b6;
$fa-var-sign: \f4d9;
$fa-var-sign-in-alt: \f2f6;
$fa-var-sign-language: \f2a7;
$fa-var-sign-out-alt: \f2f5;
$fa-var-signal: \f012;
$fa-var-signature: \f5b7;
$fa-var-sim-card: \f7c4;
$fa-var-simplybuilt: \f215;
$fa-var-sink: \e06d;
$fa-var-sistrix: \f3ee;
$fa-var-sitemap: \f0e8;
$fa-var-sith: \f512;
$fa-var-skating: \f7c5;
$fa-var-sketch: \f7c6;
$fa-var-skiing: \f7c9;
$fa-var-skiing-nordic: \f7ca;
$fa-var-skull: \f54c;
$fa-var-skull-crossbones: \f714;
$fa-var-skyatlas: \f216;
$fa-var-skype: \f17e;
$fa-var-slack: \f198;
$fa-var-slack-hash: \f3ef;
$fa-var-slash: \f715;
$fa-var-sleigh: \f7cc;
$fa-var-sliders-h: \f1de;
$fa-var-slideshare: \f1e7;
$fa-var-smile: \f118;
$fa-var-smile-beam: \f5b8;
$fa-var-smile-wink: \f4da;
$fa-var-smog: \f75f;
$fa-var-smoking: \f48d;
$fa-var-smoking-ban: \f54d;
$fa-var-sms: \f7cd;
$fa-var-snapchat: \f2ab;
$fa-var-snapchat-ghost: \f2ac;
$fa-var-snapchat-square: \f2ad;
$fa-var-snowboarding: \f7ce;
$fa-var-snowflake: \f2dc;
$fa-var-snowman: \f7d0;
$fa-var-snowplow: \f7d2;
$fa-var-soap: \e06e;
$fa-var-socks: \f696;
$fa-var-solar-panel: \f5ba;
$fa-var-sort: \f0dc;
$fa-var-sort-alpha-down: \f15d;
$fa-var-sort-alpha-down-alt: \f881;
$fa-var-sort-alpha-up: \f15e;
$fa-var-sort-alpha-up-alt: \f882;
$fa-var-sort-amount-down: \f160;
$fa-var-sort-amount-down-alt: \f884;
$fa-var-sort-amount-up: \f161;
$fa-var-sort-amount-up-alt: \f885;
$fa-var-sort-down: \f0dd;
$fa-var-sort-numeric-down: \f162;
$fa-var-sort-numeric-down-alt: \f886;
$fa-var-sort-numeric-up: \f163;
$fa-var-sort-numeric-up-alt: \f887;
$fa-var-sort-up: \f0de;
$fa-var-soundcloud: \f1be;
$fa-var-sourcetree: \f7d3;
$fa-var-spa: \f5bb;
$fa-var-space-shuttle: \f197;
$fa-var-speakap: \f3f3;
$fa-var-speaker-deck: \f83c;
$fa-var-spell-check: \f891;
$fa-var-spider: \f717;
$fa-var-spinner: \f110;
$fa-var-splotch: \f5bc;
$fa-var-spotify: \f1bc;
$fa-var-spray-can: \f5bd;
$fa-var-square: \f0c8;
$fa-var-square-full: \f45c;
$fa-var-square-root-alt: \f698;
$fa-var-squarespace: \f5be;
$fa-var-stack-exchange: \f18d;
$fa-var-stack-overflow: \f16c;
$fa-var-stackpath: \f842;
$fa-var-stamp: \f5bf;
$fa-var-star: \f005;
$fa-var-star-and-crescent: \f699;
$fa-var-star-half: \f089;
$fa-var-star-half-alt: \f5c0;
$fa-var-star-of-david: \f69a;
$fa-var-star-of-life: \f621;
$fa-var-staylinked: \f3f5;
$fa-var-steam: \f1b6;
$fa-var-steam-square: \f1b7;
$fa-var-steam-symbol: \f3f6;
$fa-var-step-backward: \f048;
$fa-var-step-forward: \f051;
$fa-var-stethoscope: \f0f1;
$fa-var-sticker-mule: \f3f7;
$fa-var-sticky-note: \f249;
$fa-var-stop: \f04d;
$fa-var-stop-circle: \f28d;
$fa-var-stopwatch: \f2f2;
$fa-var-stopwatch-20: \e06f;
$fa-var-store: \f54e;
$fa-var-store-alt: \f54f;
$fa-var-store-alt-slash: \e070;
$fa-var-store-slash: \e071;
$fa-var-strava: \f428;
$fa-var-stream: \f550;
$fa-var-street-view: \f21d;
$fa-var-strikethrough: \f0cc;
$fa-var-stripe: \f429;
$fa-var-stripe-s: \f42a;
$fa-var-stroopwafel: \f551;
$fa-var-studiovinari: \f3f8;
$fa-var-stumbleupon: \f1a4;
$fa-var-stumbleupon-circle: \f1a3;
$fa-var-subscript: \f12c;
$fa-var-subway: \f239;
$fa-var-suitcase: \f0f2;
$fa-var-suitcase-rolling: \f5c1;
$fa-var-sun: \f185;
$fa-var-superpowers: \f2dd;
$fa-var-superscript: \f12b;
$fa-var-supple: \f3f9;
$fa-var-surprise: \f5c2;
$fa-var-suse: \f7d6;
$fa-var-swatchbook: \f5c3;
$fa-var-swift: \f8e1;
$fa-var-swimmer: \f5c4;
$fa-var-swimming-pool: \f5c5;
$fa-var-symfony: \f83d;
$fa-var-synagogue: \f69b;
$fa-var-sync: \f021;
$fa-var-sync-alt: \f2f1;
$fa-var-syringe: \f48e;
$fa-var-table: \f0ce;
$fa-var-table-tennis: \f45d;
$fa-var-tablet: \f10a;
$fa-var-tablet-alt: \f3fa;
$fa-var-tablets: \f490;
$fa-var-tachometer-alt: \f3fd;
$fa-var-tag: \f02b;
$fa-var-tags: \f02c;
$fa-var-tape: \f4db;
$fa-var-tasks: \f0ae;
$fa-var-taxi: \f1ba;
$fa-var-teamspeak: \f4f9;
$fa-var-teeth: \f62e;
$fa-var-teeth-open: \f62f;
$fa-var-telegram: \f2c6;
$fa-var-telegram-plane: \f3fe;
$fa-var-temperature-high: \f769;
$fa-var-temperature-low: \f76b;
$fa-var-tencent-weibo: \f1d5;
$fa-var-tenge: \f7d7;
$fa-var-terminal: \f120;
$fa-var-text-height: \f034;
$fa-var-text-width: \f035;
$fa-var-th: \f00a;
$fa-var-th-large: \f009;
$fa-var-th-list: \f00b;
$fa-var-the-red-yeti: \f69d;
$fa-var-theater-masks: \f630;
$fa-var-themeco: \f5c6;
$fa-var-themeisle: \f2b2;
$fa-var-thermometer: \f491;
$fa-var-thermometer-empty: \f2cb;
$fa-var-thermometer-full: \f2c7;
$fa-var-thermometer-half: \f2c9;
$fa-var-thermometer-quarter: \f2ca;
$fa-var-thermometer-three-quarters: \f2c8;
$fa-var-think-peaks: \f731;
$fa-var-thumbs-down: \f165;
$fa-var-thumbs-up: \f164;
$fa-var-thumbtack: \f08d;
$fa-var-ticket-alt: \f3ff;
$fa-var-tiktok: \e07b;
$fa-var-times: \f00d;
$fa-var-times-circle: \f057;
$fa-var-tint: \f043;
$fa-var-tint-slash: \f5c7;
$fa-var-tired: \f5c8;
$fa-var-toggle-off: \f204;
$fa-var-toggle-on: \f205;
$fa-var-toilet: \f7d8;
$fa-var-toilet-paper: \f71e;
$fa-var-toilet-paper-slash: \e072;
$fa-var-toolbox: \f552;
$fa-var-tools: \f7d9;
$fa-var-tooth: \f5c9;
$fa-var-torah: \f6a0;
$fa-var-torii-gate: \f6a1;
$fa-var-tractor: \f722;
$fa-var-trade-federation: \f513;
$fa-var-trademark: \f25c;
$fa-var-traffic-light: \f637;
$fa-var-trailer: \e041;
$fa-var-train: \f238;
$fa-var-tram: \f7da;
$fa-var-transgender: \f224;
$fa-var-transgender-alt: \f225;
$fa-var-trash: \f1f8;
$fa-var-trash-alt: \f2ed;
$fa-var-trash-restore: \f829;
$fa-var-trash-restore-alt: \f82a;
$fa-var-tree: \f1bb;
$fa-var-trello: \f181;
$fa-var-tripadvisor: \f262;
$fa-var-trophy: \f091;
$fa-var-truck: \f0d1;
$fa-var-truck-loading: \f4de;
$fa-var-truck-monster: \f63b;
$fa-var-truck-moving: \f4df;
$fa-var-truck-pickup: \f63c;
$fa-var-tshirt: \f553;
$fa-var-tty: \f1e4;
$fa-var-tumblr: \f173;
$fa-var-tumblr-square: \f174;
$fa-var-tv: \f26c;
$fa-var-twitch: \f1e8;
$fa-var-twitter: \f099;
$fa-var-twitter-square: \f081;
$fa-var-typo3: \f42b;
$fa-var-uber: \f402;
$fa-var-ubuntu: \f7df;
$fa-var-uikit: \f403;
$fa-var-umbraco: \f8e8;
$fa-var-umbrella: \f0e9;
$fa-var-umbrella-beach: \f5ca;
$fa-var-uncharted: \e084;
$fa-var-underline: \f0cd;
$fa-var-undo: \f0e2;
$fa-var-undo-alt: \f2ea;
$fa-var-uniregistry: \f404;
$fa-var-unity: \e049;
$fa-var-universal-access: \f29a;
$fa-var-university: \f19c;
$fa-var-unlink: \f127;
$fa-var-unlock: \f09c;
$fa-var-unlock-alt: \f13e;
$fa-var-unsplash: \e07c;
$fa-var-untappd: \f405;
$fa-var-upload: \f093;
$fa-var-ups: \f7e0;
$fa-var-usb: \f287;
$fa-var-user: \f007;
$fa-var-user-alt: \f406;
$fa-var-user-alt-slash: \f4fa;
$fa-var-user-astronaut: \f4fb;
$fa-var-user-check: \f4fc;
$fa-var-user-circle: \f2bd;
$fa-var-user-clock: \f4fd;
$fa-var-user-cog: \f4fe;
$fa-var-user-edit: \f4ff;
$fa-var-user-friends: \f500;
$fa-var-user-graduate: \f501;
$fa-var-user-injured: \f728;
$fa-var-user-lock: \f502;
$fa-var-user-md: \f0f0;
$fa-var-user-minus: \f503;
$fa-var-user-ninja: \f504;
$fa-var-user-nurse: \f82f;
$fa-var-user-plus: \f234;
$fa-var-user-secret: \f21b;
$fa-var-user-shield: \f505;
$fa-var-user-slash: \f506;
$fa-var-user-tag: \f507;
$fa-var-user-tie: \f508;
$fa-var-user-times: \f235;
$fa-var-users: \f0c0;
$fa-var-users-cog: \f509;
$fa-var-users-slash: \e073;
$fa-var-usps: \f7e1;
$fa-var-ussunnah: \f407;
$fa-var-utensil-spoon: \f2e5;
$fa-var-utensils: \f2e7;
$fa-var-vaadin: \f408;
$fa-var-vector-square: \f5cb;
$fa-var-venus: \f221;
$fa-var-venus-double: \f226;
$fa-var-venus-mars: \f228;
$fa-var-vest: \e085;
$fa-var-vest-patches: \e086;
$fa-var-viacoin: \f237;
$fa-var-viadeo: \f2a9;
$fa-var-viadeo-square: \f2aa;
$fa-var-vial: \f492;
$fa-var-vials: \f493;
$fa-var-viber: \f409;
$fa-var-video: \f03d;
$fa-var-video-slash: \f4e2;
$fa-var-vihara: \f6a7;
$fa-var-vimeo: \f40a;
$fa-var-vimeo-square: \f194;
$fa-var-vimeo-v: \f27d;
$fa-var-vine: \f1ca;
$fa-var-virus: \e074;
$fa-var-virus-slash: \e075;
$fa-var-viruses: \e076;
$fa-var-vk: \f189;
$fa-var-vnv: \f40b;
$fa-var-voicemail: \f897;
$fa-var-volleyball-ball: \f45f;
$fa-var-volume-down: \f027;
$fa-var-volume-mute: \f6a9;
$fa-var-volume-off: \f026;
$fa-var-volume-up: \f028;
$fa-var-vote-yea: \f772;
$fa-var-vr-cardboard: \f729;
$fa-var-vuejs: \f41f;
$fa-var-walking: \f554;
$fa-var-wallet: \f555;
$fa-var-warehouse: \f494;
$fa-var-watchman-monitoring: \e087;
$fa-var-water: \f773;
$fa-var-wave-square: \f83e;
$fa-var-waze: \f83f;
$fa-var-weebly: \f5cc;
$fa-var-weibo: \f18a;
$fa-var-weight: \f496;
$fa-var-weight-hanging: \f5cd;
$fa-var-weixin: \f1d7;
$fa-var-whatsapp: \f232;
$fa-var-whatsapp-square: \f40c;
$fa-var-wheelchair: \f193;
$fa-var-whmcs: \f40d;
$fa-var-wifi: \f1eb;
$fa-var-wikipedia-w: \f266;
$fa-var-wind: \f72e;
$fa-var-window-close: \f410;
$fa-var-window-maximize: \f2d0;
$fa-var-window-minimize: \f2d1;
$fa-var-window-restore: \f2d2;
$fa-var-windows: \f17a;
$fa-var-wine-bottle: \f72f;
$fa-var-wine-glass: \f4e3;
$fa-var-wine-glass-alt: \f5ce;
$fa-var-wix: \f5cf;
$fa-var-wizards-of-the-coast: \f730;
$fa-var-wodu: \e088;
$fa-var-wolf-pack-battalion: \f514;
$fa-var-won-sign: \f159;
$fa-var-wordpress: \f19a;
$fa-var-wordpress-simple: \f411;
$fa-var-wpbeginner: \f297;
$fa-var-wpexplorer: \f2de;
$fa-var-wpforms: \f298;
$fa-var-wpressr: \f3e4;
$fa-var-wrench: \f0ad;
$fa-var-x-ray: \f497;
$fa-var-xbox: \f412;
$fa-var-xing: \f168;
$fa-var-xing-square: \f169;
$fa-var-y-combinator: \f23b;
$fa-var-yahoo: \f19e;
$fa-var-yammer: \f840;
$fa-var-yandex: \f413;
$fa-var-yandex-international: \f414;
$fa-var-yarn: \f7e3;
$fa-var-yelp: \f1e9;
$fa-var-yen-sign: \f157;
$fa-var-yin-yang: \f6ad;
$fa-var-yoast: \f2b1;
$fa-var-youtube: \f167;
$fa-var-youtube-square: \f431;
$fa-var-zhihu: \f63f;

.fa-500px:before { content: fa-content($fa-var-500px); }
.fa-accessible-icon:before { content: fa-content($fa-var-accessible-icon); }
.fa-accusoft:before { content: fa-content($fa-var-accusoft); }
.fa-acquisitions-incorporated:before { content: fa-content($fa-var-acquisitions-incorporated); }
.fa-ad:before { content: fa-content($fa-var-ad); }
.fa-address-book:before { content: fa-content($fa-var-address-book); }
.fa-address-card:before { content: fa-content($fa-var-address-card); }
.fa-adjust:before { content: fa-content($fa-var-adjust); }
.fa-adn:before { content: fa-content($fa-var-adn); }
.fa-adversal:before { content: fa-content($fa-var-adversal); }
.fa-affiliatetheme:before { content: fa-content($fa-var-affiliatetheme); }
.fa-air-freshener:before { content: fa-content($fa-var-air-freshener); }
.fa-airbnb:before { content: fa-content($fa-var-airbnb); }
.fa-algolia:before { content: fa-content($fa-var-algolia); }
.fa-align-center:before { content: fa-content($fa-var-align-center); }
.fa-align-justify:before { content: fa-content($fa-var-align-justify); }
.fa-align-left:before { content: fa-content($fa-var-align-left); }
.fa-align-right:before { content: fa-content($fa-var-align-right); }
.fa-alipay:before { content: fa-content($fa-var-alipay); }
.fa-allergies:before { content: fa-content($fa-var-allergies); }
.fa-amazon:before { content: fa-content($fa-var-amazon); }
.fa-amazon-pay:before { content: fa-content($fa-var-amazon-pay); }
.fa-ambulance:before { content: fa-content($fa-var-ambulance); }
.fa-american-sign-language-interpreting:before { content: fa-content($fa-var-american-sign-language-interpreting); }
.fa-amilia:before { content: fa-content($fa-var-amilia); }
.fa-anchor:before { content: fa-content($fa-var-anchor); }
.fa-android:before { content: fa-content($fa-var-android); }
.fa-angellist:before { content: fa-content($fa-var-angellist); }
.fa-angle-double-down:before { content: fa-content($fa-var-angle-double-down); }
.fa-angle-double-left:before { content: fa-content($fa-var-angle-double-left); }
.fa-angle-double-right:before { content: fa-content($fa-var-angle-double-right); }
.fa-angle-double-up:before { content: fa-content($fa-var-angle-double-up); }
.fa-angle-down:before { content: fa-content($fa-var-angle-down); }
.fa-angle-left:before { content: fa-content($fa-var-angle-left); }
.fa-angle-right:before { content: fa-content($fa-var-angle-right); }
.fa-angle-up:before { content: fa-content($fa-var-angle-up); }
.fa-angry:before { content: fa-content($fa-var-angry); }
.fa-angrycreative:before { content: fa-content($fa-var-angrycreative); }
.fa-angular:before { content: fa-content($fa-var-angular); }
.fa-ankh:before { content: fa-content($fa-var-ankh); }
.fa-app-store:before { content: fa-content($fa-var-app-store); }
.fa-app-store-ios:before { content: fa-content($fa-var-app-store-ios); }
.fa-apper:before { content: fa-content($fa-var-apper); }
.fa-apple:before { content: fa-content($fa-var-apple); }
.fa-apple-alt:before { content: fa-content($fa-var-apple-alt); }
.fa-apple-pay:before { content: fa-content($fa-var-apple-pay); }
.fa-archive:before { content: fa-content($fa-var-archive); }
.fa-archway:before { content: fa-content($fa-var-archway); }
.fa-arrow-alt-circle-down:before { content: fa-content($fa-var-arrow-alt-circle-down); }
.fa-arrow-alt-circle-left:before { content: fa-content($fa-var-arrow-alt-circle-left); }
.fa-arrow-alt-circle-right:before { content: fa-content($fa-var-arrow-alt-circle-right); }
.fa-arrow-alt-circle-up:before { content: fa-content($fa-var-arrow-alt-circle-up); }
.fa-arrow-circle-down:before { content: fa-content($fa-var-arrow-circle-down); }
.fa-arrow-circle-left:before { content: fa-content($fa-var-arrow-circle-left); }
.fa-arrow-circle-right:before { content: fa-content($fa-var-arrow-circle-right); }
.fa-arrow-circle-up:before { content: fa-content($fa-var-arrow-circle-up); }
.fa-arrow-down:before { content: fa-content($fa-var-arrow-down); }
.fa-arrow-left:before { content: fa-content($fa-var-arrow-left); }
.fa-arrow-right:before { content: fa-content($fa-var-arrow-right); }
.fa-arrow-up:before { content: fa-content($fa-var-arrow-up); }
.fa-arrows-alt:before { content: fa-content($fa-var-arrows-alt); }
.fa-arrows-alt-h:before { content: fa-content($fa-var-arrows-alt-h); }
.fa-arrows-alt-v:before { content: fa-content($fa-var-arrows-alt-v); }
.fa-artstation:before { content: fa-content($fa-var-artstation); }
.fa-assistive-listening-systems:before { content: fa-content($fa-var-assistive-listening-systems); }
.fa-asterisk:before { content: fa-content($fa-var-asterisk); }
.fa-asymmetrik:before { content: fa-content($fa-var-asymmetrik); }
.fa-at:before { content: fa-content($fa-var-at); }
.fa-atlas:before { content: fa-content($fa-var-atlas); }
.fa-atlassian:before { content: fa-content($fa-var-atlassian); }
.fa-atom:before { content: fa-content($fa-var-atom); }
.fa-audible:before { content: fa-content($fa-var-audible); }
.fa-audio-description:before { content: fa-content($fa-var-audio-description); }
.fa-autoprefixer:before { content: fa-content($fa-var-autoprefixer); }
.fa-avianex:before { content: fa-content($fa-var-avianex); }
.fa-aviato:before { content: fa-content($fa-var-aviato); }
.fa-award:before { content: fa-content($fa-var-award); }
.fa-aws:before { content: fa-content($fa-var-aws); }
.fa-baby:before { content: fa-content($fa-var-baby); }
.fa-baby-carriage:before { content: fa-content($fa-var-baby-carriage); }
.fa-backspace:before { content: fa-content($fa-var-backspace); }
.fa-backward:before { content: fa-content($fa-var-backward); }
.fa-bacon:before { content: fa-content($fa-var-bacon); }
.fa-bacteria:before { content: fa-content($fa-var-bacteria); }
.fa-bacterium:before { content: fa-content($fa-var-bacterium); }
.fa-bahai:before { content: fa-content($fa-var-bahai); }
.fa-balance-scale:before { content: fa-content($fa-var-balance-scale); }
.fa-balance-scale-left:before { content: fa-content($fa-var-balance-scale-left); }
.fa-balance-scale-right:before { content: fa-content($fa-var-balance-scale-right); }
.fa-ban:before { content: fa-content($fa-var-ban); }
.fa-band-aid:before { content: fa-content($fa-var-band-aid); }
.fa-bandcamp:before { content: fa-content($fa-var-bandcamp); }
.fa-barcode:before { content: fa-content($fa-var-barcode); }
.fa-bars:before { content: fa-content($fa-var-bars); }
.fa-baseball-ball:before { content: fa-content($fa-var-baseball-ball); }
.fa-basketball-ball:before { content: fa-content($fa-var-basketball-ball); }
.fa-bath:before { content: fa-content($fa-var-bath); }
.fa-battery-empty:before { content: fa-content($fa-var-battery-empty); }
.fa-battery-full:before { content: fa-content($fa-var-battery-full); }
.fa-battery-half:before { content: fa-content($fa-var-battery-half); }
.fa-battery-quarter:before { content: fa-content($fa-var-battery-quarter); }
.fa-battery-three-quarters:before { content: fa-content($fa-var-battery-three-quarters); }
.fa-battle-net:before { content: fa-content($fa-var-battle-net); }
.fa-bed:before { content: fa-content($fa-var-bed); }
.fa-beer:before { content: fa-content($fa-var-beer); }
.fa-behance:before { content: fa-content($fa-var-behance); }
.fa-behance-square:before { content: fa-content($fa-var-behance-square); }
.fa-bell:before { content: fa-content($fa-var-bell); }
.fa-bell-slash:before { content: fa-content($fa-var-bell-slash); }
.fa-bezier-curve:before { content: fa-content($fa-var-bezier-curve); }
.fa-bible:before { content: fa-content($fa-var-bible); }
.fa-bicycle:before { content: fa-content($fa-var-bicycle); }
.fa-biking:before { content: fa-content($fa-var-biking); }
.fa-bimobject:before { content: fa-content($fa-var-bimobject); }
.fa-binoculars:before { content: fa-content($fa-var-binoculars); }
.fa-biohazard:before { content: fa-content($fa-var-biohazard); }
.fa-birthday-cake:before { content: fa-content($fa-var-birthday-cake); }
.fa-bitbucket:before { content: fa-content($fa-var-bitbucket); }
.fa-bitcoin:before { content: fa-content($fa-var-bitcoin); }
.fa-bity:before { content: fa-content($fa-var-bity); }
.fa-black-tie:before { content: fa-content($fa-var-black-tie); }
.fa-blackberry:before { content: fa-content($fa-var-blackberry); }
.fa-blender:before { content: fa-content($fa-var-blender); }
.fa-blender-phone:before { content: fa-content($fa-var-blender-phone); }
.fa-blind:before { content: fa-content($fa-var-blind); }
.fa-blog:before { content: fa-content($fa-var-blog); }
.fa-blogger:before { content: fa-content($fa-var-blogger); }
.fa-blogger-b:before { content: fa-content($fa-var-blogger-b); }
.fa-bluetooth:before { content: fa-content($fa-var-bluetooth); }
.fa-bluetooth-b:before { content: fa-content($fa-var-bluetooth-b); }
.fa-bold:before { content: fa-content($fa-var-bold); }
.fa-bolt:before { content: fa-content($fa-var-bolt); }
.fa-bomb:before { content: fa-content($fa-var-bomb); }
.fa-bone:before { content: fa-content($fa-var-bone); }
.fa-bong:before { content: fa-content($fa-var-bong); }
.fa-book:before { content: fa-content($fa-var-book); }
.fa-book-dead:before { content: fa-content($fa-var-book-dead); }
.fa-book-medical:before { content: fa-content($fa-var-book-medical); }
.fa-book-open:before { content: fa-content($fa-var-book-open); }
.fa-book-reader:before { content: fa-content($fa-var-book-reader); }
.fa-bookmark:before { content: fa-content($fa-var-bookmark); }
.fa-bootstrap:before { content: fa-content($fa-var-bootstrap); }
.fa-border-all:before { content: fa-content($fa-var-border-all); }
.fa-border-none:before { content: fa-content($fa-var-border-none); }
.fa-border-style:before { content: fa-content($fa-var-border-style); }
.fa-bowling-ball:before { content: fa-content($fa-var-bowling-ball); }
.fa-box:before { content: fa-content($fa-var-box); }
.fa-box-open:before { content: fa-content($fa-var-box-open); }
.fa-box-tissue:before { content: fa-content($fa-var-box-tissue); }
.fa-boxes:before { content: fa-content($fa-var-boxes); }
.fa-braille:before { content: fa-content($fa-var-braille); }
.fa-brain:before { content: fa-content($fa-var-brain); }
.fa-bread-slice:before { content: fa-content($fa-var-bread-slice); }
.fa-briefcase:before { content: fa-content($fa-var-briefcase); }
.fa-briefcase-medical:before { content: fa-content($fa-var-briefcase-medical); }
.fa-broadcast-tower:before { content: fa-content($fa-var-broadcast-tower); }
.fa-broom:before { content: fa-content($fa-var-broom); }
.fa-brush:before { content: fa-content($fa-var-brush); }
.fa-btc:before { content: fa-content($fa-var-btc); }
.fa-buffer:before { content: fa-content($fa-var-buffer); }
.fa-bug:before { content: fa-content($fa-var-bug); }
.fa-building:before { content: fa-content($fa-var-building); }
.fa-bullhorn:before { content: fa-content($fa-var-bullhorn); }
.fa-bullseye:before { content: fa-content($fa-var-bullseye); }
.fa-burn:before { content: fa-content($fa-var-burn); }
.fa-buromobelexperte:before { content: fa-content($fa-var-buromobelexperte); }
.fa-bus:before { content: fa-content($fa-var-bus); }
.fa-bus-alt:before { content: fa-content($fa-var-bus-alt); }
.fa-business-time:before { content: fa-content($fa-var-business-time); }
.fa-buy-n-large:before { content: fa-content($fa-var-buy-n-large); }
.fa-buysellads:before { content: fa-content($fa-var-buysellads); }
.fa-calculator:before { content: fa-content($fa-var-calculator); }
.fa-calendar:before { content: fa-content($fa-var-calendar); }
.fa-calendar-alt:before { content: fa-content($fa-var-calendar-alt); }
.fa-calendar-check:before { content: fa-content($fa-var-calendar-check); }
.fa-calendar-day:before { content: fa-content($fa-var-calendar-day); }
.fa-calendar-minus:before { content: fa-content($fa-var-calendar-minus); }
.fa-calendar-plus:before { content: fa-content($fa-var-calendar-plus); }
.fa-calendar-times:before { content: fa-content($fa-var-calendar-times); }
.fa-calendar-week:before { content: fa-content($fa-var-calendar-week); }
.fa-camera:before { content: fa-content($fa-var-camera); }
.fa-camera-retro:before { content: fa-content($fa-var-camera-retro); }
.fa-campground:before { content: fa-content($fa-var-campground); }
.fa-canadian-maple-leaf:before { content: fa-content($fa-var-canadian-maple-leaf); }
.fa-candy-cane:before { content: fa-content($fa-var-candy-cane); }
.fa-cannabis:before { content: fa-content($fa-var-cannabis); }
.fa-capsules:before { content: fa-content($fa-var-capsules); }
.fa-car:before { content: fa-content($fa-var-car); }
.fa-car-alt:before { content: fa-content($fa-var-car-alt); }
.fa-car-battery:before { content: fa-content($fa-var-car-battery); }
.fa-car-crash:before { content: fa-content($fa-var-car-crash); }
.fa-car-side:before { content: fa-content($fa-var-car-side); }
.fa-caravan:before { content: fa-content($fa-var-caravan); }
.fa-caret-down:before { content: fa-content($fa-var-caret-down); }
.fa-caret-left:before { content: fa-content($fa-var-caret-left); }
.fa-caret-right:before { content: fa-content($fa-var-caret-right); }
.fa-caret-square-down:before { content: fa-content($fa-var-caret-square-down); }
.fa-caret-square-left:before { content: fa-content($fa-var-caret-square-left); }
.fa-caret-square-right:before { content: fa-content($fa-var-caret-square-right); }
.fa-caret-square-up:before { content: fa-content($fa-var-caret-square-up); }
.fa-caret-up:before { content: fa-content($fa-var-caret-up); }
.fa-carrot:before { content: fa-content($fa-var-carrot); }
.fa-cart-arrow-down:before { content: fa-content($fa-var-cart-arrow-down); }
.fa-cart-plus:before { content: fa-content($fa-var-cart-plus); }
.fa-cash-register:before { content: fa-content($fa-var-cash-register); }
.fa-cat:before { content: fa-content($fa-var-cat); }
.fa-cc-amazon-pay:before { content: fa-content($fa-var-cc-amazon-pay); }
.fa-cc-amex:before { content: fa-content($fa-var-cc-amex); }
.fa-cc-apple-pay:before { content: fa-content($fa-var-cc-apple-pay); }
.fa-cc-diners-club:before { content: fa-content($fa-var-cc-diners-club); }
.fa-cc-discover:before { content: fa-content($fa-var-cc-discover); }
.fa-cc-jcb:before { content: fa-content($fa-var-cc-jcb); }
.fa-cc-mastercard:before { content: fa-content($fa-var-cc-mastercard); }
.fa-cc-paypal:before { content: fa-content($fa-var-cc-paypal); }
.fa-cc-stripe:before { content: fa-content($fa-var-cc-stripe); }
.fa-cc-visa:before { content: fa-content($fa-var-cc-visa); }
.fa-centercode:before { content: fa-content($fa-var-centercode); }
.fa-centos:before { content: fa-content($fa-var-centos); }
.fa-certificate:before { content: fa-content($fa-var-certificate); }
.fa-chair:before { content: fa-content($fa-var-chair); }
.fa-chalkboard:before { content: fa-content($fa-var-chalkboard); }
.fa-chalkboard-teacher:before { content: fa-content($fa-var-chalkboard-teacher); }
.fa-charging-station:before { content: fa-content($fa-var-charging-station); }
.fa-chart-area:before { content: fa-content($fa-var-chart-area); }
.fa-chart-bar:before { content: fa-content($fa-var-chart-bar); }
.fa-chart-line:before { content: fa-content($fa-var-chart-line); }
.fa-chart-pie:before { content: fa-content($fa-var-chart-pie); }
.fa-check:before { content: fa-content($fa-var-check); }
.fa-check-circle:before { content: fa-content($fa-var-check-circle); }
.fa-check-double:before { content: fa-content($fa-var-check-double); }
.fa-check-square:before { content: fa-content($fa-var-check-square); }
.fa-cheese:before { content: fa-content($fa-var-cheese); }
.fa-chess:before { content: fa-content($fa-var-chess); }
.fa-chess-bishop:before { content: fa-content($fa-var-chess-bishop); }
.fa-chess-board:before { content: fa-content($fa-var-chess-board); }
.fa-chess-king:before { content: fa-content($fa-var-chess-king); }
.fa-chess-knight:before { content: fa-content($fa-var-chess-knight); }
.fa-chess-pawn:before { content: fa-content($fa-var-chess-pawn); }
.fa-chess-queen:before { content: fa-content($fa-var-chess-queen); }
.fa-chess-rook:before { content: fa-content($fa-var-chess-rook); }
.fa-chevron-circle-down:before { content: fa-content($fa-var-chevron-circle-down); }
.fa-chevron-circle-left:before { content: fa-content($fa-var-chevron-circle-left); }
.fa-chevron-circle-right:before { content: fa-content($fa-var-chevron-circle-right); }
.fa-chevron-circle-up:before { content: fa-content($fa-var-chevron-circle-up); }
.fa-chevron-down:before { content: fa-content($fa-var-chevron-down); }
.fa-chevron-left:before { content: fa-content($fa-var-chevron-left); }
.fa-chevron-right:before { content: fa-content($fa-var-chevron-right); }
.fa-chevron-up:before { content: fa-content($fa-var-chevron-up); }
.fa-child:before { content: fa-content($fa-var-child); }
.fa-chrome:before { content: fa-content($fa-var-chrome); }
.fa-chromecast:before { content: fa-content($fa-var-chromecast); }
.fa-church:before { content: fa-content($fa-var-church); }
.fa-circle:before { content: fa-content($fa-var-circle); }
.fa-circle-notch:before { content: fa-content($fa-var-circle-notch); }
.fa-city:before { content: fa-content($fa-var-city); }
.fa-clinic-medical:before { content: fa-content($fa-var-clinic-medical); }
.fa-clipboard:before { content: fa-content($fa-var-clipboard); }
.fa-clipboard-check:before { content: fa-content($fa-var-clipboard-check); }
.fa-clipboard-list:before { content: fa-content($fa-var-clipboard-list); }
.fa-clock:before { content: fa-content($fa-var-clock); }
.fa-clone:before { content: fa-content($fa-var-clone); }
.fa-closed-captioning:before { content: fa-content($fa-var-closed-captioning); }
.fa-cloud:before { content: fa-content($fa-var-cloud); }
.fa-cloud-download-alt:before { content: fa-content($fa-var-cloud-download-alt); }
.fa-cloud-meatball:before { content: fa-content($fa-var-cloud-meatball); }
.fa-cloud-moon:before { content: fa-content($fa-var-cloud-moon); }
.fa-cloud-moon-rain:before { content: fa-content($fa-var-cloud-moon-rain); }
.fa-cloud-rain:before { content: fa-content($fa-var-cloud-rain); }
.fa-cloud-showers-heavy:before { content: fa-content($fa-var-cloud-showers-heavy); }
.fa-cloud-sun:before { content: fa-content($fa-var-cloud-sun); }
.fa-cloud-sun-rain:before { content: fa-content($fa-var-cloud-sun-rain); }
.fa-cloud-upload-alt:before { content: fa-content($fa-var-cloud-upload-alt); }
.fa-cloudflare:before { content: fa-content($fa-var-cloudflare); }
.fa-cloudscale:before { content: fa-content($fa-var-cloudscale); }
.fa-cloudsmith:before { content: fa-content($fa-var-cloudsmith); }
.fa-cloudversify:before { content: fa-content($fa-var-cloudversify); }
.fa-cocktail:before { content: fa-content($fa-var-cocktail); }
.fa-code:before { content: fa-content($fa-var-code); }
.fa-code-branch:before { content: fa-content($fa-var-code-branch); }
.fa-codepen:before { content: fa-content($fa-var-codepen); }
.fa-codiepie:before { content: fa-content($fa-var-codiepie); }
.fa-coffee:before { content: fa-content($fa-var-coffee); }
.fa-cog:before { content: fa-content($fa-var-cog); }
.fa-cogs:before { content: fa-content($fa-var-cogs); }
.fa-coins:before { content: fa-content($fa-var-coins); }
.fa-columns:before { content: fa-content($fa-var-columns); }
.fa-comment:before { content: fa-content($fa-var-comment); }
.fa-comment-alt:before { content: fa-content($fa-var-comment-alt); }
.fa-comment-dollar:before { content: fa-content($fa-var-comment-dollar); }
.fa-comment-dots:before { content: fa-content($fa-var-comment-dots); }
.fa-comment-medical:before { content: fa-content($fa-var-comment-medical); }
.fa-comment-slash:before { content: fa-content($fa-var-comment-slash); }
.fa-comments:before { content: fa-content($fa-var-comments); }
.fa-comments-dollar:before { content: fa-content($fa-var-comments-dollar); }
.fa-compact-disc:before { content: fa-content($fa-var-compact-disc); }
.fa-compass:before { content: fa-content($fa-var-compass); }
.fa-compress:before { content: fa-content($fa-var-compress); }
.fa-compress-alt:before { content: fa-content($fa-var-compress-alt); }
.fa-compress-arrows-alt:before { content: fa-content($fa-var-compress-arrows-alt); }
.fa-concierge-bell:before { content: fa-content($fa-var-concierge-bell); }
.fa-confluence:before { content: fa-content($fa-var-confluence); }
.fa-connectdevelop:before { content: fa-content($fa-var-connectdevelop); }
.fa-contao:before { content: fa-content($fa-var-contao); }
.fa-cookie:before { content: fa-content($fa-var-cookie); }
.fa-cookie-bite:before { content: fa-content($fa-var-cookie-bite); }
.fa-copy:before { content: fa-content($fa-var-copy); }
.fa-copyright:before { content: fa-content($fa-var-copyright); }
.fa-cotton-bureau:before { content: fa-content($fa-var-cotton-bureau); }
.fa-couch:before { content: fa-content($fa-var-couch); }
.fa-cpanel:before { content: fa-content($fa-var-cpanel); }
.fa-creative-commons:before { content: fa-content($fa-var-creative-commons); }
.fa-creative-commons-by:before { content: fa-content($fa-var-creative-commons-by); }
.fa-creative-commons-nc:before { content: fa-content($fa-var-creative-commons-nc); }
.fa-creative-commons-nc-eu:before { content: fa-content($fa-var-creative-commons-nc-eu); }
.fa-creative-commons-nc-jp:before { content: fa-content($fa-var-creative-commons-nc-jp); }
.fa-creative-commons-nd:before { content: fa-content($fa-var-creative-commons-nd); }
.fa-creative-commons-pd:before { content: fa-content($fa-var-creative-commons-pd); }
.fa-creative-commons-pd-alt:before { content: fa-content($fa-var-creative-commons-pd-alt); }
.fa-creative-commons-remix:before { content: fa-content($fa-var-creative-commons-remix); }
.fa-creative-commons-sa:before { content: fa-content($fa-var-creative-commons-sa); }
.fa-creative-commons-sampling:before { content: fa-content($fa-var-creative-commons-sampling); }
.fa-creative-commons-sampling-plus:before { content: fa-content($fa-var-creative-commons-sampling-plus); }
.fa-creative-commons-share:before { content: fa-content($fa-var-creative-commons-share); }
.fa-creative-commons-zero:before { content: fa-content($fa-var-creative-commons-zero); }
.fa-credit-card:before { content: fa-content($fa-var-credit-card); }
.fa-critical-role:before { content: fa-content($fa-var-critical-role); }
.fa-crop:before { content: fa-content($fa-var-crop); }
.fa-crop-alt:before { content: fa-content($fa-var-crop-alt); }
.fa-cross:before { content: fa-content($fa-var-cross); }
.fa-crosshairs:before { content: fa-content($fa-var-crosshairs); }
.fa-crow:before { content: fa-content($fa-var-crow); }
.fa-crown:before { content: fa-content($fa-var-crown); }
.fa-crutch:before { content: fa-content($fa-var-crutch); }
.fa-css3:before { content: fa-content($fa-var-css3); }
.fa-css3-alt:before { content: fa-content($fa-var-css3-alt); }
.fa-cube:before { content: fa-content($fa-var-cube); }
.fa-cubes:before { content: fa-content($fa-var-cubes); }
.fa-cut:before { content: fa-content($fa-var-cut); }
.fa-cuttlefish:before { content: fa-content($fa-var-cuttlefish); }
.fa-d-and-d:before { content: fa-content($fa-var-d-and-d); }
.fa-d-and-d-beyond:before { content: fa-content($fa-var-d-and-d-beyond); }
.fa-dailymotion:before { content: fa-content($fa-var-dailymotion); }
.fa-dashcube:before { content: fa-content($fa-var-dashcube); }
.fa-database:before { content: fa-content($fa-var-database); }
.fa-deaf:before { content: fa-content($fa-var-deaf); }
.fa-deezer:before { content: fa-content($fa-var-deezer); }
.fa-delicious:before { content: fa-content($fa-var-delicious); }
.fa-democrat:before { content: fa-content($fa-var-democrat); }
.fa-deploydog:before { content: fa-content($fa-var-deploydog); }
.fa-deskpro:before { content: fa-content($fa-var-deskpro); }
.fa-desktop:before { content: fa-content($fa-var-desktop); }
.fa-dev:before { content: fa-content($fa-var-dev); }
.fa-deviantart:before { content: fa-content($fa-var-deviantart); }
.fa-dharmachakra:before { content: fa-content($fa-var-dharmachakra); }
.fa-dhl:before { content: fa-content($fa-var-dhl); }
.fa-diagnoses:before { content: fa-content($fa-var-diagnoses); }
.fa-diaspora:before { content: fa-content($fa-var-diaspora); }
.fa-dice:before { content: fa-content($fa-var-dice); }
.fa-dice-d20:before { content: fa-content($fa-var-dice-d20); }
.fa-dice-d6:before { content: fa-content($fa-var-dice-d6); }
.fa-dice-five:before { content: fa-content($fa-var-dice-five); }
.fa-dice-four:before { content: fa-content($fa-var-dice-four); }
.fa-dice-one:before { content: fa-content($fa-var-dice-one); }
.fa-dice-six:before { content: fa-content($fa-var-dice-six); }
.fa-dice-three:before { content: fa-content($fa-var-dice-three); }
.fa-dice-two:before { content: fa-content($fa-var-dice-two); }
.fa-digg:before { content: fa-content($fa-var-digg); }
.fa-digital-ocean:before { content: fa-content($fa-var-digital-ocean); }
.fa-digital-tachograph:before { content: fa-content($fa-var-digital-tachograph); }
.fa-directions:before { content: fa-content($fa-var-directions); }
.fa-discord:before { content: fa-content($fa-var-discord); }
.fa-discourse:before { content: fa-content($fa-var-discourse); }
.fa-disease:before { content: fa-content($fa-var-disease); }
.fa-divide:before { content: fa-content($fa-var-divide); }
.fa-dizzy:before { content: fa-content($fa-var-dizzy); }
.fa-dna:before { content: fa-content($fa-var-dna); }
.fa-dochub:before { content: fa-content($fa-var-dochub); }
.fa-docker:before { content: fa-content($fa-var-docker); }
.fa-dog:before { content: fa-content($fa-var-dog); }
.fa-dollar-sign:before { content: fa-content($fa-var-dollar-sign); }
.fa-dolly:before { content: fa-content($fa-var-dolly); }
.fa-dolly-flatbed:before { content: fa-content($fa-var-dolly-flatbed); }
.fa-donate:before { content: fa-content($fa-var-donate); }
.fa-door-closed:before { content: fa-content($fa-var-door-closed); }
.fa-door-open:before { content: fa-content($fa-var-door-open); }
.fa-dot-circle:before { content: fa-content($fa-var-dot-circle); }
.fa-dove:before { content: fa-content($fa-var-dove); }
.fa-download:before { content: fa-content($fa-var-download); }
.fa-draft2digital:before { content: fa-content($fa-var-draft2digital); }
.fa-drafting-compass:before { content: fa-content($fa-var-drafting-compass); }
.fa-dragon:before { content: fa-content($fa-var-dragon); }
.fa-draw-polygon:before { content: fa-content($fa-var-draw-polygon); }
.fa-dribbble:before { content: fa-content($fa-var-dribbble); }
.fa-dribbble-square:before { content: fa-content($fa-var-dribbble-square); }
.fa-dropbox:before { content: fa-content($fa-var-dropbox); }
.fa-drum:before { content: fa-content($fa-var-drum); }
.fa-drum-steelpan:before { content: fa-content($fa-var-drum-steelpan); }
.fa-drumstick-bite:before { content: fa-content($fa-var-drumstick-bite); }
.fa-drupal:before { content: fa-content($fa-var-drupal); }
.fa-dumbbell:before { content: fa-content($fa-var-dumbbell); }
.fa-dumpster:before { content: fa-content($fa-var-dumpster); }
.fa-dumpster-fire:before { content: fa-content($fa-var-dumpster-fire); }
.fa-dungeon:before { content: fa-content($fa-var-dungeon); }
.fa-dyalog:before { content: fa-content($fa-var-dyalog); }
.fa-earlybirds:before { content: fa-content($fa-var-earlybirds); }
.fa-ebay:before { content: fa-content($fa-var-ebay); }
.fa-edge:before { content: fa-content($fa-var-edge); }
.fa-edge-legacy:before { content: fa-content($fa-var-edge-legacy); }
.fa-edit:before { content: fa-content($fa-var-edit); }
.fa-egg:before { content: fa-content($fa-var-egg); }
.fa-eject:before { content: fa-content($fa-var-eject); }
.fa-elementor:before { content: fa-content($fa-var-elementor); }
.fa-ellipsis-h:before { content: fa-content($fa-var-ellipsis-h); }
.fa-ellipsis-v:before { content: fa-content($fa-var-ellipsis-v); }
.fa-ello:before { content: fa-content($fa-var-ello); }
.fa-ember:before { content: fa-content($fa-var-ember); }
.fa-empire:before { content: fa-content($fa-var-empire); }
.fa-envelope:before { content: fa-content($fa-var-envelope); }
.fa-envelope-open:before { content: fa-content($fa-var-envelope-open); }
.fa-envelope-open-text:before { content: fa-content($fa-var-envelope-open-text); }
.fa-envelope-square:before { content: fa-content($fa-var-envelope-square); }
.fa-envira:before { content: fa-content($fa-var-envira); }
.fa-equals:before { content: fa-content($fa-var-equals); }
.fa-eraser:before { content: fa-content($fa-var-eraser); }
.fa-erlang:before { content: fa-content($fa-var-erlang); }
.fa-ethereum:before { content: fa-content($fa-var-ethereum); }
.fa-ethernet:before { content: fa-content($fa-var-ethernet); }
.fa-etsy:before { content: fa-content($fa-var-etsy); }
.fa-euro-sign:before { content: fa-content($fa-var-euro-sign); }
.fa-evernote:before { content: fa-content($fa-var-evernote); }
.fa-exchange-alt:before { content: fa-content($fa-var-exchange-alt); }
.fa-exclamation:before { content: fa-content($fa-var-exclamation); }
.fa-exclamation-circle:before { content: fa-content($fa-var-exclamation-circle); }
.fa-exclamation-triangle:before { content: fa-content($fa-var-exclamation-triangle); }
.fa-expand:before { content: fa-content($fa-var-expand); }
.fa-expand-alt:before { content: fa-content($fa-var-expand-alt); }
.fa-expand-arrows-alt:before { content: fa-content($fa-var-expand-arrows-alt); }
.fa-expeditedssl:before { content: fa-content($fa-var-expeditedssl); }
.fa-external-link-alt:before { content: fa-content($fa-var-external-link-alt); }
.fa-external-link-square-alt:before { content: fa-content($fa-var-external-link-square-alt); }
.fa-eye:before { content: fa-content($fa-var-eye); }
.fa-eye-dropper:before { content: fa-content($fa-var-eye-dropper); }
.fa-eye-slash:before { content: fa-content($fa-var-eye-slash); }
.fa-facebook:before { content: fa-content($fa-var-facebook); }
.fa-facebook-f:before { content: fa-content($fa-var-facebook-f); }
.fa-facebook-messenger:before { content: fa-content($fa-var-facebook-messenger); }
.fa-facebook-square:before { content: fa-content($fa-var-facebook-square); }
.fa-fan:before { content: fa-content($fa-var-fan); }
.fa-fantasy-flight-games:before { content: fa-content($fa-var-fantasy-flight-games); }
.fa-fast-backward:before { content: fa-content($fa-var-fast-backward); }
.fa-fast-forward:before { content: fa-content($fa-var-fast-forward); }
.fa-faucet:before { content: fa-content($fa-var-faucet); }
.fa-fax:before { content: fa-content($fa-var-fax); }
.fa-feather:before { content: fa-content($fa-var-feather); }
.fa-feather-alt:before { content: fa-content($fa-var-feather-alt); }
.fa-fedex:before { content: fa-content($fa-var-fedex); }
.fa-fedora:before { content: fa-content($fa-var-fedora); }
.fa-female:before { content: fa-content($fa-var-female); }
.fa-fighter-jet:before { content: fa-content($fa-var-fighter-jet); }
.fa-figma:before { content: fa-content($fa-var-figma); }
.fa-file:before { content: fa-content($fa-var-file); }
.fa-file-alt:before { content: fa-content($fa-var-file-alt); }
.fa-file-archive:before { content: fa-content($fa-var-file-archive); }
.fa-file-audio:before { content: fa-content($fa-var-file-audio); }
.fa-file-code:before { content: fa-content($fa-var-file-code); }
.fa-file-contract:before { content: fa-content($fa-var-file-contract); }
.fa-file-csv:before { content: fa-content($fa-var-file-csv); }
.fa-file-download:before { content: fa-content($fa-var-file-download); }
.fa-file-excel:before { content: fa-content($fa-var-file-excel); }
.fa-file-export:before { content: fa-content($fa-var-file-export); }
.fa-file-image:before { content: fa-content($fa-var-file-image); }
.fa-file-import:before { content: fa-content($fa-var-file-import); }
.fa-file-invoice:before { content: fa-content($fa-var-file-invoice); }
.fa-file-invoice-dollar:before { content: fa-content($fa-var-file-invoice-dollar); }
.fa-file-medical:before { content: fa-content($fa-var-file-medical); }
.fa-file-medical-alt:before { content: fa-content($fa-var-file-medical-alt); }
.fa-file-pdf:before { content: fa-content($fa-var-file-pdf); }
.fa-file-powerpoint:before { content: fa-content($fa-var-file-powerpoint); }
.fa-file-prescription:before { content: fa-content($fa-var-file-prescription); }
.fa-file-signature:before { content: fa-content($fa-var-file-signature); }
.fa-file-upload:before { content: fa-content($fa-var-file-upload); }
.fa-file-video:before { content: fa-content($fa-var-file-video); }
.fa-file-word:before { content: fa-content($fa-var-file-word); }
.fa-fill:before { content: fa-content($fa-var-fill); }
.fa-fill-drip:before { content: fa-content($fa-var-fill-drip); }
.fa-film:before { content: fa-content($fa-var-film); }
.fa-filter:before { content: fa-content($fa-var-filter); }
.fa-fingerprint:before { content: fa-content($fa-var-fingerprint); }
.fa-fire:before { content: fa-content($fa-var-fire); }
.fa-fire-alt:before { content: fa-content($fa-var-fire-alt); }
.fa-fire-extinguisher:before { content: fa-content($fa-var-fire-extinguisher); }
.fa-firefox:before { content: fa-content($fa-var-firefox); }
.fa-firefox-browser:before { content: fa-content($fa-var-firefox-browser); }
.fa-first-aid:before { content: fa-content($fa-var-first-aid); }
.fa-first-order:before { content: fa-content($fa-var-first-order); }
.fa-first-order-alt:before { content: fa-content($fa-var-first-order-alt); }
.fa-firstdraft:before { content: fa-content($fa-var-firstdraft); }
.fa-fish:before { content: fa-content($fa-var-fish); }
.fa-fist-raised:before { content: fa-content($fa-var-fist-raised); }
.fa-flag:before { content: fa-content($fa-var-flag); }
.fa-flag-checkered:before { content: fa-content($fa-var-flag-checkered); }
.fa-flag-usa:before { content: fa-content($fa-var-flag-usa); }
.fa-flask:before { content: fa-content($fa-var-flask); }
.fa-flickr:before { content: fa-content($fa-var-flickr); }
.fa-flipboard:before { content: fa-content($fa-var-flipboard); }
.fa-flushed:before { content: fa-content($fa-var-flushed); }
.fa-fly:before { content: fa-content($fa-var-fly); }
.fa-folder:before { content: fa-content($fa-var-folder); }
.fa-folder-minus:before { content: fa-content($fa-var-folder-minus); }
.fa-folder-open:before { content: fa-content($fa-var-folder-open); }
.fa-folder-plus:before { content: fa-content($fa-var-folder-plus); }
.fa-font:before { content: fa-content($fa-var-font); }
.fa-font-awesome:before { content: fa-content($fa-var-font-awesome); }
.fa-font-awesome-alt:before { content: fa-content($fa-var-font-awesome-alt); }
.fa-font-awesome-flag:before { content: fa-content($fa-var-font-awesome-flag); }
.fa-font-awesome-logo-full:before { content: fa-content($fa-var-font-awesome-logo-full); }
.fa-fonticons:before { content: fa-content($fa-var-fonticons); }
.fa-fonticons-fi:before { content: fa-content($fa-var-fonticons-fi); }
.fa-football-ball:before { content: fa-content($fa-var-football-ball); }
.fa-fort-awesome:before { content: fa-content($fa-var-fort-awesome); }
.fa-fort-awesome-alt:before { content: fa-content($fa-var-fort-awesome-alt); }
.fa-forumbee:before { content: fa-content($fa-var-forumbee); }
.fa-forward:before { content: fa-content($fa-var-forward); }
.fa-foursquare:before { content: fa-content($fa-var-foursquare); }
.fa-free-code-camp:before { content: fa-content($fa-var-free-code-camp); }
.fa-freebsd:before { content: fa-content($fa-var-freebsd); }
.fa-frog:before { content: fa-content($fa-var-frog); }
.fa-frown:before { content: fa-content($fa-var-frown); }
.fa-frown-open:before { content: fa-content($fa-var-frown-open); }
.fa-fulcrum:before { content: fa-content($fa-var-fulcrum); }
.fa-funnel-dollar:before { content: fa-content($fa-var-funnel-dollar); }
.fa-futbol:before { content: fa-content($fa-var-futbol); }
.fa-galactic-republic:before { content: fa-content($fa-var-galactic-republic); }
.fa-galactic-senate:before { content: fa-content($fa-var-galactic-senate); }
.fa-gamepad:before { content: fa-content($fa-var-gamepad); }
.fa-gas-pump:before { content: fa-content($fa-var-gas-pump); }
.fa-gavel:before { content: fa-content($fa-var-gavel); }
.fa-gem:before { content: fa-content($fa-var-gem); }
.fa-genderless:before { content: fa-content($fa-var-genderless); }
.fa-get-pocket:before { content: fa-content($fa-var-get-pocket); }
.fa-gg:before { content: fa-content($fa-var-gg); }
.fa-gg-circle:before { content: fa-content($fa-var-gg-circle); }
.fa-ghost:before { content: fa-content($fa-var-ghost); }
.fa-gift:before { content: fa-content($fa-var-gift); }
.fa-gifts:before { content: fa-content($fa-var-gifts); }
.fa-git:before { content: fa-content($fa-var-git); }
.fa-git-alt:before { content: fa-content($fa-var-git-alt); }
.fa-git-square:before { content: fa-content($fa-var-git-square); }
.fa-github:before { content: fa-content($fa-var-github); }
.fa-github-alt:before { content: fa-content($fa-var-github-alt); }
.fa-github-square:before { content: fa-content($fa-var-github-square); }
.fa-gitkraken:before { content: fa-content($fa-var-gitkraken); }
.fa-gitlab:before { content: fa-content($fa-var-gitlab); }
.fa-gitter:before { content: fa-content($fa-var-gitter); }
.fa-glass-cheers:before { content: fa-content($fa-var-glass-cheers); }
.fa-glass-martini:before { content: fa-content($fa-var-glass-martini); }
.fa-glass-martini-alt:before { content: fa-content($fa-var-glass-martini-alt); }
.fa-glass-whiskey:before { content: fa-content($fa-var-glass-whiskey); }
.fa-glasses:before { content: fa-content($fa-var-glasses); }
.fa-glide:before { content: fa-content($fa-var-glide); }
.fa-glide-g:before { content: fa-content($fa-var-glide-g); }
.fa-globe:before { content: fa-content($fa-var-globe); }
.fa-globe-africa:before { content: fa-content($fa-var-globe-africa); }
.fa-globe-americas:before { content: fa-content($fa-var-globe-americas); }
.fa-globe-asia:before { content: fa-content($fa-var-globe-asia); }
.fa-globe-europe:before { content: fa-content($fa-var-globe-europe); }
.fa-gofore:before { content: fa-content($fa-var-gofore); }
.fa-golf-ball:before { content: fa-content($fa-var-golf-ball); }
.fa-goodreads:before { content: fa-content($fa-var-goodreads); }
.fa-goodreads-g:before { content: fa-content($fa-var-goodreads-g); }
.fa-google:before { content: fa-content($fa-var-google); }
.fa-google-drive:before { content: fa-content($fa-var-google-drive); }
.fa-google-pay:before { content: fa-content($fa-var-google-pay); }
.fa-google-play:before { content: fa-content($fa-var-google-play); }
.fa-google-plus:before { content: fa-content($fa-var-google-plus); }
.fa-google-plus-g:before { content: fa-content($fa-var-google-plus-g); }
.fa-google-plus-square:before { content: fa-content($fa-var-google-plus-square); }
.fa-google-wallet:before { content: fa-content($fa-var-google-wallet); }
.fa-gopuram:before { content: fa-content($fa-var-gopuram); }
.fa-graduation-cap:before { content: fa-content($fa-var-graduation-cap); }
.fa-gratipay:before { content: fa-content($fa-var-gratipay); }
.fa-grav:before { content: fa-content($fa-var-grav); }
.fa-greater-than:before { content: fa-content($fa-var-greater-than); }
.fa-greater-than-equal:before { content: fa-content($fa-var-greater-than-equal); }
.fa-grimace:before { content: fa-content($fa-var-grimace); }
.fa-grin:before { content: fa-content($fa-var-grin); }
.fa-grin-alt:before { content: fa-content($fa-var-grin-alt); }
.fa-grin-beam:before { content: fa-content($fa-var-grin-beam); }
.fa-grin-beam-sweat:before { content: fa-content($fa-var-grin-beam-sweat); }
.fa-grin-hearts:before { content: fa-content($fa-var-grin-hearts); }
.fa-grin-squint:before { content: fa-content($fa-var-grin-squint); }
.fa-grin-squint-tears:before { content: fa-content($fa-var-grin-squint-tears); }
.fa-grin-stars:before { content: fa-content($fa-var-grin-stars); }
.fa-grin-tears:before { content: fa-content($fa-var-grin-tears); }
.fa-grin-tongue:before { content: fa-content($fa-var-grin-tongue); }
.fa-grin-tongue-squint:before { content: fa-content($fa-var-grin-tongue-squint); }
.fa-grin-tongue-wink:before { content: fa-content($fa-var-grin-tongue-wink); }
.fa-grin-wink:before { content: fa-content($fa-var-grin-wink); }
.fa-grip-horizontal:before { content: fa-content($fa-var-grip-horizontal); }
.fa-grip-lines:before { content: fa-content($fa-var-grip-lines); }
.fa-grip-lines-vertical:before { content: fa-content($fa-var-grip-lines-vertical); }
.fa-grip-vertical:before { content: fa-content($fa-var-grip-vertical); }
.fa-gripfire:before { content: fa-content($fa-var-gripfire); }
.fa-grunt:before { content: fa-content($fa-var-grunt); }
.fa-guilded:before { content: fa-content($fa-var-guilded); }
.fa-guitar:before { content: fa-content($fa-var-guitar); }
.fa-gulp:before { content: fa-content($fa-var-gulp); }
.fa-h-square:before { content: fa-content($fa-var-h-square); }
.fa-hacker-news:before { content: fa-content($fa-var-hacker-news); }
.fa-hacker-news-square:before { content: fa-content($fa-var-hacker-news-square); }
.fa-hackerrank:before { content: fa-content($fa-var-hackerrank); }
.fa-hamburger:before { content: fa-content($fa-var-hamburger); }
.fa-hammer:before { content: fa-content($fa-var-hammer); }
.fa-hamsa:before { content: fa-content($fa-var-hamsa); }
.fa-hand-holding:before { content: fa-content($fa-var-hand-holding); }
.fa-hand-holding-heart:before { content: fa-content($fa-var-hand-holding-heart); }
.fa-hand-holding-medical:before { content: fa-content($fa-var-hand-holding-medical); }
.fa-hand-holding-usd:before { content: fa-content($fa-var-hand-holding-usd); }
.fa-hand-holding-water:before { content: fa-content($fa-var-hand-holding-water); }
.fa-hand-lizard:before { content: fa-content($fa-var-hand-lizard); }
.fa-hand-middle-finger:before { content: fa-content($fa-var-hand-middle-finger); }
.fa-hand-paper:before { content: fa-content($fa-var-hand-paper); }
.fa-hand-peace:before { content: fa-content($fa-var-hand-peace); }
.fa-hand-point-down:before { content: fa-content($fa-var-hand-point-down); }
.fa-hand-point-left:before { content: fa-content($fa-var-hand-point-left); }
.fa-hand-point-right:before { content: fa-content($fa-var-hand-point-right); }
.fa-hand-point-up:before { content: fa-content($fa-var-hand-point-up); }
.fa-hand-pointer:before { content: fa-content($fa-var-hand-pointer); }
.fa-hand-rock:before { content: fa-content($fa-var-hand-rock); }
.fa-hand-scissors:before { content: fa-content($fa-var-hand-scissors); }
.fa-hand-sparkles:before { content: fa-content($fa-var-hand-sparkles); }
.fa-hand-spock:before { content: fa-content($fa-var-hand-spock); }
.fa-hands:before { content: fa-content($fa-var-hands); }
.fa-hands-helping:before { content: fa-content($fa-var-hands-helping); }
.fa-hands-wash:before { content: fa-content($fa-var-hands-wash); }
.fa-handshake:before { content: fa-content($fa-var-handshake); }
.fa-handshake-alt-slash:before { content: fa-content($fa-var-handshake-alt-slash); }
.fa-handshake-slash:before { content: fa-content($fa-var-handshake-slash); }
.fa-hanukiah:before { content: fa-content($fa-var-hanukiah); }
.fa-hard-hat:before { content: fa-content($fa-var-hard-hat); }
.fa-hashtag:before { content: fa-content($fa-var-hashtag); }
.fa-hat-cowboy:before { content: fa-content($fa-var-hat-cowboy); }
.fa-hat-cowboy-side:before { content: fa-content($fa-var-hat-cowboy-side); }
.fa-hat-wizard:before { content: fa-content($fa-var-hat-wizard); }
.fa-hdd:before { content: fa-content($fa-var-hdd); }
.fa-head-side-cough:before { content: fa-content($fa-var-head-side-cough); }
.fa-head-side-cough-slash:before { content: fa-content($fa-var-head-side-cough-slash); }
.fa-head-side-mask:before { content: fa-content($fa-var-head-side-mask); }
.fa-head-side-virus:before { content: fa-content($fa-var-head-side-virus); }
.fa-heading:before { content: fa-content($fa-var-heading); }
.fa-headphones:before { content: fa-content($fa-var-headphones); }
.fa-headphones-alt:before { content: fa-content($fa-var-headphones-alt); }
.fa-headset:before { content: fa-content($fa-var-headset); }
.fa-heart:before { content: fa-content($fa-var-heart); }
.fa-heart-broken:before { content: fa-content($fa-var-heart-broken); }
.fa-heartbeat:before { content: fa-content($fa-var-heartbeat); }
.fa-helicopter:before { content: fa-content($fa-var-helicopter); }
.fa-highlighter:before { content: fa-content($fa-var-highlighter); }
.fa-hiking:before { content: fa-content($fa-var-hiking); }
.fa-hippo:before { content: fa-content($fa-var-hippo); }
.fa-hips:before { content: fa-content($fa-var-hips); }
.fa-hire-a-helper:before { content: fa-content($fa-var-hire-a-helper); }
.fa-history:before { content: fa-content($fa-var-history); }
.fa-hive:before { content: fa-content($fa-var-hive); }
.fa-hockey-puck:before { content: fa-content($fa-var-hockey-puck); }
.fa-holly-berry:before { content: fa-content($fa-var-holly-berry); }
.fa-home:before { content: fa-content($fa-var-home); }
.fa-hooli:before { content: fa-content($fa-var-hooli); }
.fa-hornbill:before { content: fa-content($fa-var-hornbill); }
.fa-horse:before { content: fa-content($fa-var-horse); }
.fa-horse-head:before { content: fa-content($fa-var-horse-head); }
.fa-hospital:before { content: fa-content($fa-var-hospital); }
.fa-hospital-alt:before { content: fa-content($fa-var-hospital-alt); }
.fa-hospital-symbol:before { content: fa-content($fa-var-hospital-symbol); }
.fa-hospital-user:before { content: fa-content($fa-var-hospital-user); }
.fa-hot-tub:before { content: fa-content($fa-var-hot-tub); }
.fa-hotdog:before { content: fa-content($fa-var-hotdog); }
.fa-hotel:before { content: fa-content($fa-var-hotel); }
.fa-hotjar:before { content: fa-content($fa-var-hotjar); }
.fa-hourglass:before { content: fa-content($fa-var-hourglass); }
.fa-hourglass-end:before { content: fa-content($fa-var-hourglass-end); }
.fa-hourglass-half:before { content: fa-content($fa-var-hourglass-half); }
.fa-hourglass-start:before { content: fa-content($fa-var-hourglass-start); }
.fa-house-damage:before { content: fa-content($fa-var-house-damage); }
.fa-house-user:before { content: fa-content($fa-var-house-user); }
.fa-houzz:before { content: fa-content($fa-var-houzz); }
.fa-hryvnia:before { content: fa-content($fa-var-hryvnia); }
.fa-html5:before { content: fa-content($fa-var-html5); }
.fa-hubspot:before { content: fa-content($fa-var-hubspot); }
.fa-i-cursor:before { content: fa-content($fa-var-i-cursor); }
.fa-ice-cream:before { content: fa-content($fa-var-ice-cream); }
.fa-icicles:before { content: fa-content($fa-var-icicles); }
.fa-icons:before { content: fa-content($fa-var-icons); }
.fa-id-badge:before { content: fa-content($fa-var-id-badge); }
.fa-id-card:before { content: fa-content($fa-var-id-card); }
.fa-id-card-alt:before { content: fa-content($fa-var-id-card-alt); }
.fa-ideal:before { content: fa-content($fa-var-ideal); }
.fa-igloo:before { content: fa-content($fa-var-igloo); }
.fa-image:before { content: fa-content($fa-var-image); }
.fa-images:before { content: fa-content($fa-var-images); }
.fa-imdb:before { content: fa-content($fa-var-imdb); }
.fa-inbox:before { content: fa-content($fa-var-inbox); }
.fa-indent:before { content: fa-content($fa-var-indent); }
.fa-industry:before { content: fa-content($fa-var-industry); }
.fa-infinity:before { content: fa-content($fa-var-infinity); }
.fa-info:before { content: fa-content($fa-var-info); }
.fa-info-circle:before { content: fa-content($fa-var-info-circle); }
.fa-innosoft:before { content: fa-content($fa-var-innosoft); }
.fa-instagram:before { content: fa-content($fa-var-instagram); }
.fa-instagram-square:before { content: fa-content($fa-var-instagram-square); }
.fa-instalod:before { content: fa-content($fa-var-instalod); }
.fa-intercom:before { content: fa-content($fa-var-intercom); }
.fa-internet-explorer:before { content: fa-content($fa-var-internet-explorer); }
.fa-invision:before { content: fa-content($fa-var-invision); }
.fa-ioxhost:before { content: fa-content($fa-var-ioxhost); }
.fa-italic:before { content: fa-content($fa-var-italic); }
.fa-itch-io:before { content: fa-content($fa-var-itch-io); }
.fa-itunes:before { content: fa-content($fa-var-itunes); }
.fa-itunes-note:before { content: fa-content($fa-var-itunes-note); }
.fa-java:before { content: fa-content($fa-var-java); }
.fa-jedi:before { content: fa-content($fa-var-jedi); }
.fa-jedi-order:before { content: fa-content($fa-var-jedi-order); }
.fa-jenkins:before { content: fa-content($fa-var-jenkins); }
.fa-jira:before { content: fa-content($fa-var-jira); }
.fa-joget:before { content: fa-content($fa-var-joget); }
.fa-joint:before { content: fa-content($fa-var-joint); }
.fa-joomla:before { content: fa-content($fa-var-joomla); }
.fa-journal-whills:before { content: fa-content($fa-var-journal-whills); }
.fa-js:before { content: fa-content($fa-var-js); }
.fa-js-square:before { content: fa-content($fa-var-js-square); }
.fa-jsfiddle:before { content: fa-content($fa-var-jsfiddle); }
.fa-kaaba:before { content: fa-content($fa-var-kaaba); }
.fa-kaggle:before { content: fa-content($fa-var-kaggle); }
.fa-key:before { content: fa-content($fa-var-key); }
.fa-keybase:before { content: fa-content($fa-var-keybase); }
.fa-keyboard:before { content: fa-content($fa-var-keyboard); }
.fa-keycdn:before { content: fa-content($fa-var-keycdn); }
.fa-khanda:before { content: fa-content($fa-var-khanda); }
.fa-kickstarter:before { content: fa-content($fa-var-kickstarter); }
.fa-kickstarter-k:before { content: fa-content($fa-var-kickstarter-k); }
.fa-kiss:before { content: fa-content($fa-var-kiss); }
.fa-kiss-beam:before { content: fa-content($fa-var-kiss-beam); }
.fa-kiss-wink-heart:before { content: fa-content($fa-var-kiss-wink-heart); }
.fa-kiwi-bird:before { content: fa-content($fa-var-kiwi-bird); }
.fa-korvue:before { content: fa-content($fa-var-korvue); }
.fa-landmark:before { content: fa-content($fa-var-landmark); }
.fa-language:before { content: fa-content($fa-var-language); }
.fa-laptop:before { content: fa-content($fa-var-laptop); }
.fa-laptop-code:before { content: fa-content($fa-var-laptop-code); }
.fa-laptop-house:before { content: fa-content($fa-var-laptop-house); }
.fa-laptop-medical:before { content: fa-content($fa-var-laptop-medical); }
.fa-laravel:before { content: fa-content($fa-var-laravel); }
.fa-lastfm:before { content: fa-content($fa-var-lastfm); }
.fa-lastfm-square:before { content: fa-content($fa-var-lastfm-square); }
.fa-laugh:before { content: fa-content($fa-var-laugh); }
.fa-laugh-beam:before { content: fa-content($fa-var-laugh-beam); }
.fa-laugh-squint:before { content: fa-content($fa-var-laugh-squint); }
.fa-laugh-wink:before { content: fa-content($fa-var-laugh-wink); }
.fa-layer-group:before { content: fa-content($fa-var-layer-group); }
.fa-leaf:before { content: fa-content($fa-var-leaf); }
.fa-leanpub:before { content: fa-content($fa-var-leanpub); }
.fa-lemon:before { content: fa-content($fa-var-lemon); }
.fa-less:before { content: fa-content($fa-var-less); }
.fa-less-than:before { content: fa-content($fa-var-less-than); }
.fa-less-than-equal:before { content: fa-content($fa-var-less-than-equal); }
.fa-level-down-alt:before { content: fa-content($fa-var-level-down-alt); }
.fa-level-up-alt:before { content: fa-content($fa-var-level-up-alt); }
.fa-life-ring:before { content: fa-content($fa-var-life-ring); }
.fa-lightbulb:before { content: fa-content($fa-var-lightbulb); }
.fa-line:before { content: fa-content($fa-var-line); }
.fa-link:before { content: fa-content($fa-var-link); }
.fa-linkedin:before { content: fa-content($fa-var-linkedin); }
.fa-linkedin-in:before { content: fa-content($fa-var-linkedin-in); }
.fa-linode:before { content: fa-content($fa-var-linode); }
.fa-linux:before { content: fa-content($fa-var-linux); }
.fa-lira-sign:before { content: fa-content($fa-var-lira-sign); }
.fa-list:before { content: fa-content($fa-var-list); }
.fa-list-alt:before { content: fa-content($fa-var-list-alt); }
.fa-list-ol:before { content: fa-content($fa-var-list-ol); }
.fa-list-ul:before { content: fa-content($fa-var-list-ul); }
.fa-location-arrow:before { content: fa-content($fa-var-location-arrow); }
.fa-lock:before { content: fa-content($fa-var-lock); }
.fa-lock-open:before { content: fa-content($fa-var-lock-open); }
.fa-long-arrow-alt-down:before { content: fa-content($fa-var-long-arrow-alt-down); }
.fa-long-arrow-alt-left:before { content: fa-content($fa-var-long-arrow-alt-left); }
.fa-long-arrow-alt-right:before { content: fa-content($fa-var-long-arrow-alt-right); }
.fa-long-arrow-alt-up:before { content: fa-content($fa-var-long-arrow-alt-up); }
.fa-low-vision:before { content: fa-content($fa-var-low-vision); }
.fa-luggage-cart:before { content: fa-content($fa-var-luggage-cart); }
.fa-lungs:before { content: fa-content($fa-var-lungs); }
.fa-lungs-virus:before { content: fa-content($fa-var-lungs-virus); }
.fa-lyft:before { content: fa-content($fa-var-lyft); }
.fa-magento:before { content: fa-content($fa-var-magento); }
.fa-magic:before { content: fa-content($fa-var-magic); }
.fa-magnet:before { content: fa-content($fa-var-magnet); }
.fa-mail-bulk:before { content: fa-content($fa-var-mail-bulk); }
.fa-mailchimp:before { content: fa-content($fa-var-mailchimp); }
.fa-male:before { content: fa-content($fa-var-male); }
.fa-mandalorian:before { content: fa-content($fa-var-mandalorian); }
.fa-map:before { content: fa-content($fa-var-map); }
.fa-map-marked:before { content: fa-content($fa-var-map-marked); }
.fa-map-marked-alt:before { content: fa-content($fa-var-map-marked-alt); }
.fa-map-marker:before { content: fa-content($fa-var-map-marker); }
.fa-map-marker-alt:before { content: fa-content($fa-var-map-marker-alt); }
.fa-map-pin:before { content: fa-content($fa-var-map-pin); }
.fa-map-signs:before { content: fa-content($fa-var-map-signs); }
.fa-markdown:before { content: fa-content($fa-var-markdown); }
.fa-marker:before { content: fa-content($fa-var-marker); }
.fa-mars:before { content: fa-content($fa-var-mars); }
.fa-mars-double:before { content: fa-content($fa-var-mars-double); }
.fa-mars-stroke:before { content: fa-content($fa-var-mars-stroke); }
.fa-mars-stroke-h:before { content: fa-content($fa-var-mars-stroke-h); }
.fa-mars-stroke-v:before { content: fa-content($fa-var-mars-stroke-v); }
.fa-mask:before { content: fa-content($fa-var-mask); }
.fa-mastodon:before { content: fa-content($fa-var-mastodon); }
.fa-maxcdn:before { content: fa-content($fa-var-maxcdn); }
.fa-mdb:before { content: fa-content($fa-var-mdb); }
.fa-medal:before { content: fa-content($fa-var-medal); }
.fa-medapps:before { content: fa-content($fa-var-medapps); }
.fa-medium:before { content: fa-content($fa-var-medium); }
.fa-medium-m:before { content: fa-content($fa-var-medium-m); }
.fa-medkit:before { content: fa-content($fa-var-medkit); }
.fa-medrt:before { content: fa-content($fa-var-medrt); }
.fa-meetup:before { content: fa-content($fa-var-meetup); }
.fa-megaport:before { content: fa-content($fa-var-megaport); }
.fa-meh:before { content: fa-content($fa-var-meh); }
.fa-meh-blank:before { content: fa-content($fa-var-meh-blank); }
.fa-meh-rolling-eyes:before { content: fa-content($fa-var-meh-rolling-eyes); }
.fa-memory:before { content: fa-content($fa-var-memory); }
.fa-mendeley:before { content: fa-content($fa-var-mendeley); }
.fa-menorah:before { content: fa-content($fa-var-menorah); }
.fa-mercury:before { content: fa-content($fa-var-mercury); }
.fa-meteor:before { content: fa-content($fa-var-meteor); }
.fa-microblog:before { content: fa-content($fa-var-microblog); }
.fa-microchip:before { content: fa-content($fa-var-microchip); }
.fa-microphone:before { content: fa-content($fa-var-microphone); }
.fa-microphone-alt:before { content: fa-content($fa-var-microphone-alt); }
.fa-microphone-alt-slash:before { content: fa-content($fa-var-microphone-alt-slash); }
.fa-microphone-slash:before { content: fa-content($fa-var-microphone-slash); }
.fa-microscope:before { content: fa-content($fa-var-microscope); }
.fa-microsoft:before { content: fa-content($fa-var-microsoft); }
.fa-minus:before { content: fa-content($fa-var-minus); }
.fa-minus-circle:before { content: fa-content($fa-var-minus-circle); }
.fa-minus-square:before { content: fa-content($fa-var-minus-square); }
.fa-mitten:before { content: fa-content($fa-var-mitten); }
.fa-mix:before { content: fa-content($fa-var-mix); }
.fa-mixcloud:before { content: fa-content($fa-var-mixcloud); }
.fa-mixer:before { content: fa-content($fa-var-mixer); }
.fa-mizuni:before { content: fa-content($fa-var-mizuni); }
.fa-mobile:before { content: fa-content($fa-var-mobile); }
.fa-mobile-alt:before { content: fa-content($fa-var-mobile-alt); }
.fa-modx:before { content: fa-content($fa-var-modx); }
.fa-monero:before { content: fa-content($fa-var-monero); }
.fa-money-bill:before { content: fa-content($fa-var-money-bill); }
.fa-money-bill-alt:before { content: fa-content($fa-var-money-bill-alt); }
.fa-money-bill-wave:before { content: fa-content($fa-var-money-bill-wave); }
.fa-money-bill-wave-alt:before { content: fa-content($fa-var-money-bill-wave-alt); }
.fa-money-check:before { content: fa-content($fa-var-money-check); }
.fa-money-check-alt:before { content: fa-content($fa-var-money-check-alt); }
.fa-monument:before { content: fa-content($fa-var-monument); }
.fa-moon:before { content: fa-content($fa-var-moon); }
.fa-mortar-pestle:before { content: fa-content($fa-var-mortar-pestle); }
.fa-mosque:before { content: fa-content($fa-var-mosque); }
.fa-motorcycle:before { content: fa-content($fa-var-motorcycle); }
.fa-mountain:before { content: fa-content($fa-var-mountain); }
.fa-mouse:before { content: fa-content($fa-var-mouse); }
.fa-mouse-pointer:before { content: fa-content($fa-var-mouse-pointer); }
.fa-mug-hot:before { content: fa-content($fa-var-mug-hot); }
.fa-music:before { content: fa-content($fa-var-music); }
.fa-napster:before { content: fa-content($fa-var-napster); }
.fa-neos:before { content: fa-content($fa-var-neos); }
.fa-network-wired:before { content: fa-content($fa-var-network-wired); }
.fa-neuter:before { content: fa-content($fa-var-neuter); }
.fa-newspaper:before { content: fa-content($fa-var-newspaper); }
.fa-nimblr:before { content: fa-content($fa-var-nimblr); }
.fa-node:before { content: fa-content($fa-var-node); }
.fa-node-js:before { content: fa-content($fa-var-node-js); }
.fa-not-equal:before { content: fa-content($fa-var-not-equal); }
.fa-notes-medical:before { content: fa-content($fa-var-notes-medical); }
.fa-npm:before { content: fa-content($fa-var-npm); }
.fa-ns8:before { content: fa-content($fa-var-ns8); }
.fa-nutritionix:before { content: fa-content($fa-var-nutritionix); }
.fa-object-group:before { content: fa-content($fa-var-object-group); }
.fa-object-ungroup:before { content: fa-content($fa-var-object-ungroup); }
.fa-octopus-deploy:before { content: fa-content($fa-var-octopus-deploy); }
.fa-odnoklassniki:before { content: fa-content($fa-var-odnoklassniki); }
.fa-odnoklassniki-square:before { content: fa-content($fa-var-odnoklassniki-square); }
.fa-oil-can:before { content: fa-content($fa-var-oil-can); }
.fa-old-republic:before { content: fa-content($fa-var-old-republic); }
.fa-om:before { content: fa-content($fa-var-om); }
.fa-opencart:before { content: fa-content($fa-var-opencart); }
.fa-openid:before { content: fa-content($fa-var-openid); }
.fa-opera:before { content: fa-content($fa-var-opera); }
.fa-optin-monster:before { content: fa-content($fa-var-optin-monster); }
.fa-orcid:before { content: fa-content($fa-var-orcid); }
.fa-osi:before { content: fa-content($fa-var-osi); }
.fa-otter:before { content: fa-content($fa-var-otter); }
.fa-outdent:before { content: fa-content($fa-var-outdent); }
.fa-page4:before { content: fa-content($fa-var-page4); }
.fa-pagelines:before { content: fa-content($fa-var-pagelines); }
.fa-pager:before { content: fa-content($fa-var-pager); }
.fa-paint-brush:before { content: fa-content($fa-var-paint-brush); }
.fa-paint-roller:before { content: fa-content($fa-var-paint-roller); }
.fa-palette:before { content: fa-content($fa-var-palette); }
.fa-palfed:before { content: fa-content($fa-var-palfed); }
.fa-pallet:before { content: fa-content($fa-var-pallet); }
.fa-paper-plane:before { content: fa-content($fa-var-paper-plane); }
.fa-paperclip:before { content: fa-content($fa-var-paperclip); }
.fa-parachute-box:before { content: fa-content($fa-var-parachute-box); }
.fa-paragraph:before { content: fa-content($fa-var-paragraph); }
.fa-parking:before { content: fa-content($fa-var-parking); }
.fa-passport:before { content: fa-content($fa-var-passport); }
.fa-pastafarianism:before { content: fa-content($fa-var-pastafarianism); }
.fa-paste:before { content: fa-content($fa-var-paste); }
.fa-patreon:before { content: fa-content($fa-var-patreon); }
.fa-pause:before { content: fa-content($fa-var-pause); }
.fa-pause-circle:before { content: fa-content($fa-var-pause-circle); }
.fa-paw:before { content: fa-content($fa-var-paw); }
.fa-paypal:before { content: fa-content($fa-var-paypal); }
.fa-peace:before { content: fa-content($fa-var-peace); }
.fa-pen:before { content: fa-content($fa-var-pen); }
.fa-pen-alt:before { content: fa-content($fa-var-pen-alt); }
.fa-pen-fancy:before { content: fa-content($fa-var-pen-fancy); }
.fa-pen-nib:before { content: fa-content($fa-var-pen-nib); }
.fa-pen-square:before { content: fa-content($fa-var-pen-square); }
.fa-pencil-alt:before { content: fa-content($fa-var-pencil-alt); }
.fa-pencil-ruler:before { content: fa-content($fa-var-pencil-ruler); }
.fa-penny-arcade:before { content: fa-content($fa-var-penny-arcade); }
.fa-people-arrows:before { content: fa-content($fa-var-people-arrows); }
.fa-people-carry:before { content: fa-content($fa-var-people-carry); }
.fa-pepper-hot:before { content: fa-content($fa-var-pepper-hot); }
.fa-perbyte:before { content: fa-content($fa-var-perbyte); }
.fa-percent:before { content: fa-content($fa-var-percent); }
.fa-percentage:before { content: fa-content($fa-var-percentage); }
.fa-periscope:before { content: fa-content($fa-var-periscope); }
.fa-person-booth:before { content: fa-content($fa-var-person-booth); }
.fa-phabricator:before { content: fa-content($fa-var-phabricator); }
.fa-phoenix-framework:before { content: fa-content($fa-var-phoenix-framework); }
.fa-phoenix-squadron:before { content: fa-content($fa-var-phoenix-squadron); }
.fa-phone:before { content: fa-content($fa-var-phone); }
.fa-phone-alt:before { content: fa-content($fa-var-phone-alt); }
.fa-phone-slash:before { content: fa-content($fa-var-phone-slash); }
.fa-phone-square:before { content: fa-content($fa-var-phone-square); }
.fa-phone-square-alt:before { content: fa-content($fa-var-phone-square-alt); }
.fa-phone-volume:before { content: fa-content($fa-var-phone-volume); }
.fa-photo-video:before { content: fa-content($fa-var-photo-video); }
.fa-php:before { content: fa-content($fa-var-php); }
.fa-pied-piper:before { content: fa-content($fa-var-pied-piper); }
.fa-pied-piper-alt:before { content: fa-content($fa-var-pied-piper-alt); }
.fa-pied-piper-hat:before { content: fa-content($fa-var-pied-piper-hat); }
.fa-pied-piper-pp:before { content: fa-content($fa-var-pied-piper-pp); }
.fa-pied-piper-square:before { content: fa-content($fa-var-pied-piper-square); }
.fa-piggy-bank:before { content: fa-content($fa-var-piggy-bank); }
.fa-pills:before { content: fa-content($fa-var-pills); }
.fa-pinterest:before { content: fa-content($fa-var-pinterest); }
.fa-pinterest-p:before { content: fa-content($fa-var-pinterest-p); }
.fa-pinterest-square:before { content: fa-content($fa-var-pinterest-square); }
.fa-pizza-slice:before { content: fa-content($fa-var-pizza-slice); }
.fa-place-of-worship:before { content: fa-content($fa-var-place-of-worship); }
.fa-plane:before { content: fa-content($fa-var-plane); }
.fa-plane-arrival:before { content: fa-content($fa-var-plane-arrival); }
.fa-plane-departure:before { content: fa-content($fa-var-plane-departure); }
.fa-plane-slash:before { content: fa-content($fa-var-plane-slash); }
.fa-play:before { content: fa-content($fa-var-play); }
.fa-play-circle:before { content: fa-content($fa-var-play-circle); }
.fa-playstation:before { content: fa-content($fa-var-playstation); }
.fa-plug:before { content: fa-content($fa-var-plug); }
.fa-plus:before { content: fa-content($fa-var-plus); }
.fa-plus-circle:before { content: fa-content($fa-var-plus-circle); }
.fa-plus-square:before { content: fa-content($fa-var-plus-square); }
.fa-podcast:before { content: fa-content($fa-var-podcast); }
.fa-poll:before { content: fa-content($fa-var-poll); }
.fa-poll-h:before { content: fa-content($fa-var-poll-h); }
.fa-poo:before { content: fa-content($fa-var-poo); }
.fa-poo-storm:before { content: fa-content($fa-var-poo-storm); }
.fa-poop:before { content: fa-content($fa-var-poop); }
.fa-portrait:before { content: fa-content($fa-var-portrait); }
.fa-pound-sign:before { content: fa-content($fa-var-pound-sign); }
.fa-power-off:before { content: fa-content($fa-var-power-off); }
.fa-pray:before { content: fa-content($fa-var-pray); }
.fa-praying-hands:before { content: fa-content($fa-var-praying-hands); }
.fa-prescription:before { content: fa-content($fa-var-prescription); }
.fa-prescription-bottle:before { content: fa-content($fa-var-prescription-bottle); }
.fa-prescription-bottle-alt:before { content: fa-content($fa-var-prescription-bottle-alt); }
.fa-print:before { content: fa-content($fa-var-print); }
.fa-procedures:before { content: fa-content($fa-var-procedures); }
.fa-product-hunt:before { content: fa-content($fa-var-product-hunt); }
.fa-project-diagram:before { content: fa-content($fa-var-project-diagram); }
.fa-pump-medical:before { content: fa-content($fa-var-pump-medical); }
.fa-pump-soap:before { content: fa-content($fa-var-pump-soap); }
.fa-pushed:before { content: fa-content($fa-var-pushed); }
.fa-puzzle-piece:before { content: fa-content($fa-var-puzzle-piece); }
.fa-python:before { content: fa-content($fa-var-python); }
.fa-qq:before { content: fa-content($fa-var-qq); }
.fa-qrcode:before { content: fa-content($fa-var-qrcode); }
.fa-question:before { content: fa-content($fa-var-question); }
.fa-question-circle:before { content: fa-content($fa-var-question-circle); }
.fa-quidditch:before { content: fa-content($fa-var-quidditch); }
.fa-quinscape:before { content: fa-content($fa-var-quinscape); }
.fa-quora:before { content: fa-content($fa-var-quora); }
.fa-quote-left:before { content: fa-content($fa-var-quote-left); }
.fa-quote-right:before { content: fa-content($fa-var-quote-right); }
.fa-quran:before { content: fa-content($fa-var-quran); }
.fa-r-project:before { content: fa-content($fa-var-r-project); }
.fa-radiation:before { content: fa-content($fa-var-radiation); }
.fa-radiation-alt:before { content: fa-content($fa-var-radiation-alt); }
.fa-rainbow:before { content: fa-content($fa-var-rainbow); }
.fa-random:before { content: fa-content($fa-var-random); }
.fa-raspberry-pi:before { content: fa-content($fa-var-raspberry-pi); }
.fa-ravelry:before { content: fa-content($fa-var-ravelry); }
.fa-react:before { content: fa-content($fa-var-react); }
.fa-reacteurope:before { content: fa-content($fa-var-reacteurope); }
.fa-readme:before { content: fa-content($fa-var-readme); }
.fa-rebel:before { content: fa-content($fa-var-rebel); }
.fa-receipt:before { content: fa-content($fa-var-receipt); }
.fa-record-vinyl:before { content: fa-content($fa-var-record-vinyl); }
.fa-recycle:before { content: fa-content($fa-var-recycle); }
.fa-red-river:before { content: fa-content($fa-var-red-river); }
.fa-reddit:before { content: fa-content($fa-var-reddit); }
.fa-reddit-alien:before { content: fa-content($fa-var-reddit-alien); }
.fa-reddit-square:before { content: fa-content($fa-var-reddit-square); }
.fa-redhat:before { content: fa-content($fa-var-redhat); }
.fa-redo:before { content: fa-content($fa-var-redo); }
.fa-redo-alt:before { content: fa-content($fa-var-redo-alt); }
.fa-registered:before { content: fa-content($fa-var-registered); }
.fa-remove-format:before { content: fa-content($fa-var-remove-format); }
.fa-renren:before { content: fa-content($fa-var-renren); }
.fa-reply:before { content: fa-content($fa-var-reply); }
.fa-reply-all:before { content: fa-content($fa-var-reply-all); }
.fa-replyd:before { content: fa-content($fa-var-replyd); }
.fa-republican:before { content: fa-content($fa-var-republican); }
.fa-researchgate:before { content: fa-content($fa-var-researchgate); }
.fa-resolving:before { content: fa-content($fa-var-resolving); }
.fa-restroom:before { content: fa-content($fa-var-restroom); }
.fa-retweet:before { content: fa-content($fa-var-retweet); }
.fa-rev:before { content: fa-content($fa-var-rev); }
.fa-ribbon:before { content: fa-content($fa-var-ribbon); }
.fa-ring:before { content: fa-content($fa-var-ring); }
.fa-road:before { content: fa-content($fa-var-road); }
.fa-robot:before { content: fa-content($fa-var-robot); }
.fa-rocket:before { content: fa-content($fa-var-rocket); }
.fa-rocketchat:before { content: fa-content($fa-var-rocketchat); }
.fa-rockrms:before { content: fa-content($fa-var-rockrms); }
.fa-route:before { content: fa-content($fa-var-route); }
.fa-rss:before { content: fa-content($fa-var-rss); }
.fa-rss-square:before { content: fa-content($fa-var-rss-square); }
.fa-ruble-sign:before { content: fa-content($fa-var-ruble-sign); }
.fa-ruler:before { content: fa-content($fa-var-ruler); }
.fa-ruler-combined:before { content: fa-content($fa-var-ruler-combined); }
.fa-ruler-horizontal:before { content: fa-content($fa-var-ruler-horizontal); }
.fa-ruler-vertical:before { content: fa-content($fa-var-ruler-vertical); }
.fa-running:before { content: fa-content($fa-var-running); }
.fa-rupee-sign:before { content: fa-content($fa-var-rupee-sign); }
.fa-rust:before { content: fa-content($fa-var-rust); }
.fa-sad-cry:before { content: fa-content($fa-var-sad-cry); }
.fa-sad-tear:before { content: fa-content($fa-var-sad-tear); }
.fa-safari:before { content: fa-content($fa-var-safari); }
.fa-salesforce:before { content: fa-content($fa-var-salesforce); }
.fa-sass:before { content: fa-content($fa-var-sass); }
.fa-satellite:before { content: fa-content($fa-var-satellite); }
.fa-satellite-dish:before { content: fa-content($fa-var-satellite-dish); }
.fa-save:before { content: fa-content($fa-var-save); }
.fa-schlix:before { content: fa-content($fa-var-schlix); }
.fa-school:before { content: fa-content($fa-var-school); }
.fa-screwdriver:before { content: fa-content($fa-var-screwdriver); }
.fa-scribd:before { content: fa-content($fa-var-scribd); }
.fa-scroll:before { content: fa-content($fa-var-scroll); }
.fa-sd-card:before { content: fa-content($fa-var-sd-card); }
.fa-search:before { content: fa-content($fa-var-search); }
.fa-search-dollar:before { content: fa-content($fa-var-search-dollar); }
.fa-search-location:before { content: fa-content($fa-var-search-location); }
.fa-search-minus:before { content: fa-content($fa-var-search-minus); }
.fa-search-plus:before { content: fa-content($fa-var-search-plus); }
.fa-searchengin:before { content: fa-content($fa-var-searchengin); }
.fa-seedling:before { content: fa-content($fa-var-seedling); }
.fa-sellcast:before { content: fa-content($fa-var-sellcast); }
.fa-sellsy:before { content: fa-content($fa-var-sellsy); }
.fa-server:before { content: fa-content($fa-var-server); }
.fa-servicestack:before { content: fa-content($fa-var-servicestack); }
.fa-shapes:before { content: fa-content($fa-var-shapes); }
.fa-share:before { content: fa-content($fa-var-share); }
.fa-share-alt:before { content: fa-content($fa-var-share-alt); }
.fa-share-alt-square:before { content: fa-content($fa-var-share-alt-square); }
.fa-share-square:before { content: fa-content($fa-var-share-square); }
.fa-shekel-sign:before { content: fa-content($fa-var-shekel-sign); }
.fa-shield-alt:before { content: fa-content($fa-var-shield-alt); }
.fa-shield-virus:before { content: fa-content($fa-var-shield-virus); }
.fa-ship:before { content: fa-content($fa-var-ship); }
.fa-shipping-fast:before { content: fa-content($fa-var-shipping-fast); }
.fa-shirtsinbulk:before { content: fa-content($fa-var-shirtsinbulk); }
.fa-shoe-prints:before { content: fa-content($fa-var-shoe-prints); }
.fa-shopify:before { content: fa-content($fa-var-shopify); }
.fa-shopping-bag:before { content: fa-content($fa-var-shopping-bag); }
.fa-shopping-basket:before { content: fa-content($fa-var-shopping-basket); }
.fa-shopping-cart:before { content: fa-content($fa-var-shopping-cart); }
.fa-shopware:before { content: fa-content($fa-var-shopware); }
.fa-shower:before { content: fa-content($fa-var-shower); }
.fa-shuttle-van:before { content: fa-content($fa-var-shuttle-van); }
.fa-sign:before { content: fa-content($fa-var-sign); }
.fa-sign-in-alt:before { content: fa-content($fa-var-sign-in-alt); }
.fa-sign-language:before { content: fa-content($fa-var-sign-language); }
.fa-sign-out-alt:before { content: fa-content($fa-var-sign-out-alt); }
.fa-signal:before { content: fa-content($fa-var-signal); }
.fa-signature:before { content: fa-content($fa-var-signature); }
.fa-sim-card:before { content: fa-content($fa-var-sim-card); }
.fa-simplybuilt:before { content: fa-content($fa-var-simplybuilt); }
.fa-sink:before { content: fa-content($fa-var-sink); }
.fa-sistrix:before { content: fa-content($fa-var-sistrix); }
.fa-sitemap:before { content: fa-content($fa-var-sitemap); }
.fa-sith:before { content: fa-content($fa-var-sith); }
.fa-skating:before { content: fa-content($fa-var-skating); }
.fa-sketch:before { content: fa-content($fa-var-sketch); }
.fa-skiing:before { content: fa-content($fa-var-skiing); }
.fa-skiing-nordic:before { content: fa-content($fa-var-skiing-nordic); }
.fa-skull:before { content: fa-content($fa-var-skull); }
.fa-skull-crossbones:before { content: fa-content($fa-var-skull-crossbones); }
.fa-skyatlas:before { content: fa-content($fa-var-skyatlas); }
.fa-skype:before { content: fa-content($fa-var-skype); }
.fa-slack:before { content: fa-content($fa-var-slack); }
.fa-slack-hash:before { content: fa-content($fa-var-slack-hash); }
.fa-slash:before { content: fa-content($fa-var-slash); }
.fa-sleigh:before { content: fa-content($fa-var-sleigh); }
.fa-sliders-h:before { content: fa-content($fa-var-sliders-h); }
.fa-slideshare:before { content: fa-content($fa-var-slideshare); }
.fa-smile:before { content: fa-content($fa-var-smile); }
.fa-smile-beam:before { content: fa-content($fa-var-smile-beam); }
.fa-smile-wink:before { content: fa-content($fa-var-smile-wink); }
.fa-smog:before { content: fa-content($fa-var-smog); }
.fa-smoking:before { content: fa-content($fa-var-smoking); }
.fa-smoking-ban:before { content: fa-content($fa-var-smoking-ban); }
.fa-sms:before { content: fa-content($fa-var-sms); }
.fa-snapchat:before { content: fa-content($fa-var-snapchat); }
.fa-snapchat-ghost:before { content: fa-content($fa-var-snapchat-ghost); }
.fa-snapchat-square:before { content: fa-content($fa-var-snapchat-square); }
.fa-snowboarding:before { content: fa-content($fa-var-snowboarding); }
.fa-snowflake:before { content: fa-content($fa-var-snowflake); }
.fa-snowman:before { content: fa-content($fa-var-snowman); }
.fa-snowplow:before { content: fa-content($fa-var-snowplow); }
.fa-soap:before { content: fa-content($fa-var-soap); }
.fa-socks:before { content: fa-content($fa-var-socks); }
.fa-solar-panel:before { content: fa-content($fa-var-solar-panel); }
.fa-sort:before { content: fa-content($fa-var-sort); }
.fa-sort-alpha-down:before { content: fa-content($fa-var-sort-alpha-down); }
.fa-sort-alpha-down-alt:before { content: fa-content($fa-var-sort-alpha-down-alt); }
.fa-sort-alpha-up:before { content: fa-content($fa-var-sort-alpha-up); }
.fa-sort-alpha-up-alt:before { content: fa-content($fa-var-sort-alpha-up-alt); }
.fa-sort-amount-down:before { content: fa-content($fa-var-sort-amount-down); }
.fa-sort-amount-down-alt:before { content: fa-content($fa-var-sort-amount-down-alt); }
.fa-sort-amount-up:before { content: fa-content($fa-var-sort-amount-up); }
.fa-sort-amount-up-alt:before { content: fa-content($fa-var-sort-amount-up-alt); }
.fa-sort-down:before { content: fa-content($fa-var-sort-down); }
.fa-sort-numeric-down:before { content: fa-content($fa-var-sort-numeric-down); }
.fa-sort-numeric-down-alt:before { content: fa-content($fa-var-sort-numeric-down-alt); }
.fa-sort-numeric-up:before { content: fa-content($fa-var-sort-numeric-up); }
.fa-sort-numeric-up-alt:before { content: fa-content($fa-var-sort-numeric-up-alt); }
.fa-sort-up:before { content: fa-content($fa-var-sort-up); }
.fa-soundcloud:before { content: fa-content($fa-var-soundcloud); }
.fa-sourcetree:before { content: fa-content($fa-var-sourcetree); }
.fa-spa:before { content: fa-content($fa-var-spa); }
.fa-space-shuttle:before { content: fa-content($fa-var-space-shuttle); }
.fa-speakap:before { content: fa-content($fa-var-speakap); }
.fa-speaker-deck:before { content: fa-content($fa-var-speaker-deck); }
.fa-spell-check:before { content: fa-content($fa-var-spell-check); }
.fa-spider:before { content: fa-content($fa-var-spider); }
.fa-spinner:before { content: fa-content($fa-var-spinner); }
.fa-splotch:before { content: fa-content($fa-var-splotch); }
.fa-spotify:before { content: fa-content($fa-var-spotify); }
.fa-spray-can:before { content: fa-content($fa-var-spray-can); }
.fa-square:before { content: fa-content($fa-var-square); }
.fa-square-full:before { content: fa-content($fa-var-square-full); }
.fa-square-root-alt:before { content: fa-content($fa-var-square-root-alt); }
.fa-squarespace:before { content: fa-content($fa-var-squarespace); }
.fa-stack-exchange:before { content: fa-content($fa-var-stack-exchange); }
.fa-stack-overflow:before { content: fa-content($fa-var-stack-overflow); }
.fa-stackpath:before { content: fa-content($fa-var-stackpath); }
.fa-stamp:before { content: fa-content($fa-var-stamp); }
.fa-star:before { content: fa-content($fa-var-star); }
.fa-star-and-crescent:before { content: fa-content($fa-var-star-and-crescent); }
.fa-star-half:before { content: fa-content($fa-var-star-half); }
.fa-star-half-alt:before { content: fa-content($fa-var-star-half-alt); }
.fa-star-of-david:before { content: fa-content($fa-var-star-of-david); }
.fa-star-of-life:before { content: fa-content($fa-var-star-of-life); }
.fa-staylinked:before { content: fa-content($fa-var-staylinked); }
.fa-steam:before { content: fa-content($fa-var-steam); }
.fa-steam-square:before { content: fa-content($fa-var-steam-square); }
.fa-steam-symbol:before { content: fa-content($fa-var-steam-symbol); }
.fa-step-backward:before { content: fa-content($fa-var-step-backward); }
.fa-step-forward:before { content: fa-content($fa-var-step-forward); }
.fa-stethoscope:before { content: fa-content($fa-var-stethoscope); }
.fa-sticker-mule:before { content: fa-content($fa-var-sticker-mule); }
.fa-sticky-note:before { content: fa-content($fa-var-sticky-note); }
.fa-stop:before { content: fa-content($fa-var-stop); }
.fa-stop-circle:before { content: fa-content($fa-var-stop-circle); }
.fa-stopwatch:before { content: fa-content($fa-var-stopwatch); }
.fa-stopwatch-20:before { content: fa-content($fa-var-stopwatch-20); }
.fa-store:before { content: fa-content($fa-var-store); }
.fa-store-alt:before { content: fa-content($fa-var-store-alt); }
.fa-store-alt-slash:before { content: fa-content($fa-var-store-alt-slash); }
.fa-store-slash:before { content: fa-content($fa-var-store-slash); }
.fa-strava:before { content: fa-content($fa-var-strava); }
.fa-stream:before { content: fa-content($fa-var-stream); }
.fa-street-view:before { content: fa-content($fa-var-street-view); }
.fa-strikethrough:before { content: fa-content($fa-var-strikethrough); }
.fa-stripe:before { content: fa-content($fa-var-stripe); }
.fa-stripe-s:before { content: fa-content($fa-var-stripe-s); }
.fa-stroopwafel:before { content: fa-content($fa-var-stroopwafel); }
.fa-studiovinari:before { content: fa-content($fa-var-studiovinari); }
.fa-stumbleupon:before { content: fa-content($fa-var-stumbleupon); }
.fa-stumbleupon-circle:before { content: fa-content($fa-var-stumbleupon-circle); }
.fa-subscript:before { content: fa-content($fa-var-subscript); }
.fa-subway:before { content: fa-content($fa-var-subway); }
.fa-suitcase:before { content: fa-content($fa-var-suitcase); }
.fa-suitcase-rolling:before { content: fa-content($fa-var-suitcase-rolling); }
.fa-sun:before { content: fa-content($fa-var-sun); }
.fa-superpowers:before { content: fa-content($fa-var-superpowers); }
.fa-superscript:before { content: fa-content($fa-var-superscript); }
.fa-supple:before { content: fa-content($fa-var-supple); }
.fa-surprise:before { content: fa-content($fa-var-surprise); }
.fa-suse:before { content: fa-content($fa-var-suse); }
.fa-swatchbook:before { content: fa-content($fa-var-swatchbook); }
.fa-swift:before { content: fa-content($fa-var-swift); }
.fa-swimmer:before { content: fa-content($fa-var-swimmer); }
.fa-swimming-pool:before { content: fa-content($fa-var-swimming-pool); }
.fa-symfony:before { content: fa-content($fa-var-symfony); }
.fa-synagogue:before { content: fa-content($fa-var-synagogue); }
.fa-sync:before { content: fa-content($fa-var-sync); }
.fa-sync-alt:before { content: fa-content($fa-var-sync-alt); }
.fa-syringe:before { content: fa-content($fa-var-syringe); }
.fa-table:before { content: fa-content($fa-var-table); }
.fa-table-tennis:before { content: fa-content($fa-var-table-tennis); }
.fa-tablet:before { content: fa-content($fa-var-tablet); }
.fa-tablet-alt:before { content: fa-content($fa-var-tablet-alt); }
.fa-tablets:before { content: fa-content($fa-var-tablets); }
.fa-tachometer-alt:before { content: fa-content($fa-var-tachometer-alt); }
.fa-tag:before { content: fa-content($fa-var-tag); }
.fa-tags:before { content: fa-content($fa-var-tags); }
.fa-tape:before { content: fa-content($fa-var-tape); }
.fa-tasks:before { content: fa-content($fa-var-tasks); }
.fa-taxi:before { content: fa-content($fa-var-taxi); }
.fa-teamspeak:before { content: fa-content($fa-var-teamspeak); }
.fa-teeth:before { content: fa-content($fa-var-teeth); }
.fa-teeth-open:before { content: fa-content($fa-var-teeth-open); }
.fa-telegram:before { content: fa-content($fa-var-telegram); }
.fa-telegram-plane:before { content: fa-content($fa-var-telegram-plane); }
.fa-temperature-high:before { content: fa-content($fa-var-temperature-high); }
.fa-temperature-low:before { content: fa-content($fa-var-temperature-low); }
.fa-tencent-weibo:before { content: fa-content($fa-var-tencent-weibo); }
.fa-tenge:before { content: fa-content($fa-var-tenge); }
.fa-terminal:before { content: fa-content($fa-var-terminal); }
.fa-text-height:before { content: fa-content($fa-var-text-height); }
.fa-text-width:before { content: fa-content($fa-var-text-width); }
.fa-th:before { content: fa-content($fa-var-th); }
.fa-th-large:before { content: fa-content($fa-var-th-large); }
.fa-th-list:before { content: fa-content($fa-var-th-list); }
.fa-the-red-yeti:before { content: fa-content($fa-var-the-red-yeti); }
.fa-theater-masks:before { content: fa-content($fa-var-theater-masks); }
.fa-themeco:before { content: fa-content($fa-var-themeco); }
.fa-themeisle:before { content: fa-content($fa-var-themeisle); }
.fa-thermometer:before { content: fa-content($fa-var-thermometer); }
.fa-thermometer-empty:before { content: fa-content($fa-var-thermometer-empty); }
.fa-thermometer-full:before { content: fa-content($fa-var-thermometer-full); }
.fa-thermometer-half:before { content: fa-content($fa-var-thermometer-half); }
.fa-thermometer-quarter:before { content: fa-content($fa-var-thermometer-quarter); }
.fa-thermometer-three-quarters:before { content: fa-content($fa-var-thermometer-three-quarters); }
.fa-think-peaks:before { content: fa-content($fa-var-think-peaks); }
.fa-thumbs-down:before { content: fa-content($fa-var-thumbs-down); }
.fa-thumbs-up:before { content: fa-content($fa-var-thumbs-up); }
.fa-thumbtack:before { content: fa-content($fa-var-thumbtack); }
.fa-ticket-alt:before { content: fa-content($fa-var-ticket-alt); }
.fa-tiktok:before { content: fa-content($fa-var-tiktok); }
.fa-times:before { content: fa-content($fa-var-times); }
.fa-times-circle:before { content: fa-content($fa-var-times-circle); }
.fa-tint:before { content: fa-content($fa-var-tint); }
.fa-tint-slash:before { content: fa-content($fa-var-tint-slash); }
.fa-tired:before { content: fa-content($fa-var-tired); }
.fa-toggle-off:before { content: fa-content($fa-var-toggle-off); }
.fa-toggle-on:before { content: fa-content($fa-var-toggle-on); }
.fa-toilet:before { content: fa-content($fa-var-toilet); }
.fa-toilet-paper:before { content: fa-content($fa-var-toilet-paper); }
.fa-toilet-paper-slash:before { content: fa-content($fa-var-toilet-paper-slash); }
.fa-toolbox:before { content: fa-content($fa-var-toolbox); }
.fa-tools:before { content: fa-content($fa-var-tools); }
.fa-tooth:before { content: fa-content($fa-var-tooth); }
.fa-torah:before { content: fa-content($fa-var-torah); }
.fa-torii-gate:before { content: fa-content($fa-var-torii-gate); }
.fa-tractor:before { content: fa-content($fa-var-tractor); }
.fa-trade-federation:before { content: fa-content($fa-var-trade-federation); }
.fa-trademark:before { content: fa-content($fa-var-trademark); }
.fa-traffic-light:before { content: fa-content($fa-var-traffic-light); }
.fa-trailer:before { content: fa-content($fa-var-trailer); }
.fa-train:before { content: fa-content($fa-var-train); }
.fa-tram:before { content: fa-content($fa-var-tram); }
.fa-transgender:before { content: fa-content($fa-var-transgender); }
.fa-transgender-alt:before { content: fa-content($fa-var-transgender-alt); }
.fa-trash:before { content: fa-content($fa-var-trash); }
.fa-trash-alt:before { content: fa-content($fa-var-trash-alt); }
.fa-trash-restore:before { content: fa-content($fa-var-trash-restore); }
.fa-trash-restore-alt:before { content: fa-content($fa-var-trash-restore-alt); }
.fa-tree:before { content: fa-content($fa-var-tree); }
.fa-trello:before { content: fa-content($fa-var-trello); }
.fa-tripadvisor:before { content: fa-content($fa-var-tripadvisor); }
.fa-trophy:before { content: fa-content($fa-var-trophy); }
.fa-truck:before { content: fa-content($fa-var-truck); }
.fa-truck-loading:before { content: fa-content($fa-var-truck-loading); }
.fa-truck-monster:before { content: fa-content($fa-var-truck-monster); }
.fa-truck-moving:before { content: fa-content($fa-var-truck-moving); }
.fa-truck-pickup:before { content: fa-content($fa-var-truck-pickup); }
.fa-tshirt:before { content: fa-content($fa-var-tshirt); }
.fa-tty:before { content: fa-content($fa-var-tty); }
.fa-tumblr:before { content: fa-content($fa-var-tumblr); }
.fa-tumblr-square:before { content: fa-content($fa-var-tumblr-square); }
.fa-tv:before { content: fa-content($fa-var-tv); }
.fa-twitch:before { content: fa-content($fa-var-twitch); }
.fa-twitter:before { content: fa-content($fa-var-twitter); }
.fa-twitter-square:before { content: fa-content($fa-var-twitter-square); }
.fa-typo3:before { content: fa-content($fa-var-typo3); }
.fa-uber:before { content: fa-content($fa-var-uber); }
.fa-ubuntu:before { content: fa-content($fa-var-ubuntu); }
.fa-uikit:before { content: fa-content($fa-var-uikit); }
.fa-umbraco:before { content: fa-content($fa-var-umbraco); }
.fa-umbrella:before { content: fa-content($fa-var-umbrella); }
.fa-umbrella-beach:before { content: fa-content($fa-var-umbrella-beach); }
.fa-uncharted:before { content: fa-content($fa-var-uncharted); }
.fa-underline:before { content: fa-content($fa-var-underline); }
.fa-undo:before { content: fa-content($fa-var-undo); }
.fa-undo-alt:before { content: fa-content($fa-var-undo-alt); }
.fa-uniregistry:before { content: fa-content($fa-var-uniregistry); }
.fa-unity:before { content: fa-content($fa-var-unity); }
.fa-universal-access:before { content: fa-content($fa-var-universal-access); }
.fa-university:before { content: fa-content($fa-var-university); }
.fa-unlink:before { content: fa-content($fa-var-unlink); }
.fa-unlock:before { content: fa-content($fa-var-unlock); }
.fa-unlock-alt:before { content: fa-content($fa-var-unlock-alt); }
.fa-unsplash:before { content: fa-content($fa-var-unsplash); }
.fa-untappd:before { content: fa-content($fa-var-untappd); }
.fa-upload:before { content: fa-content($fa-var-upload); }
.fa-ups:before { content: fa-content($fa-var-ups); }
.fa-usb:before { content: fa-content($fa-var-usb); }
.fa-user:before { content: fa-content($fa-var-user); }
.fa-user-alt:before { content: fa-content($fa-var-user-alt); }
.fa-user-alt-slash:before { content: fa-content($fa-var-user-alt-slash); }
.fa-user-astronaut:before { content: fa-content($fa-var-user-astronaut); }
.fa-user-check:before { content: fa-content($fa-var-user-check); }
.fa-user-circle:before { content: fa-content($fa-var-user-circle); }
.fa-user-clock:before { content: fa-content($fa-var-user-clock); }
.fa-user-cog:before { content: fa-content($fa-var-user-cog); }
.fa-user-edit:before { content: fa-content($fa-var-user-edit); }
.fa-user-friends:before { content: fa-content($fa-var-user-friends); }
.fa-user-graduate:before { content: fa-content($fa-var-user-graduate); }
.fa-user-injured:before { content: fa-content($fa-var-user-injured); }
.fa-user-lock:before { content: fa-content($fa-var-user-lock); }
.fa-user-md:before { content: fa-content($fa-var-user-md); }
.fa-user-minus:before { content: fa-content($fa-var-user-minus); }
.fa-user-ninja:before { content: fa-content($fa-var-user-ninja); }
.fa-user-nurse:before { content: fa-content($fa-var-user-nurse); }
.fa-user-plus:before { content: fa-content($fa-var-user-plus); }
.fa-user-secret:before { content: fa-content($fa-var-user-secret); }
.fa-user-shield:before { content: fa-content($fa-var-user-shield); }
.fa-user-slash:before { content: fa-content($fa-var-user-slash); }
.fa-user-tag:before { content: fa-content($fa-var-user-tag); }
.fa-user-tie:before { content: fa-content($fa-var-user-tie); }
.fa-user-times:before { content: fa-content($fa-var-user-times); }
.fa-users:before { content: fa-content($fa-var-users); }
.fa-users-cog:before { content: fa-content($fa-var-users-cog); }
.fa-users-slash:before { content: fa-content($fa-var-users-slash); }
.fa-usps:before { content: fa-content($fa-var-usps); }
.fa-ussunnah:before { content: fa-content($fa-var-ussunnah); }
.fa-utensil-spoon:before { content: fa-content($fa-var-utensil-spoon); }
.fa-utensils:before { content: fa-content($fa-var-utensils); }
.fa-vaadin:before { content: fa-content($fa-var-vaadin); }
.fa-vector-square:before { content: fa-content($fa-var-vector-square); }
.fa-venus:before { content: fa-content($fa-var-venus); }
.fa-venus-double:before { content: fa-content($fa-var-venus-double); }
.fa-venus-mars:before { content: fa-content($fa-var-venus-mars); }
.fa-vest:before { content: fa-content($fa-var-vest); }
.fa-vest-patches:before { content: fa-content($fa-var-vest-patches); }
.fa-viacoin:before { content: fa-content($fa-var-viacoin); }
.fa-viadeo:before { content: fa-content($fa-var-viadeo); }
.fa-viadeo-square:before { content: fa-content($fa-var-viadeo-square); }
.fa-vial:before { content: fa-content($fa-var-vial); }
.fa-vials:before { content: fa-content($fa-var-vials); }
.fa-viber:before { content: fa-content($fa-var-viber); }
.fa-video:before { content: fa-content($fa-var-video); }
.fa-video-slash:before { content: fa-content($fa-var-video-slash); }
.fa-vihara:before { content: fa-content($fa-var-vihara); }
.fa-vimeo:before { content: fa-content($fa-var-vimeo); }
.fa-vimeo-square:before { content: fa-content($fa-var-vimeo-square); }
.fa-vimeo-v:before { content: fa-content($fa-var-vimeo-v); }
.fa-vine:before { content: fa-content($fa-var-vine); }
.fa-virus:before { content: fa-content($fa-var-virus); }
.fa-virus-slash:before { content: fa-content($fa-var-virus-slash); }
.fa-viruses:before { content: fa-content($fa-var-viruses); }
.fa-vk:before { content: fa-content($fa-var-vk); }
.fa-vnv:before { content: fa-content($fa-var-vnv); }
.fa-voicemail:before { content: fa-content($fa-var-voicemail); }
.fa-volleyball-ball:before { content: fa-content($fa-var-volleyball-ball); }
.fa-volume-down:before { content: fa-content($fa-var-volume-down); }
.fa-volume-mute:before { content: fa-content($fa-var-volume-mute); }
.fa-volume-off:before { content: fa-content($fa-var-volume-off); }
.fa-volume-up:before { content: fa-content($fa-var-volume-up); }
.fa-vote-yea:before { content: fa-content($fa-var-vote-yea); }
.fa-vr-cardboard:before { content: fa-content($fa-var-vr-cardboard); }
.fa-vuejs:before { content: fa-content($fa-var-vuejs); }
.fa-walking:before { content: fa-content($fa-var-walking); }
.fa-wallet:before { content: fa-content($fa-var-wallet); }
.fa-warehouse:before { content: fa-content($fa-var-warehouse); }
.fa-watchman-monitoring:before { content: fa-content($fa-var-watchman-monitoring); }
.fa-water:before { content: fa-content($fa-var-water); }
.fa-wave-square:before { content: fa-content($fa-var-wave-square); }
.fa-waze:before { content: fa-content($fa-var-waze); }
.fa-weebly:before { content: fa-content($fa-var-weebly); }
.fa-weibo:before { content: fa-content($fa-var-weibo); }
.fa-weight:before { content: fa-content($fa-var-weight); }
.fa-weight-hanging:before { content: fa-content($fa-var-weight-hanging); }
.fa-weixin:before { content: fa-content($fa-var-weixin); }
.fa-whatsapp:before { content: fa-content($fa-var-whatsapp); }
.fa-whatsapp-square:before { content: fa-content($fa-var-whatsapp-square); }
.fa-wheelchair:before { content: fa-content($fa-var-wheelchair); }
.fa-whmcs:before { content: fa-content($fa-var-whmcs); }
.fa-wifi:before { content: fa-content($fa-var-wifi); }
.fa-wikipedia-w:before { content: fa-content($fa-var-wikipedia-w); }
.fa-wind:before { content: fa-content($fa-var-wind); }
.fa-window-close:before { content: fa-content($fa-var-window-close); }
.fa-window-maximize:before { content: fa-content($fa-var-window-maximize); }
.fa-window-minimize:before { content: fa-content($fa-var-window-minimize); }
.fa-window-restore:before { content: fa-content($fa-var-window-restore); }
.fa-windows:before { content: fa-content($fa-var-windows); }
.fa-wine-bottle:before { content: fa-content($fa-var-wine-bottle); }
.fa-wine-glass:before { content: fa-content($fa-var-wine-glass); }
.fa-wine-glass-alt:before { content: fa-content($fa-var-wine-glass-alt); }
.fa-wix:before { content: fa-content($fa-var-wix); }
.fa-wizards-of-the-coast:before { content: fa-content($fa-var-wizards-of-the-coast); }
.fa-wodu:before { content: fa-content($fa-var-wodu); }
.fa-wolf-pack-battalion:before { content: fa-content($fa-var-wolf-pack-battalion); }
.fa-won-sign:before { content: fa-content($fa-var-won-sign); }
.fa-wordpress:before { content: fa-content($fa-var-wordpress); }
.fa-wordpress-simple:before { content: fa-content($fa-var-wordpress-simple); }
.fa-wpbeginner:before { content: fa-content($fa-var-wpbeginner); }
.fa-wpexplorer:before { content: fa-content($fa-var-wpexplorer); }
.fa-wpforms:before { content: fa-content($fa-var-wpforms); }
.fa-wpressr:before { content: fa-content($fa-var-wpressr); }
.fa-wrench:before { content: fa-content($fa-var-wrench); }
.fa-x-ray:before { content: fa-content($fa-var-x-ray); }
.fa-xbox:before { content: fa-content($fa-var-xbox); }
.fa-xing:before { content: fa-content($fa-var-xing); }
.fa-xing-square:before { content: fa-content($fa-var-xing-square); }
.fa-y-combinator:before { content: fa-content($fa-var-y-combinator); }
.fa-yahoo:before { content: fa-content($fa-var-yahoo); }
.fa-yammer:before { content: fa-content($fa-var-yammer); }
.fa-yandex:before { content: fa-content($fa-var-yandex); }
.fa-yandex-international:before { content: fa-content($fa-var-yandex-international); }
.fa-yarn:before { content: fa-content($fa-var-yarn); }
.fa-yelp:before { content: fa-content($fa-var-yelp); }
.fa-yen-sign:before { content: fa-content($fa-var-yen-sign); }
.fa-yin-yang:before { content: fa-content($fa-var-yin-yang); }
.fa-yoast:before { content: fa-content($fa-var-yoast); }
.fa-youtube:before { content: fa-content($fa-var-youtube); }
.fa-youtube-square:before { content: fa-content($fa-var-youtube-square); }
.fa-zhihu:before { content: fa-content($fa-var-zhihu); }
