@import "../variables";

form {
  input[type="file"] {
    display: none;
  }
  .upload-btn {
    border: 1.4px solid $black;
    color: $black;
    border-radius: 20px;
    background-color: transparent;
    padding: 5px 20px;
    display: inline-block;
    cursor: pointer;
    font-weight: bold;
  }
}

.form-search-container {
  margin: 10px 0 20px 0;
}

.form-control {
  height: 40px;
  border-radius: 5px;
  border-color: $gray2;
  color: $black;
  background-color: $white2;
  &::placeholder {
    color: $gray4;
  }
  &:focus {
    border-color: $blue;
    box-shadow: 0px 0.5px 10px rgba(0, 0, 0, 0.05);
    color: $black;
    background-color: $white2;
  }
  &:disabled {
    background-color: $gray2;
  }
  &.form-control.is-invalid {
    background-image: none;
  }
}

input {
  box-shadow: 0px 0.5px 10px rgba(0, 0, 0, 0.05);
}
