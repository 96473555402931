// Here you can add other styles
.width-full {
  width: 100%;
}

.flex-1 {
  flex: 1 !important;
}

.flex-2 {
  flex: 2 !important;
}

.margin-left-15px {
  margin-left: 15px;
}

.padding-left-15px {
  padding-left: 15px;
}
