.ReactModal__Overlay {
  overflow: scroll;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 70px;
  transition: margin-left .25s;
  background-color: rgba(44, 44, 44, 0.35) !important;
  z-index: 2;
}
.ReactModal__Content {
  inset: unset !important;
  padding: 0 !important;
  border: 0 !important;
  background: unset !important;
  height: inherit;
}
body {
  .ReactModal__Overlay {
    margin-left: 0px;
  }
}
body.sidebar-lg-show {
  .ReactModal__Overlay {
    margin-left: 200px;
  }
}