@import "../../scss/variables";

.awselect-container {
  box-shadow: 0px 0.5px 10px rgba(0, 0, 0, 0.05);
  select {
    appearance: none;
    -moz-appearance: none;
    -moz-appearance: none;
    &::after {
      border-radius: 5px;
    }
    &::-ms-expand {
      display: none;
    }
    option {
      background-color: $white2;
      color: $black;

      &:active {
        background-color: $light_blue;
        color: $medium_blue;
      }
    }
  }
  .awselect-carret {
    position: relative;
    float: right;
    top: -30px;
    right: 12px;
    pointer-events: none;
  }

  .awselect-carret-hide {
    position: relative;
    float: right;
    top: -30px;
    right: 12px;
    pointer-events: none;
    display: none;
  }

}

.awselect-carret-hide {
  position: relative;
  float: right;
  top: -30px;
  right: 12px;
  pointer-events: none;
  display: none;
}

.custom-select-container {
  display: inline-block;
  text-align: center;
  position: relative;
  cursor: pointer;

  &.form-control {
    padding: 0;
  }

  .selected-text {
    width: 100%;
    height: 100%;
    padding: 0.5rem 0.75rem 0.375rem 0.75rem;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: bold;
    &.placeholder {
      color: $gray4;
      font-weight: normal;
    }
  }

  ul {
    margin: 0;
    padding: 0;
    text-align: left;
    border-top: 1px solid $gray2;
  }

  .select-options {
    position: absolute;
    width: 100%;
    z-index: 1000;
    top: 38px;
    left: 0;
    height: 165px;
    overflow: auto;
  }

  li {
    list-style-type: none;
    padding: 6px 20px;
    background: $white2;
    border-left: 1px solid $gray2;
    border-right: 1px solid $gray2;
    cursor: pointer;
    &:last-child {
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      border-bottom: 1px solid $gray2;
    }
  }

  li:hover {
    background-color: $light-blue;
    color: $medium-blue;
  }
}

.custom-select-container.disabled {
  background-color: #ededed;
  cursor: not-allowed;
}
