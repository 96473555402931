@import '../variables';

.flat-btn {
  background-color: $black;
  border-color: $black;
  color: $white;
  padding: 5px;
  border-radius: 22px;
  font-size: 15px;
  font-weight: bold;
  i {
    vertical-align: middle;
  }
  &:hover {
    color: $white;
    background-color: $gray;
  }
}

.outline-btn {
  border-color: $black;
  color: $black;
  border-radius: 20px;
  background-color: transparent;
  padding: 5px;
  font-weight: bold;
  svg {
    margin-bottom: 2px;
  }
}

.reset-btn {
  border-color: transparent;
  background-color: transparent;
  font-weight: bold;
  margin-right: 10px;
  &:hover {
    border-color: transparent;
    background-color: transparent;
    text-decoration: underline;
  }
  &:focus {
    box-shadow: none !important;
    border: transparent !important;
  }
  &:active {
    background-color: transparent !important;
    border: transparent !important;
  }
}

.search-btn {
  background-color: $black;
  border-color: $black;
  color: $white;
  padding: 10px 60px;
  border-radius: 22px;
  font-size: 15px;
  font-weight: bold;
  i {
    vertical-align: middle;
  }
  &:hover {
    color: $white;
    background-color: $gray;
  }
}

.back-btn {
  border-color: $black;
  color: $black;
  border-radius: 20px;
  background-color: transparent;
  padding: 5px 15px;
  font-weight: bold;
  svg {
    margin-bottom: 2px;
  }
}

.select-file-btn {
  background-color: $black;
  border-color: $black;
  color: $white;
  border-radius: 20px;
  padding: 5px 15px;
  font-weight: bold;
  i {
    vertical-align: middle;
  }
  &:hover {
    color: $white;
    background-color: $gray;
  }
}

.edit-btn {
  background-color: $black;
  color: $white;
  border-radius: 20px;
  padding: 5px 20px;
  font-weight: bold;
  i {
    font-size: 18px;
    vertical-align: text-bottom;
  }
}

.submit-btn {
  background-color: $black;
  border-color: $black;
  color: $white;
  border-radius: 20px;
  padding: 5px 20px;
  font-weight: bold;
  i {
    vertical-align: sub;
    font-size: 18px;
  }
}

.delete-btn {
  background-color: $white;
  border-color: $red;
  color: $red;
  border-radius: 20px;
  padding: 5px 20px;
  font-weight: bold;
  i {
    vertical-align: sub;
    font-size: 18px;
  }
}

.submit-white-btn {
  background-color: $white2;
  border-color: $white2;
  color: $black;
  border-radius: 20px;
  padding: 5px 20px;
  font-weight: bold;
  i {
    vertical-align: sub;
    font-size: 18px;
  }
}

.submit-rounded-white-btn {
  background-color: $black;
  border-color: $white2;
  color: $white2;
  border-radius: 20px;
  padding: 5px 20px;
  font-weight: bold;
  i {
    vertical-align: sub;
    font-size: 18px;
  }
}

.pen-btn {
  height: 28px;
  width: 28px;
  background-color: $yellow;
  color: $white;
  border: none;
  margin-left: 5px;
  font-size: 22px;
  padding: 3px 4px;
  &:hover {
    color: $white;
    background-color: $yellow2;
  }
}

.trash-btn {
  height: 28px;
  width: 28px;
  background-color: $red;
  color: $white;
  border: none;
  margin-left: 5px;
  font-size: 20px;
  padding: 1px 4.5px;
  &:hover {
    color: $white;
    background-color: $red2;
  }
}

.eye-btn {
  height: 28px;
  width: 28px;
  background-color: $medium-blue;
  color: $white;
  border: none;
  margin-left: 5px;
  font-size: 20px;
  padding: 4px;
  &:hover {
    color: $white;
  }
}

.clone-btn {
  height: 28px;
  width: 28px;
  background-color: $green;
  color: $white;
  border: none;
  margin-left: 5px;
  font-size: 16px;
  padding: 0;
  &:hover {
    color: $white;
    background-color: $green;
  }
}

.download-btn {
  height: 28px;
  width: 28px;
  background-color: $blue;
  color: $white;
  border: none;
  margin-left: 5px;
  font-size: 22px;
  padding: 0;
  &:hover {
    color: $white;
    background-color: $medium-blue;
  }
}

.send-btn {
  height: 28px;
  width: 28px;
  background-color: $black;
  color: $white;
  border: none;
  margin-left: 5px;
  font-size: 20px;
  padding: 0;
  &:hover {
    color: $white;
  }
}
