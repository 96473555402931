// Variable overrides
//font 
// @font-face {
//   font-family: 'SukhumvitSet';
//   src: url('/font/SukhumvitSet.ttf');
//   font-weight: normal;
//   font-style: normal;
// }

// @font-face {
//   font-family: 'SukhumvitSet';
//   src: url('/font/SukhumvitSet-Bold.ttf');
//   font-weight: bold;
//   font-style: normal;
// }

@font-face {
  font-family: 'Thonburi';
  src: url('/font/Thonburi.ttf');
  font-weight: normal;
  font-style: normal;
}

// $font-family-sukhumvitset: 'SukhumvitSet';
$font-family-thonburi: 'Thonburi';

// color
$white: #fafafa;
$white2: #ffffff;
$pink: #f7168f;
$black: #2c2c2c;
$black2: #000;
$black3: #2a2a2a;
$gray: #707070;
$gray2: #e6e6e6;
$gray2p5: #bcbcbc;
$gray3: #f6f6f6;
$gray4: #bcbcbc;
$gray5: #ECEBED;
$gray6: #bdc2be;
$gray7: #d0d6d2;
$gray8: #929497;
$gray_inactive: #c8ced3;
$blue: #537CE5;
$light_blue: #f1f2ff;
$medium_blue: #38539a;
$red: #ed174c;
$red2: #CC1441;
$light_red:  #FFEEEE;
$dark_red:  #C8212A;
$green: #1e9aa6;
$green2: #27ae60;
$coral: #ea6766;
$yellow: #F2C94C;
$yellow2: #CCA940;

//background
$bgcolor: #eee;

//export to js
:export {
  white: $white;
  white2: $white2;
  pink: $pink;
  black: $black;
  black2: $black2;
  black3: $black3;
  gray: $gray;
  gray2: $gray2;
  gray2p5: $gray2p5;
  gray3: $gray3;
  gray4: $gray4;
  blue: $blue;
  light_blue: $light_blue;
  medium_blue: $medium_blue;
  red: $red;
  red2: $red2;
  green: $green;
  green2: $green2;
  bgcolor: $bgcolor;
  // font-family-sukhumvitset: $font-family-sukhumvitset;
  font-family-thonburi:$font-family-thonburi ;
  coral: $coral;
  yelow: $yellow;
  yellow2: $yellow2;
}
