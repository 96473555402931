@import "../variables";

.pagination {
  overflow-x: auto;
  overflow-wrap: anywhere;
  max-width: 100%;
  padding: 10px;
  .page-item {
    &.disabled {
      i {
        color: $gray4;
      }
    }
    .page-link {
      border: none;
      color: $black;
      line-height: 1;
      margin-left: 4px;
      border-radius: 50%;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.07);
      padding: 0.55rem 0.75rem;
      &.previous {
        box-shadow: none;
        padding: 0.5rem 0.25rem;
        background-color: transparent;
        &:hover {
          background-color: transparent;
          color: $black;
        }
      }
      &.next {
        box-shadow: none;
        padding: 0.5rem 0.25rem;
        background-color: transparent;
        &:hover {
          background-color: transparent;
          color: $black;
        }
      }

      &:hover {
        background-color: $gray4;
        color: $white;
        border-radius: 50%;
      }
    }
    &.active {
      .page-link {
        background-color: $black;
        color: $white;
        border-radius: 50%;
        box-shadow: 0px 0.5px 10px rgba(0, 0, 0, 0.05);
        line-height: 1;
      }
    }
    &.break {
      a {
        display: block;
        line-height: 1;
        padding: 0.5rem 0.75rem;
        border-radius: 50%;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.07);
        margin-left: 2px;
        color: $black;
        text-decoration: none;
      }
    }
  }
}
