@import "../variables";

.card {
  margin-top: 10px;
  box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.07);
  border-radius: 16px;
  border: none;
  .badge {
    padding: 5px;
    border-radius: 4px;
    &.badge-success {
      background-color: $green2;
    }
  }
  .card-header {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    display: flex;
    align-items: center;
    background-color: $black;
    color: $white;
    font-style: normal;
    font-weight: bold;
    flex-wrap: wrap;
    .export-btn {
      color: $black;
      background-color: $white;
      border: none;
      border-radius: 20px;
      display: inline-block;
      vertical-align: middle;
      margin-left: 10px;
      font-weight: bold;
      font-size: 14px;
      i {
        font-size: 20px;
        display: inline-block;
        vertical-align: top;
      }
    }
    .export-btn-disable {
      color: $black;
      background-color: $gray_inactive;
      border: none;
      border-radius: 20px;
      display: inline-block;
      vertical-align: middle;
      margin-left: 10px;
      font-weight: bold;
      font-size: 14px;
      i {
        font-size: 20px;
        display: inline-block;
        vertical-align: top;
      }
    }
    .filter-btn {
      color: $white;
      border: none;
      border-radius: 20px;
      display: flex;
      align-items: center;
      margin-left: 10px;
      font-weight: bold;
      width: 100px;
      justify-content: center;
      font-size: 12px;
      &.active {
        background-color: $gray3 !important;
      }
    }
    h5 {
      margin: 0;
    }
    strong {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      i {
        vertical-align: middle;
      }
    }
  }
  .card-body {
    background-color: $white2;
    .table {
      thead {
        border-bottom: 2px $black solid;
        th {
          padding-top: 0;
          border: none;
        }
      }
    }
  }
  .card-footer {
    display: flex;
    flex-direction: row-reverse;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    background-color: $white;
    border-top: none;
  }
}
