// If you want to override variables do it here
@import "variables";

// Import styles
@import "~@coreui/coreui/scss/coreui.scss";

// Temp fix for reactstrap
@import '~@coreui/coreui/scss/_dropdown-menu-right.scss';

// If you want to add something do it here
@import "custom";

// ie fixes
@import "ie-fix";

.break-word {
    
    /* css-3 */
    white-space: -o-pre-wrap; 
    word-break: break-all;
    word-wrap: break-word;
    white-space: pre-wrap; 
    white-space: -moz-pre-wrap; 
    white-space: -pre-wrap;
    width: 10%; 
}

.ql-tooltip.ql-editing {
    z-index: 1000;
}

.ql-editor {
    min-height: 500px;
  }
