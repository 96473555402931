@import '../variables';

.custom-checkbox {
    margin-left: 10px;
    .custom-control-input:checked ~ .custom-control-label::before{
        background-color: $medium_blue;
        box-shadow: none;
    }
    .custom-control-label{
        &::before{
            background-color: $white2;
            border-radius: 50%;
            border: 1px solid $gray2;
            box-shadow: none !important;
            &:hover{
                border-color: $medium_blue;
            }
        }
    }
}